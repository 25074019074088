import React, { useState, useEffect } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  input: string;
  openCreatemodal?: any;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleAdd: () => void;
};

function AddCatagory({
  state,
  input,
  onChangeInput,
  onHandleAdd,
  openCreatemodal,
}: Props) {
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    console.log("this is state: ", state);

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [input, state]);

  return (
    <div className="flex flex-col h-full  px-[0.45rem]">
      <div className="grow space-y-4 pt-[1.25rem] overflow-y-scroll no-scrollbar ">
        <div>
          <InputText
            value={input}
            onChange={(e) => onChangeInput(e)}
            placeholder="Enter Catagory"
            labelTitle="Catagory"
          />
        </div>

        <div className="flex flex-col space-y-[0.94rem] py-[1.25rem]  px-[0.49rem]">
          <div className="text-[16px] font-[600]">Category Image</div>
          <div className="flex space-x-1 items-center">
            <UploadButton
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "",
                input: input,
                openCreatemodal: openCreatemodal,
              }}
              handleUploadFromDevice={(file) => {
                setFile(file);
                console.log(file);
              }}
            />
            {/* {state && state.file?.name} */}
            <UploadDropdowns
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "",
                input: input,
                openCreatemodal: openCreatemodal,
              }}
              handleCropFile={(cropFile) => setFile(cropFile)}
              openCreateModal={openCreatemodal}
              File={file}
            />
          </div>
        </div>
      </div>
      <div className=" border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => onHandleAdd()}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddCatagory;

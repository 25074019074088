import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuthUser, setIsAuthenticated } from "../../features/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { setAccessToken } from "src/features/token/tokenSlice";
import Logo from "../../assets/logowhite.png";
import SignInLogo from "../../assets/SignIn.svg";
import BackgroungImage from "../../assets/AltoSEPMBg.jfif";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useSelector } from "react-redux";

type Props = {
  children: JSX.Element;
};

function Auth(props: Props) {
  // const [access, setAccess] = useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate()

  // useEffect(() => {
  //   const init = async () => {
  //     const response = await axios
  //       .get("http://localhost:8080/api/v1/user/me", { withCredentials: true })
  //       .catch((err) => {
  //         console.log("Not properly authenticated!");
  //         navigate("/login")
  //       });

  //     if (response && response.data) {
  //       console.log("User: ", response.data);
  //       dispatch(setIsAuthenticated(true));
  //       dispatch(setAuthUser(response.data));
  //       setAccess(true);
  //     }
  //   };
  //   init();
  // }, []);
  const [loading, setLoading] = useState(true);
  const [showSessionExpiredPopup, setShowSessionExpiredPopup] = useState(false);
  const [trialEnded, setTrialEnded] = useState(false);

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  console.log(instance, accounts);
  const account = useAccount(accounts[0] || {});

  console.log(accounts, account);

  const { userId } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   handleSignIn();
  // }, []);

  useEffect(() => {
    if (account) {
      setLoading(false);
    }
    // else {
    //   if (!inProgress) {
    //     handleSignIn();
    //   }
    // }
  }, [account, inProgress]);

  useEffect(() => {

    try {
      if (loading) {
        return;
      }

      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
      }

      const fetchData = async () => {
        let accessToken: string | undefined = undefined;
        const response = await instance.acquireTokenSilent({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        });
        accessToken = response.accessToken;

        console.log("Access Token:", accessToken);

        if (accessToken) dispatch(setAccessToken(accessToken));
      };

      fetchData();
      console.log(account.idTokenClaims?.oid !== userId);
      // if (account.idTokenClaims?.oid !== userId) navigate(`/dashboard`);

      const preLoginRoute = sessionStorage.getItem("preLoginRoute");
      if (preLoginRoute) {
        // If there's a URL, redirect to that URL and remove it from the session storage
        sessionStorage.removeItem("preLoginRoute");
        navigate(preLoginRoute);
      }
    }
    catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        setShowSessionExpiredPopup(true);
      } else {
        console.error("An error occurred during token acquisition", err);
      }
    }
  }, [loading, account, userId, navigate]);

  useEffect(() => {
    const acquireToken = async () => {
      const silentRequest = {
        scopes: ["User.Read"],
        account: accounts[0]
      };
      try {
        await instance.acquireTokenSilent(silentRequest);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          setShowSessionExpiredPopup(true);
        }
      }
    }; // Check every minute

    // return () => clearInterval(intervalId); // Clear interval on component unmount

    // const renewToken = async () => {
    //   if (accounts.length > 0) {
    //     try {
    //       const silentRequest = {
    //         scopes: ["User.Read"],
    //         account: accounts[0]
    //       };
    //       const response = await instance.acquireTokenSilent(silentRequest);
    //       if (response.accessToken) {
    //         // Update the accessToken in the Redux store
    //         dispatch(setAccessToken(response.accessToken));
    //       }
    //     } catch (err) {
    //       if (err instanceof InteractionRequiredAuthError) {
    //         // Show session expired popup
    //         setShowSessionExpiredPopup(true);
    //       }
    //     }
    //   }
    // };

    // renewToken();

    acquireToken();
  }, [accounts, instance, dispatch]);

  // useEffect(() => {
  //   // Check if there's a URL stored in the session storage

  //   const preLoginRoute = sessionStorage.getItem('preLoginRoute');
  //   if (preLoginRoute) {
  //     // If there's a URL, redirect to that URL and remove it from the session storage
  //     sessionStorage.removeItem('preLoginRoute');
  //     navigate(preLoginRoute);
  //   }
  // }, []);

  const handleSignIn = () => {

    if (!instance) {
      console.error('MSAL instance is not initialized');
      return;
    }
    // Store the current page URL in the session storage
    sessionStorage.setItem("preLoginRoute", window.location.pathname);

    try {
      instance.loginRedirect({
        scopes: ["User.Read"],
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    async function checkTrialStatus() {
      // Replace this with a call to your backend service
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/user/checkusertrialended`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log("User:", response.data.data);
          setTrialEnded(response.data.data.trialEnded);
        }
      } catch (error) {
        console.log(error);
      }
    }

    checkTrialStatus();
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        {showSessionExpiredPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded shadow-lg">
              <p className="mb-4">Session expired. Please click continue to renew your session.</p>
              <button className="bg-blue-500 hover:bg-blue-700 text-white bg-[#AB0273] font-bold py-2 px-4 rounded" onClick={async () => {
                // Try to renew the token again
                const silentRequest = {
                  scopes: ["User.Read"],
                  account: accounts[0]
                };
                try {
                  const response = await instance.acquireTokenSilent(silentRequest);
                  if (response && response.accessToken) {
                    // Update the accessToken in the Redux store
                    dispatch(setAccessToken(response.accessToken));
                  }
                  // Hide the popup
                  setShowSessionExpiredPopup(false);
                } catch (err) {
                  if (err instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    instance.acquireTokenRedirect(silentRequest);
                  }
                }
              }}>Continue</button>
            </div>
          </div>
        )}
        {trialEnded && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded shadow-lg">
              <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2">
                <h1 className="text-2xl font-bold mb-5 text-[#AB0273]">Choose Your Subscription</h1>
                <div className="flex space-x-4">
                  <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <h2 className="font-bold text-xl mb-2 text-[#AB0273]">Basic</h2>
                    <p className="mb-4">Basic subscription details...</p>
                    <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">Choose Basic</button>
                  </div>
                  <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <h2 className="font-bold text-xl mb-2 text-[#AB0273]">Premium</h2>
                    <p className="mb-4">Premium subscription details...</p>
                    <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">Choose Premium</button>
                  </div>
                  <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <h2 className="font-bold text-xl mb-2 text-[#AB0273]">Organization</h2>
                    <p className="mb-4">Organization subscription details...</p>
                    <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">Choose Organization</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <main className="w-full max-w-[90rem] h-screen max-h-[64rem] mx-auto overflow-hidden font-poppins">
          <div className="flex w-full max-w-[90rem] h-[5.625rem] justify-start px-[1.25rem] py-[0.5625rem] items-center bg-[#242424] text-[#D9D9D9] ">
            <span>
              <img src={Logo} alt="logo" />
            </span>
            <span className="w-[0.125rem] h-[1.875rem] bg-[#FFFFFF]"></span>
            <div className="font-normal flex items-center space-x-1">
              <span className="flex items-start text-[#AB0273]">
                <span className="text-[3rem] font-[500]  leading-normal">
                  ALTO
                </span>
                <span className="text-[2rem] font-[400]  leading-normal">
                  ©
                </span>
              </span>
              <span className="text-[#D9D9D9] text-[1.5rem] font-[400]  leading-normal">
                Suite
              </span>
            </div>
          </div>
          <section className="relative w-full h-[calc(100%-5.625rem)] max-w-[90rem] flex bg-[#EFEFEF]">
            <main
              className="h-full w-full max-w-[90rem] mx-auto flex justify-center bg-cover overflow-y-scroll no-scrollbar"
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${BackgroungImage})`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
                strokeWidth: "1px",
                stroke: "#242424",
              }}
            >
              <div className="">
                <div className="flex flex-col text-[#FFFFFF] mt-[12.44rem] items-center space-y-[1.875rem] pb-[2rem]">
                  <div className="flex space-x-[6px] text-[1.8rem] lg:text-[2.25rem] items-center justify-center">
                    <span className="flex text-[#FFFFFF] font-[500] leading-normal">
                      PEAK PACIFIC
                    </span>
                    <span className="flex text-[#AB0273] font-[400] leading-normal ">
                      <span>ALTO</span>
                      <span className="text-[1.8rem]">©</span>
                    </span>
                    <span className="flex text-[#FFFFFF] font-[400] leading-normal">
                      Suite
                    </span>
                  </div>
                  <div className="w-full max-w-[27.8125rem] h-[0.125rem] bg-[#D9D9D9]"></div>
                  <div className="text-[#FFFFFF] font-[300] text-[2.5rem] lg:text-[3rem] text-center">
                    <div className="flex items-center justify-center space-x-[7px] leading-normal">
                      <div className="">Welcome</div>
                      <div className="text-[1rem]">to the</div>
                      <div>World</div>
                    </div>

                    <div className="flex items-center justify-center space-x-[7px] leading-normal">
                      <div className="text-[0.875rem]">of</div>
                      <div>Extraordinary</div>
                    </div>
                    <div className="flex items-center justify-center leading-normal">
                      Learning Experience
                    </div>
                  </div>

                  <button
                    onClick={() => handleSignIn()}
                    className="flex justify-center items-center px-[2rem] py-[0.90rem] space-x-[0.75rem] rounded-[1.25rem] bg-[#AB0273]"
                  >
                    <span className="text-[1.2rem] lg:text-[1.5rem] font-[500] leading-normal text-[#FFFFFF]">
                      Sign in
                    </span>
                    <span className="w-[2rem] h-[2rem] flex flex-shrink-0 items-center justify-center">
                      <img src={SignInLogo} alt="signIn" />
                    </span>
                  </button>
                </div>
              </div>
            </main>
          </section>
        </main>
        {/* <div className="flex justify-center items-center h-screen ">
          <button className="text-2xl border-b" onClick={() => handleSignIn()}>
            Login
          </button>
        </div> */}
      </UnauthenticatedTemplate>
    </>
  );
}

export default Auth;

import React, { useEffect, useState } from 'react';
import Tool from './Tool';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeSelectedTemplate, removeSelectedTool } from 'src/features/courseTemplate/courseTemplateSlice';
import { GetTemplate } from './Templates/Authoring/utils/GetTemplate';
import { Mcr } from './Templates/Preview';
import { GetPreviewTemplate } from './Templates/Preview/utils/GetPreviewTempalte';

export type Tool = {
    id: number;
    type: string;
    x: number;
    y: number;
    width: string | number;
    height: string | number;
    content: string;
};

type Props = {
    topic: any;
    handleTopicChange: (topic: any) => void;
}


const Canvas = ({ topic, handleTopicChange }: Props) => {
    const [tools, setTools] = useState<Array<Tool>>([]);
    const [template, setTemplate] = useState<any>(null);


    useEffect(() => {
        console.log('topic:', topic);
        setTools(topic.tools);
        setTemplate(topic.template);
    }, [topic]);

    const { selectedTemplate, selectedTool } = useSelector((state: any) => state.courseTemplate);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('selectedTemplate:', selectedTemplate);
        if (selectedTemplate) {
            setTemplate(selectedTemplate);
            dispatch(removeSelectedTemplate());
        }

    }, [selectedTemplate]);

    useEffect(() => {
        console.log('selectedTool:', selectedTool);
        if (selectedTool) {
            setTools([...tools, selectedTool]);
            dispatch(removeSelectedTool());
        }

    }, [selectedTool]);

    useEffect(() => {
        console.log("changed: ", tools, template);
    }, [tools, template]);

    function handleDataChange(data: any) {
        setTemplate({ ...template, data });
        handleTopicChange({ ...topic, template: { ...template, data } });
    }


    const addTool = (type: string) => {
        const newTool = {
            id: Date.now(),
            type,
            x: 50,
            y: 50,
            width: 100,
            height: 50,
            content: type === 'text' ? 'Text' : 'Image'
        };
        setTools([...tools, newTool]);
    };

    const handleDragStop = (id: number, x: number, y: number) => {
        const updatedTools = tools.map(tool => tool.id === id ? { ...tool, x, y } : tool);
        console.log('drag stop', id, x, y, updatedTools);
        setTools(updatedTools);
        handleTopicChange({ ...topic, tools: updatedTools })
    };

    const handleResizeStop = (id: number, width: string, height: string) => {
        const updatedTools = tools.map(tool => tool.id === id ? { ...tool, width, height } : tool);
        setTools(updatedTools);
        handleTopicChange({ ...topic, tools: updatedTools })
    };

    return (
        <div className="canvas-container">
            {/* <div className="toolbar">
                <button onClick={() => addTool('text')}>Add Text</button>
                <button onClick={() => addTool('image')}>Add Image</button>
            </div> */}
            <div className="canvas" id="canvas">
                {!template ? tools.map(tool => (
                    <Tool
                        key={tool.id}
                        tool={tool}
                        onDragStop={handleDragStop}
                        onResizeStop={handleResizeStop}
                    />
                )) : (
                    (() => {
                        const TemplateComponent = GetPreviewTemplate.get(template.type);
                        return TemplateComponent ? <TemplateComponent data={template.data} handleDataChange={handleDataChange} /> : null;
                    })()
                )
                }
            </div>
        </div>
    );
};

export default Canvas;

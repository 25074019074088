import React, { useEffect, useState } from 'react'
import Player from './Player'
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { setAccessToken } from 'src/features/token/tokenSlice';
import { useSelector } from 'react-redux';

type Props = {}

function PlayerSettings({ } : Props) {

    const [course, setCourse] = useState<any>();
    const [title, setTitle] = useState<any>('');
    const [haveTitle, setHaveTitle] = useState<any>(true);
    
    const { courseId } = useParams();
    const { accessToken } = useSelector((state: any) => state.accessToken);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );
                if (res && res.data) {
                    console.log("check response", res.data.data.course);
                    const courseData = res.data.data.course;
                    setCourse(courseData);
                    setTitle(courseData.title);
                    setHaveTitle(courseData.settings?.haveTitle);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [courseId, accessToken]);

    // function handleToggle() {
    //     async() => {
    //         try {
    //             updatedHaveTitle = !haveTitle
    //         }
    //     }
    // }

  return (
    <main className='relative w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins'>
        <div className='flex w-full h-[3rem] px-[1.25rem] py-[0.5625] items-center'>Player Settings</div>
        <section className='relative w-full h-[calc(100%-3rem)] flex'>
            <div className='w-full max-w-[calc(100%-18rem)] h-full bg-[#d1d1d1] flex items-center justify-center'>
                <div className=' container '>
                    <Player/>
                </div>
            </div>
            {/* settings div */}
            <div className='w-[18rem] h-full overflow-y-scroll no-scrollbar bg-[#d1d1d1] py-[1.5rem] pr-[1.5rem]'>
                <div className='mr-[1.5rem] bg-[#f6f6f6] w-full h-full p-4 space-y-2'>
                    <button className='text-gray-600 text-sm'
                        // onClick={handleToggle}
                    >
                        Disable Title
                        {/* {haveTitle ? 'Disable Title' : 'Enable Title'} */}
                    </button>
                    <div className='border-2 border-gray-300 p-2 rounded-md'>
                        <input type="text" onChange={(e) => {}} value={title} className='border-none outline-none text-gray-600 text-sm' />
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default PlayerSettings

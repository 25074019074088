import React, { useState } from "react";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MicrosoftLogin from "react-microsoft-login";
import Layout from "../Layout";
import BackgroungImage from "../../assets/AltoSEPMBg.jfif";
import SignInLogo from "../../assets/SignIn.svg";
import { setTokenExpired } from "src/features/token/tokenSliceExpire";
import { useDispatch } from 'react-redux';

function Login() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(setTokenExpired(false))
    instance.loginRedirect({
      scopes: ["User.Read"],
    });
  };

  const handleInvite = () => {
    navigate("/invite");
  };

  const handleSignOut = () => {
    instance.logoutRedirect({
      account: instance.getAccountByUsername(username),
    });
  };

  //handle promise returned by the microsoft

  instance
    .handleRedirectPromise()
    .then((res) => {
      console.log("first");
      console.log("User: ", res?.accessToken);
      // if (res) setUsername(res.account.username);
    })
    .catch((err) => console.error(err));

  const redirectToGoogleSso = async () => {
    let timer: NodeJS.Timeout;

    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
    const top = (height - 600) / 2 / systemZoom + dualScreenTop;

    const googleLoginUrl = "http://localhost:8080/api/v1/auth/login/google";
    const newWindow = window.open(
      googleLoginUrl,
      "_blank",
      `width=${500 / systemZoom},height=${600 / systemZoom
      },top=${top},left=${left}`
    );

    if (newWindow) {
      timer = setInterval(() => {
        if (newWindow.closed) {
          console.log("you are authenticated");
          navigate("/");
          if (timer) clearInterval(timer);
        }
      }, 500);
    }
  };

  return (
    <Layout type="homepage">
      {(callback) => 
      <main
        className="h-full w-full max-w-[90rem] mx-auto flex justify-center bg-cover overflow-y-scroll no-scrollbar"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${BackgroungImage})`,
          backgroundPosition: "50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "lightgray",
          color: "#146793",
          strokeWidth: "1px",
          stroke: "#242424",
        }}
      >
        {/* <GoogleButton onClick={redirectToGoogleSso}>
        Login with google
      </GoogleButton>

      <button onClick={() => handleSignIn()}>Click to login</button>
      <button onClick={() => handleSignOut()}>Click to logout</button>
      <MicrosoftLogin
        clientId={"b6930f4f-f80d-4ae3-ad24-f8b08b51e5ed"}
        authCallback={handleSignIn}
        children={""}
      /> */}
        {/* <img src="https://peakpacificgroup.com/academy/wp-content/uploads/sites/2/2019/08/homepage_header_academy.jpg?id=654" alt="img" /> */}
        <div>
          <div className="flex flex-col text-[#FFFFFF] mt-[12.44rem] pb-[2rem] items-center space-y-[1.875rem]">
            <div className="flex space-x-[6px] text-[1.8rem] lg:text-[2.25rem] items-center justify-center">
              <span className="flex text-[#FFFFFF] font-[500] leading-normal">
                PEAK PACIFIC
              </span>
              <span className="flex text-[#AB0273] font-[400] leading-normal ">
                <span>ALTO</span>
                <span className="text-[1.8rem]">©</span>
              </span>
              <span className="flex text-[#FFFFFF] font-[400] leading-normal">
                Suite
              </span>
            </div>
            <div className="w-full max-w-[27.8125rem] h-[0.125rem] bg-[#D9D9D9]"></div>
            <div className="text-[#FFFFFF] font-[300] text-[2.5rem] lg:text-[3rem] text-center">
              <div className="flex items-center justify-center space-x-[7px] leading-normal">
                <div className="">Welcome</div>
                <div className="text-[1rem]">to the</div>
                <div>World</div>
              </div>

              <div className="flex items-center justify-center space-x-[7px] leading-normal">
                <div className="text-[0.875rem]">of</div>
                <div>Extraordinary</div>
              </div>
              <div className="flex items-center justify-center leading-normal">
                Learning Experience
              </div>
            </div>

            <div className="flex space-x-6">
              <button
                onClick={() => handleSignIn()}
                className="flex justify-center items-center px-[2rem] py-[0.90rem] space-x-[0.75rem] rounded-[1.25rem] bg-[#AB0273]"
              >
                <span className="text-[1.2rem] lg:text-[1.5rem] font-[500] leading-normal text-[#FFFFFF]">
                  Sign in
                </span>
                <span className="w-[2rem] h-[2rem] flex flex-shrink-0 items-center justify-center">
                  <img src={SignInLogo} alt="signIn" />
                </span>
              </button>
              <button
                onClick={() => handleInvite()}
                className="flex justify-center items-center px-[2rem] py-[0.90rem] space-x-[0.75rem] rounded-[1.25rem] bg-[#AB0273]"
              >
                <span className="text-[1.2rem] lg:text-[1.5rem] font-[500] leading-normal text-[#FFFFFF]">
                  Try for free
                </span>
                {/* <span className="w-[2rem] h-[2rem] flex flex-shrink-0 items-center justify-center">
                  <img src={SignInLogo} alt="signIn" />
                </span> */}
              </button>
            </div>

          </div>
        </div>
      </main>}
    </Layout>
  );
}

export default Login;

import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import Text from "src/utils/Text/Txt";
import { Option } from "../utils/DefaultTemplateData";
import { set, values } from "lodash";
import { v4 as uuid } from "uuid";

type Props = {
  type?: string;
  data: any;
  handleDataChange: (data: any) => void;
};

function Question({ type, data, handleDataChange }: Props) {
  const [question, setQuestion] = React.useState("");
  const [prompt, setPrompt] = React.useState("");
  const [templateType, setTemplateType] = useState("");
  const [options, setOptions] = useState([]);
  const [globalFeedback, setGlobalFeedback] = useState("");

  useEffect(() => {
    setQuestion(data.question);
    setPrompt(data.prompt);
    if (type) setTemplateType(type);
    setOptions(data.options);
    setGlobalFeedback(data.globalFeedback);
  }, []);

  useEffect(() => {
    console.log(type);
    if (type) setTemplateType(type);
  }, [type]);

  useEffect(() => {
    console.log(templateType);
  }, [templateType]);

  useEffect(() => {
    console.log("data: ", data, question, prompt, options);

    handleDataChange({
      ...data,
      question,
      prompt,
      options,
    });
  }, [question, prompt, options]);

  return (
    <div>
      <Text
        labelTitle="Question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        maxLength={350}
      />
      <Text
        labelTitle="Prompt"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        maxLength={125}
      />
      <Text
        labelTitle="Global Feedback"
        value={globalFeedback}
        onChange={(e) => setGlobalFeedback(e.target.value)}
        disabled={true}
      />

      {!type && (
        <Select
          value={templateType}
          onValueChange={(value: string) => setTemplateType(value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Template Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="light">Multiple Choice Response</SelectItem>
            <SelectItem value="dark">Single Choice Response</SelectItem>
            <SelectItem value="system">True/False</SelectItem>
          </SelectContent>
        </Select>
      )}

      {templateType && (
        <OptionChoices
          type={templateType}
          options={options}
          setOptions={(options: any) => setOptions(options)}
        />
      )}
    </div>
  );
}

export default Question;

const OptionChoices = ({
  type,
  options,
  setOptions,
}: {
  type: string;
  options: Option[];
  setOptions: (options: Option[]) => void;
}) => {
  const [templateType, setTemplateType] = useState("");

  useEffect(() => {
    console.log("type", type);
    if (type) setTemplateType(type);
    console.log("type", templateType);
  }, []);

  const handleSelectionChange = (index: number) => {
    if (type === "scr" || type === "true/false") {
      setOptions(
        options.map((option, i) => ({
          ...option,
          correct: i === index, // Select only the clicked option
        }))
      );
    } else {
      setOptions(
        options.map((option, i) =>
          i === index ? { ...option, correct: !option.correct } : option
        )
      );
    }
  };

  const getMaxOptions = () => {
    if (templateType === "mcr" || templateType === "scr") {
      return 4;
    } else if (templateType === "true/false") {
      return 2;
    }
    return Infinity;

    // switch (templateType) {
    //   case "mcr": // Multiple Choice Response
    //   case "scr": // Single Choice Response
    //     return 4;
    //   case "true/false": // True/False
    //     return 2;
    //   default:
    //     return Infinity; // No limit
    // }
  };

  const maxOptions = getMaxOptions();

  const addOption = () => {
    if (options.length >= maxOptions) return; // Prevent adding more options if limit is reached

    const newOption = {
      _id: uuid(),
      value: "New Option",
      correct: false,
      response: false,
      feedback: "Write feedback text here..",
    };
    setOptions([...options, newOption]);
  };

  const deleteOption = (index: number) => {
    setOptions(options.filter((option, idx) => idx !== index));
  };

  return (
    <div className="p-[1.63rem] w-full">
      <div className="flex justify-between w-full items-center">
        <div className="w-[49%] flex items-center justify-start pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <div className="w-[15%] "><span>Correct</span></div>
          <div className="w-[85%] ml-3"><span>Options</span></div>
        </div>
        <div className="flex justify-start w-[49%] pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]"><span>FeedBack</span></div>
      </div>

      <div className="space-y-1.5 w-full">
        {options.map((option, index) => (
          <div key={index} className="flex justify-between w-full items-center">
            <div className="w-[49%] flex items-center">
              {templateType === "scr" && (
                <input
                  type="radio"
                  checked={option.correct}
                  onChange={() => handleSelectionChange(index)}
                  className="w-[15%] flex justify-center"
                />
              )}
              {templateType === "true/false" && (
                <input
                  type="checkbox"
                  checked={option.correct}
                  onChange={() => handleSelectionChange(index)}
                  className="w-[15%] flex justify-center"
                />
              )}
              {templateType === "mcr" && (
                <input
                  type="checkbox"
                  checked={option.correct}
                  onChange={() => handleSelectionChange(index)}
                  className="w-[15%] flex justify-center"
                />
              )}
              <div className="flex w-[85%] items-center pr-[0.75rem] overflow-hidden rounded-md border border-[#586A84] hover:border-gray-800 ml-3">
                <input
                  type="text"
                  value={option.value}
                  maxLength={100}
                  className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                  onChange={(e) => {
                    setOptions(
                      options.map((op, i) =>
                        i === index ? { ...op, value: e.target.value } : op
                      )
                    );
                  }}
                />
                <span className="text-[14px] text-gray-600 font-medium">
                  <span className={`${option.value.length >= 100 ? "text-red-500" : "text-green-500"}`}>{option.value.length}</span>
                  /
                  <span className={``}>100</span>
                </span>
              </div>
            </div>

            <div className="flex w-[49%] space-x-5 items-center">
            <div className="w-full flex items-center pr-[0.75rem] overflow-hidden rounded-md border border-[#586A84] hover:border-gray-800">
              <input
                type="text"
                value={option.feedback || ""}
                maxLength={500}
                className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                onChange={(e) => {
                  setOptions(
                    options.map((op, i) =>
                      i === index ? { ...op, feedback: e.target.value } : op
                    )
                  );
                }}
              />
              <span className="text-[14px] text-gray-600 font-medium">
                  <span className={`${option.value.length >= 100 ? "text-red-500" : "text-green-500"}`}>{option.value.length}</span>
                  /
                  <span className={``}>500</span>
                </span>
            </div>

            <button
              onClick={() => deleteOption(index)}
              className="text-black text-md"
              aria-label="Delete Option"
            >
              X
            </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-10 ml-8">
        <button
          onClick={addOption}
          className={`bg-black/70 hover:bg-black/50 text-white py-2 px-4 rounded disabled:bg-black/25 disabled:text-black`}
          disabled={options.length >= maxOptions}
        >
          Add Option
        </button>
        {options.length >= maxOptions && (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            {templateType === "mcr" || templateType === "scr" ? (
              <div>You cannot add more than four options</div>
            ) : templateType === "true/false" ? (
              <div>You cannot add more than two options</div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

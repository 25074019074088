import React, { useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { useSelector } from 'react-redux';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import Layout1 from '../../assets/Course/textwithimage/Layout1.png';
import Layout2 from '../../assets/Course/textwithimage/Layout2.png';
import Layout3 from '../../assets/Course/textwithimage/Layout3.png';
import { Input } from '../ui/input';
import Layout4 from '../../assets/Course/textwithimage/Layout4.png';


type SettingProps = {
  templateData: any;
  handleChange: (templateData: any) => void;
}

const Settings = ({ templateData, handleChange }: SettingProps) => {
  const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);

  useEffect(() => {
    console.log(canvasActiveComponent);
  }, [canvasActiveComponent]);
  console.log(templateData);

  function handleUpdateTemplateData(key: string, value: any) {
    const keys = key.split('.');
    let updatedData = JSON.parse(JSON.stringify(templateData.data)); // Deep copy

    let current = updatedData;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = {};
      }
      current = current[keys[i]];
    }

    current[keys[keys.length - 1]] = value;

    handleChange({
      ...templateData,
      data: updatedData
    });
  }

  return (
    <div className="sidebar bg-[#EFEFEF] border-l border-gray-300 relative flex w-[20rem] flex-col overflow-y-scroll">
      <Tabs defaultValue="style" className="">
        <TabsList>
          <TabsTrigger value="style">Style</TabsTrigger>
          <TabsTrigger value="layout">Layout</TabsTrigger>
        </TabsList>
        {
          templateData.type === 'scr' && (
            <>
              <TabsContent value="style">
                <div className='p-3 space-y-4'>
                  <div>
                    <h3 className='text-lg font-semibold'>{templateData.title}</h3>
                  </div>
                  {
                    canvasActiveComponent?.componentType === 'scr' && (
                      <div>
                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Image</div>
                          <div className='space-y-4'>
                            <div className='flex w-full justify-between items-center'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.width', `${e.target.value}${templateData.data.styles.image.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full items-center justify-between'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.height', `${e.target.value}${templateData.data.styles.image.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.imageContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.imageContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.imageContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.imageContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                            {/* write a code to change the image object fit to contain or cover */}

                            <div className='flex justify-between items-center'>
                              <div>Object Fit</div>
                              <Select
                                value={(
                                  templateData.data.styles.image.objectFit === 'contain' ? "Contain" : (
                                    templateData.data.styles.image.objectFit === 'cover' ? "Cover" : undefined
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Contain' ? 'contain' : 'cover';
                                  handleUpdateTemplateData('styles.image.objectFit', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Contain">Contain</SelectItem>
                                  <SelectItem value="Cover">Cover</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Question</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.width', `${e.target.value}${templateData.data.styles.question.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.height', `${e.target.value}${templateData.data.styles.question.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.questionContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.questionContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.questionContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.questionContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', `${e.target.value}${templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', `${e.target.value}${templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', `${e.target.value}${templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', `${e.target.value}${templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Prompt</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.width', `${e.target.value}${templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.height', `${e.target.value}${templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.promptContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.promptContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.promptContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.promptContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', `${e.target.value}${templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', `${e.target.value}${templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', `${e.target.value}${templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', `${e.target.value}${templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Choices</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.width', `${e.target.value}${templateData.data.styles.option.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.height', `${e.target.value}${templateData.data.styles.option.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.optionsContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.optionsContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.optionsContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.optionsContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', `${e.target.value}${templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', `${e.target.value}${templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', `${e.target.value}${templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', `${e.target.value}${templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    )
                  }

                </div>
              </TabsContent>
              <TabsContent value="layout" className='space-y-3'>

              </TabsContent>
            </>
          )
        }

        {
          templateData.type === 'mcr' && (
            <>
              <TabsContent value="style">
                <div className='p-3 space-y-4'>
                  <div>
                    <h3 className='text-lg font-semibold'>{templateData.title}</h3>
                  </div>
                  {
                    canvasActiveComponent?.componentType === 'mcr' && (
                      <div>
                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Image</div>
                          <div className='space-y-4'>
                            <div className='flex w-full justify-between items-center'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.width', `${e.target.value}${templateData.data.styles.image.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full items-center justify-between'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.height', `${e.target.value}${templateData.data.styles.image.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.imageContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.imageContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.imageContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.imageContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                            {/* write a code to change the image object fit to contain or cover */}

                            <div className='flex justify-between items-center'>
                              <div>Object Fit</div>
                              <Select
                                value={(
                                  templateData.data.styles.image.objectFit === 'contain' ? "Contain" : (
                                    templateData.data.styles.image.objectFit === 'cover' ? "Cover" : undefined
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Contain' ? 'contain' : 'cover';
                                  handleUpdateTemplateData('styles.image.objectFit', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Contain">Contain</SelectItem>
                                  <SelectItem value="Cover">Cover</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Question</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.width', `${e.target.value}${templateData.data.styles.question.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.height', `${e.target.value}${templateData.data.styles.question.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.questionContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.questionContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.questionContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.questionContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', `${e.target.value}${templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', `${e.target.value}${templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', `${e.target.value}${templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', `${e.target.value}${templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Prompt</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.width', `${e.target.value}${templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.height', `${e.target.value}${templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.promptContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.promptContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.promptContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.promptContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', `${e.target.value}${templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', `${e.target.value}${templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', `${e.target.value}${templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', `${e.target.value}${templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Choices</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.width', `${e.target.value}${templateData.data.styles.option.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.height', `${e.target.value}${templateData.data.styles.option.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.optionsContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.optionsContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.optionsContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.optionsContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', `${e.target.value}${templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', `${e.target.value}${templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', `${e.target.value}${templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', `${e.target.value}${templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    )
                  }

                </div>
              </TabsContent>
              <TabsContent value="layout" className='space-y-3'>

              </TabsContent>
            </>
          )
        }

        {
          templateData.type === 'true/false' && (
            <>
              <TabsContent value="style">
                <div className='p-3 space-y-4'>
                  <div>
                    <h3 className='text-lg font-semibold'>{templateData.title}</h3>
                  </div>
                  {
                    canvasActiveComponent?.componentType === 'true/false' && (
                      <div>
                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Image</div>
                          <div className='space-y-4'>
                            <div className='flex w-full justify-between items-center'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.width', `${e.target.value}${templateData.data.styles.image.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full items-center justify-between'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.image.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.image.height', `${e.target.value}${templateData.data.styles.image.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.image.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.image.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.image.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.imageContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.imageContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.imageContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.imageContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.imageContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.imageContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                            {/* write a code to change the image object fit to contain or cover */}

                            <div className='flex justify-between items-center'>
                              <div>Object Fit</div>
                              <Select
                                value={(
                                  templateData.data.styles.image.objectFit === 'contain' ? "Contain" : (
                                    templateData.data.styles.image.objectFit === 'cover' ? "Cover" : undefined
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Contain' ? 'contain' : 'cover';
                                  handleUpdateTemplateData('styles.image.objectFit', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Contain">Contain</SelectItem>
                                  <SelectItem value="Cover">Cover</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Question</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.width', `${e.target.value}${templateData.data.styles.question.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.question.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.question.height', `${e.target.value}${templateData.data.styles.question.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.question.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.question.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.question.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.questionContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.questionContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.questionContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.questionContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.questionContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.questionContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', `${e.target.value}${templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', `${e.target.value}${templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', `${e.target.value}${templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.questionContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', `${e.target.value}${templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.questionContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.questionContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.questionContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Prompt</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.width', `${e.target.value}${templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.prompt.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.prompt.height', `${e.target.value}${templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.prompt.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.prompt.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.prompt.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.promptContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.promptContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.promptContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.promptContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.promptContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.promptContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', `${e.target.value}${templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', `${e.target.value}${templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', `${e.target.value}${templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.promptContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', `${e.target.value}${templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.promptContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.promptContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.promptContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='flex flex-col space-y-4 justify-between items-center'>
                          <div>Choices</div>
                          <div className='space-y-4'>
                            <div className='flex w-full items-center justify-between'>
                              <div>Width</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.width)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.width', `${e.target.value}${templateData.data.styles.option.width.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.width.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.width)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.width', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div>Height</div>
                              <div className='flex items-center space-x-2'>
                                <input
                                  type="number"
                                  value={parseInt(templateData.data.styles.option.height)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.option.height', `${e.target.value}${templateData.data.styles.option.height.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData.data.styles.option.height.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData.data.styles.option.height)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.option.height', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Horizontal Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.justifyContent === 'center' ? "Center" : (
                                    templateData.data.styles.optionsContainer.justifyContent === 'flex-start' ? "Left" : (
                                      templateData.data.styles.optionsContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.justifyContent', newValue);
                                }}
                              >
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className='flex justify-between items-center'>
                              <div>Vertical Alignment</div>
                              <Select
                                value={(
                                  templateData.data.styles.optionsContainer.alignItems === 'center' ? "Middle" : (
                                    templateData.data.styles.optionsContainer.alignItems === 'flex-start' ? "Top" : (
                                      templateData.data.styles.optionsContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                    )
                                  )
                                )}
                                onValueChange={(value: string) => {
                                  const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                  handleUpdateTemplateData('styles.optionsContainer.alignItems', newValue);
                                }}>
                                <SelectTrigger className="w-[8rem]">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div>Padding</div>
                              <div className='flex flex-row flex-wrap'>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', `${e.target.value}${templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', `${e.target.value}${templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', `${e.target.value}${templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex px-3 py-2 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <input
                                      type="number"
                                      value={parseInt(templateData.data.styles.optionsContainer.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', `${e.target.value}${templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.optionsContainer.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.optionsContainer.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.optionsContainer.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    )
                  }

                </div>
              </TabsContent>
              <TabsContent value="layout" className='space-y-3'>

              </TabsContent>
            </>
          )
        }
        {
          templateData.type === 'textwithimage' && (
            <>
              <TabsContent value="style">
                <div className='p-3 space-y-4'>
                  <div>
                    <h3 className='text-lg font-semibold'>{templateData.title}</h3>
                  </div>
                  {
                    canvasActiveComponent?.componentType === 'text' && templateData.data.logoImageFile && templateData.data.layoutOption !== "only-text" && (
                      <div className='flex flex-col space-y-4 justify-between items-center'>
                        <div>Logo</div>
                        <div className='space-y-4'>
                          <div className='flex w-full justify-between items-center'>
                            <div>Width</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.logoImage.width)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.logoImage.width', `${e.target.value}${templateData.data.styles.logoImage.width.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.logoImage.width.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.logoImage.width)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.logoImage.width', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className='flex w-full items-center justify-between'>
                            <div>Height</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.logoImage.height)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.logoImage.height', `${e.target.value}${templateData.data.styles.logoImage.height.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.logoImage.height.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.logoImage.height)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.logoImage.height', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className='flex justify-between items-center'>
                            <div>Horizontal Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.logoImageContainer.justifyContent === 'center' ? "Center" : (
                                  templateData.data.styles.logoImageContainer.justifyContent === 'flex-start' ? "Left" : (
                                    templateData.data.styles.logoImageContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.logoImageContainer.justifyContent', newValue);
                              }}
                            >
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className='flex justify-between items-center'>
                            <div>Vertical Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.logoImageContainer.alignItems === 'center' ? "Middle" : (
                                  templateData.data.styles.logoImageContainer.alignItems === 'flex-start' ? "Top" : (
                                    templateData.data.styles.logoImageContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.logoImageContainer.alignItems', newValue);
                              }}>
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          {/* write a code to change the image object fit to contain or cover */}

                          <div className='flex justify-between items-center'>
                            <div>Object Fit</div>

                            <Select
                              value={(
                                templateData.data.styles.logoImage.objectFit === 'contain' ? "Contain" : (
                                  templateData.data.styles.logoImage.objectFit === 'cover' ? "Cover" : undefined
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Contain' ? 'contain' : 'cover';
                                handleUpdateTemplateData('styles.logoImage.objectFit', newValue);
                              }}>
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  {
                    canvasActiveComponent?.componentType === 'text' && (
                      <div className='flex flex-col space-y-4 justify-between items-center'>
                        <div>Text</div>
                        <div className='space-y-4'>
                          <div className='flex w-full items-center justify-between'>
                            <div>Width</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.text.width)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.text.width', `${e.target.value}${templateData.data.styles.text.width.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.text.width.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.text.width)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.text.width', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className='flex w-full justify-between items-center'>
                            <div>Height</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.text.height)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.text.height', `${e.target.value}${templateData.data.styles.text.height.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.text.height.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.text.height)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.text.height', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className='flex justify-between items-center'>
                            <div>Horizontal Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.text.justifyContent === 'center' ? "Center" : (
                                  templateData.data.styles.text.justifyContent === 'flex-start' ? "Left" : (
                                    templateData.data.styles.text.justifyContent === 'flex-end' ? "Right" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.text.justifyContent', newValue);
                              }}
                            >
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className='flex justify-between items-center'>
                            <div>Vertical Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.text.alignItems === 'center' ? "Middle" : (
                                  templateData.data.styles.text.alignItems === 'flex-start' ? "Top" : (
                                    templateData.data.styles.text.alignItems === 'flex-end' ? "Bottom" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.text.alignItems', newValue);
                              }}>
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div>Padding</div>
                            <div className='flex flex-row flex-wrap'>
                              <div className='flex px-3 py-2 w-full justify-between items-center'>
                                <label htmlFor="paddingTop">Top</label>
                                <div className='flex items-center space-x-2'>
                                  <input
                                    type="number"
                                    value={parseInt(templateData.data.styles.text.paddingTop)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.text.paddingTop', `${e.target.value}${templateData.data.styles.text.paddingTop.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.text.paddingTop.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.text.paddingTop)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.text.paddingTop', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex px-3 py-2 w-full justify-between items-center'>
                                <label htmlFor="paddingRight">Right</label>
                                <div className='flex items-center space-x-2'>
                                  <input
                                    type="number"
                                    value={parseInt(templateData.data.styles.text.paddingRight)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.text.paddingRight', `${e.target.value}${templateData.data.styles.text.paddingRight.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.text.paddingRight.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.text.paddingRight)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.text.paddingRight', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex px-3 py-2 w-full justify-between items-center'>
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className='flex items-center space-x-2'>
                                  <input
                                    type="number"
                                    value={parseInt(templateData.data.styles.text.paddingBottom)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.text.paddingBottom', `${e.target.value}${templateData.data.styles.text.paddingBottom.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.text.paddingBottom.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.text.paddingBottom)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.text.paddingBottom', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex px-3 py-2 w-full justify-between items-center'>
                                <label htmlFor="paddingLeft">Left</label>
                                <div className='flex items-center space-x-2'>
                                  <input
                                    type="number"
                                    value={parseInt(templateData.data.styles.text.paddingLeft)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.text.paddingLeft', `${e.target.value}${templateData.data.styles.text.paddingLeft.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.text.paddingLeft.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.text.paddingLeft)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.text.paddingLeft', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                const newValue = templateData.data.styles.text.overflowY === 'auto' ? 'hidden' : 'auto';
                                handleUpdateTemplateData('styles.text.overflowY', newValue);
                              }}
                              className=''>
                              {templateData.data.styles.text.overflowY === 'auto' ? 'Enable' : 'Disable'} Scroll
                            </button>
                          </div>
                        </div>

                      </div>
                    )
                  }
                  {
                    canvasActiveComponent?.componentType === 'image' && (
                      <div className='flex flex-col space-y-4 justify-between items-center'>
                        <div>Image</div>
                        <div className='space-y-4'>
                          <div className='flex w-full items-center justify-between'>
                            <div>Width</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.image.width)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.image.width', `${e.target.value}${templateData.data.styles.image.width.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.image.width.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.image.width)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.image.width', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className='flex w-full justify-between items-center'>
                            <div>Height</div>
                            <div className='flex items-center space-x-2'>
                              <input
                                type="number"
                                value={parseInt(templateData.data.styles.image.height)}
                                onChange={(e) => {
                                  handleUpdateTemplateData('styles.image.height', `${e.target.value}${templateData.data.styles.image.height.includes('%') ? '%' : 'px'}`);
                                }}
                                className='w-[5rem] px-2 py-1 rounded-md'
                              />
                              <select
                                value={templateData.data.styles.image.height.includes('%') ? '%' : 'px'}
                                onChange={(e) => {
                                  const newValue = `${parseInt(templateData.data.styles.image.height)}${e.target.value}`;
                                  handleUpdateTemplateData('styles.image.height', newValue);
                                }}
                                className='w-[4rem] px-2 py-1 rounded-md'
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className='flex w-full justify-between items-center'>
                            <div>Horizontal Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.imageContainer.justifyContent === 'center' ? "Center" : (
                                  templateData.data.styles.imageContainer.justifyContent === 'flex-start' ? "Left" : (
                                    templateData.data.styles.imageContainer.justifyContent === 'flex-end' ? "Right" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.imageContainer.justifyContent', newValue);
                              }}
                            >
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className='flex w-full justify-between items-center'>
                            <div>Vertical Alignment</div>
                            <Select
                              value={(
                                templateData.data.styles.imageContainer.alignItems === 'center' ? "Middle" : (
                                  templateData.data.styles.imageContainer.alignItems === 'flex-start' ? "Top" : (
                                    templateData.data.styles.imageContainer.alignItems === 'flex-end' ? "Bottom" : undefined
                                  )
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Middle' ? 'center' : value === 'Top' ? 'flex-start' : 'flex-end';
                                handleUpdateTemplateData('styles.imageContainer.alignItems', newValue);
                              }}>
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          {/* write a code to change the image object fit to contain or cover */}

                          <div className='flex w-full justify-between items-center'>
                            <div>Object Fit</div>
                            <Select
                              value={(
                                templateData.data.styles.image.objectFit === 'contain' ? "Contain" : (
                                  templateData.data.styles.image.objectFit === 'cover' ? "Cover" : undefined
                                )
                              )}
                              onValueChange={(value: string) => {
                                const newValue = value === 'Contain' ? 'contain' : 'cover';
                                handleUpdateTemplateData('styles.image.objectFit', newValue);
                              }}>
                              <SelectTrigger className="w-[8rem]">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )
                  }

                </div>
              </TabsContent>
              <TabsContent value="layout" className='space-y-3'>
                <div onClick={() => handleUpdateTemplateData("layoutOption", "text-left-image-right")} className='h-[6rem] w-full flex justify-center items-center'>
                  <img className={`h-full aspect-auto ${templateData.data.layoutOption === "text-left-image-right" && "border-blue border-[3px] rounded-md"}`} src={Layout1} alt="image" />
                </div>
                <div onClick={() => handleUpdateTemplateData("layoutOption", "text-right-image-left")} className='h-[6rem] w-full flex justify-center items-center'>
                  <img className={`h-full aspect-auto ${templateData.data.layoutOption === "text-right-image-left" && "border-blue border-[3px] rounded-md"}`} src={Layout4} alt="image" />
                </div>
                <div onClick={() => handleUpdateTemplateData("layoutOption", "only-text")} className='h-[6rem] w-full flex justify-center items-center'>
                  <img className={`h-full aspect-auto ${templateData.data.layoutOption === "only-text" && "border-blue border-[3px] rounded-md"}`} src={Layout2} alt="image" />
                </div>
                <div onClick={() => handleUpdateTemplateData("layoutOption", "text-image-centered")} className='h-[6rem] w-full flex justify-center items-center'>
                  <img className={`h-full aspect-auto ${templateData.data.layoutOption === "only-text" && "border-blue border-[3px] rounded-md"}`} src={Layout3} alt="image" />
                </div>
              </TabsContent>
            </>
          )
        }

        {
          templateData.type === 'tabs' && (
            <>
              <TabsContent value='style'>
                <div className='p-3 space-y-3'>
                  <div>
                    <h3 className='text-lg font-semibold'>{templateData?.title}</h3>
                  </div>
                  {
                    canvasActiveComponent?.componentType === 'tab' && (
                      <div className='space-y-3'>
                        <div className='flex flex-col space-y-3 justify-between items-center'>
                          <div className='text-left w-full text-sm'>Container</div>
                          <div className='space-y-3'>
                            <div className='text-left w-full text-sm'>Padding</div>
                            <div className='flex flex-row flex-wrap text-xs'>
                              <div className='flex py-1 w-full justify-between items-center'>
                                <label htmlFor="paddingTop">Top</label>
                                <div className='flex items-center space-x-2'>
                                  <Input
                                    type="number"
                                    value={parseInt(templateData.data.styles.container.paddingTop)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.container.paddingTop', `${e.target.value}${templateData.data.styles.container.paddingTop.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.container.paddingTop.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.container.paddingTop)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.container.paddingTop', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex py-1 w-full justify-between items-center'>
                                <label htmlFor="paddingRight">Right</label>
                                <div className='flex items-center space-x-2'>
                                  <Input
                                    type="number"
                                    value={parseInt(templateData.data.styles.container.paddingRight)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.container.paddingRight', `${e.target.value}${templateData.data.styles.container.paddingRight.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.container.paddingRight.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.container.paddingRight)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.container.paddingRight', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex py-1 w-full justify-between items-center'>
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className='flex items-center space-x-2'>
                                  <Input
                                    type="number"
                                    value={parseInt(templateData.data.styles.container.paddingBottom)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.container.paddingBottom', `${e.target.value}${templateData.data.styles.container.paddingBottom.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.container.paddingBottom.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.container.paddingBottom)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.container.paddingBottom', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className='flex py-1 w-full justify-between items-center'>
                                <label htmlFor="paddingLeft">Left</label>
                                <div className='flex items-center space-x-2'>
                                  <Input
                                    type="number"
                                    value={parseInt(templateData.data.styles.container.paddingLeft)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.container.paddingLeft', `${e.target.value}${templateData.data.styles.container.paddingLeft.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData.data.styles.container.paddingLeft.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData.data.styles.container.paddingLeft)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.container.paddingLeft', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='flex px-3 py-1 w-full justify-between items-center mr-6'>
                            <div className='text-left w-full text-sm '>Background Color</div>
                            <input value={templateData.data.styles.container.backgroundColor} onChange={(e) => {
                              console.log(e.target.value)
                              handleUpdateTemplateData('styles.container.backgroundColor', e.target.value)
                            }}
                              type="color" name="" id="" />
                          </div>
                        </div>
                        <hr className='w-full h-[2px] bg-secondary/70' />
                        <div className='flex flex-col space-y-3 justify-between items-center'>
                          <div className='text-left w-full text-sm'>Tab Button</div>
                          <div className='space-y-4'>
                            <div className='space-y-3'>
                              <div className='text-left w-full text-sm'>Padding</div>
                              <div className='flex flex-row flex-wrap text-xs'>
                                <div className='flex py-1 w-full justify-between items-center'>
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className='flex items-center space-x-2'>
                                    <Input
                                      type="number"
                                      value={parseInt(templateData.data.styles.tabButton.paddingTop)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.tabButton.paddingTop', `${e.target.value}${templateData.data.styles.tabButton.paddingTop.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.tabButton.paddingTop.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.tabButton.paddingTop)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.tabButton.paddingTop', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex py-1 w-full justify-between items-center'>
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className='flex items-center space-x-2'>
                                    <Input
                                      type="number"
                                      value={parseInt(templateData.data.styles.tabButton.paddingRight)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.tabButton.paddingRight', `${e.target.value}${templateData.data.styles.tabButton.paddingRight.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.tabButton.paddingRight.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.tabButton.paddingRight)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.tabButton.paddingRight', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex py-1 w-full justify-between items-center'>
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className='flex items-center space-x-2'>
                                    <Input
                                      type="number"
                                      value={parseInt(templateData.data.styles.tabButton.paddingBottom)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.tabButton.paddingBottom', `${e.target.value}${templateData.data.styles.tabButton.paddingBottom.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.tabButton.paddingBottom.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.tabButton.paddingBottom)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.tabButton.paddingBottom', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='flex py-1 w-full justify-between items-center'>
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className='flex items-center space-x-2'>
                                    <Input
                                      type="number"
                                      value={parseInt(templateData.data.styles.tabButton.paddingLeft)}
                                      onChange={(e) => {
                                        handleUpdateTemplateData('styles.tabButton.paddingLeft', `${e.target.value}${templateData.data.styles.tabButton.paddingLeft.includes('%') ? '%' : 'px'}`);
                                      }}
                                      className='w-[5rem] px-2 py-1 rounded-md'
                                    />
                                    <select
                                      value={templateData.data.styles.tabButton.paddingLeft.includes('%') ? '%' : 'px'}
                                      onChange={(e) => {
                                        const newValue = `${parseInt(templateData.data.styles.tabButton.paddingLeft)}${e.target.value}`;
                                        handleUpdateTemplateData('styles.tabButton.paddingLeft', newValue);
                                      }}
                                      className='w-[4rem] px-2 py-1 rounded-md'
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className='text-left w-full text-sm'>Tabs corners</div>
                              <div className='flex items-center space-x-2'>
                                <Input
                                  type="number"
                                  value={parseInt(templateData?.data?.styles?.tabButton?.borderRadius)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.tabButton.borderRadius', `${e.target.value}${templateData?.data?.styles?.tabButton?.borderRadius?.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData?.data?.styles?.tabButton?.borderRadius?.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData?.data?.styles?.tabButton?.borderRadius)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.tabButton.borderRadius', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md text-xs'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className='text-left w-full text-sm'>Tabs gap</div>
                              <div className='flex items-center space-x-2'>
                                <Input
                                  type="number"
                                  value={parseInt(templateData?.data?.styles?.tabContainer?.gap)}
                                  onChange={(e) => {
                                    handleUpdateTemplateData('styles.tabContainer.gap', `${e.target.value}${templateData?.data?.styles?.tabContainer?.gap?.includes('%') ? '%' : 'px'}`);
                                  }}
                                  className='w-[5rem] px-2 py-1 rounded-md'
                                />
                                <select
                                  value={templateData?.data?.styles?.tabContainer?.gap?.includes('%') ? '%' : 'px'}
                                  onChange={(e) => {
                                    const newValue = `${parseInt(templateData?.data?.styles?.tabContainer?.gap)}${e.target.value}`;
                                    handleUpdateTemplateData('styles.tabContainer.gap', newValue);
                                  }}
                                  className='w-[4rem] px-2 py-1 rounded-md text-xs'
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className='text-left w-full text-sm'>Tabs gap with content area</div>
                              {templateData.data.layoutOption === "horizontal-tab-top" ? (
                                <div className='flex items-center space-x-2'>
                                  <Input
                                    type="number"
                                    value={parseInt(templateData?.data?.styles?.content?.marginTop)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.content.marginTop', `${e.target.value}${templateData?.data?.styles?.content?.marginTop?.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData?.data?.styles?.content?.marginTop?.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData?.data?.styles?.content?.marginTop)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.content.marginTop', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md text-xs'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              ) : (
                                <div className='flex items-center space-x-2'>
                                  <input
                                    type="number"
                                    value={parseInt(templateData?.data?.styles?.content?.marginLeft)}
                                    onChange={(e) => {
                                      handleUpdateTemplateData('styles.content.marginLeft', `${e.target.value}${templateData?.data?.styles?.content?.marginLeft?.includes('%') ? '%' : 'px'}`);
                                    }}
                                    className='w-[5rem] px-2 py-1 rounded-md'
                                  />
                                  <select
                                    value={templateData?.data?.styles?.content?.marginLeft?.includes('%') ? '%' : 'px'}
                                    onChange={(e) => {
                                      const newValue = `${parseInt(templateData?.data?.styles?.content?.marginLeft)}${e.target.value}`;
                                      handleUpdateTemplateData('styles.content.marginLeft', newValue);
                                    }}
                                    className='w-[4rem] px-2 py-1 rounded-md'
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              )}
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className='text-left w-full text-sm'>Text Color</div>
                              <input value={templateData.data.styles.tabButton.color} onChange={(e) => {
                                console.log(e.target.value)
                                handleUpdateTemplateData('styles.tabButton.color', e.target.value)
                              }}
                                type="color" name="" id="" />
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className='text-left w-full text-sm'>BG Color</div>
                              <input value={templateData.data.styles.tabButton.backgroundColor} onChange={(e) => {
                                console.log(e.target.value)
                                handleUpdateTemplateData('styles.tabButton.backgroundColor', e.target.value)
                              }}
                                type="color" name="" id="" />
                            </div>
                            <div className='flex w-full justify-between items-center '>
                              <div className='text-left w-full text-sm'>Selected Text Color</div>
                              <input value={{ ...templateData.data.styles.tabButton.selected.color }} onChange={(e) => {
                                console.log(e.target.value)
                                handleUpdateTemplateData('styles.tabButton.selected.color', e.target.value)
                              }}
                                type="color" name="" id="" />
                            </div>
                            <div className='flex w-full justify-between items-center mr-6'>
                              <div className='text-left w-full text-sm'>Selected BG Color</div>
                              <input value={templateData.data.styles.tabButton.selected.backgroundColor} onChange={(e) => {
                                console.log(e.target.value)
                                handleUpdateTemplateData('styles.tabButton.selected.backgroundColor', e.target.value)
                              }}
                                type="color" name="" id="" />
                            </div>
                          </div>
                        </div>
                        <hr className='w-full h-[2px] bg-secondary/70' />
                        <div className='flex flex-col space-y-3 justify-between items-center'>
                          <div className='text-left w-full text-sm'>Content</div>
                          <div className='flex px-3 py-1 w-full justify-between items-center  mr-6'>
                            <div className='text-left w-full text-sm'>Background Color</div>
                            <input value={templateData.data.styles.content.backgroundColor} onChange={(e) => {
                              console.log(e.target.value)
                              handleUpdateTemplateData('styles.content.backgroundColor', e.target.value)
                            }}
                              type="color" name="" id="" />
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </TabsContent>
              <TabsContent value="layout" className='space-y-3 p-3'>
                <div className='flex justify-between items-center'>
                  <div className='text-left w-full text-sm'>Tabs direction</div>
                  <Select
                    value={(
                      templateData.data.layoutOption === 'horizontal-tab-top' ? "Horizontal" : (
                        templateData.data.layoutOption === 'vertical-tab-left' ? "Vertical" : undefined
                      )
                    )}
                    onValueChange={(value: string) => {
                      const newValue = value === 'Horizontal' ? 'horizontal-tab-top' : 'vertical-tab-left';
                      handleUpdateTemplateData("layoutOption", newValue);
                    }}
                  >
                    <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                      <SelectValue placeholder="Position" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Horizontal">Horizontal</SelectItem>
                      <SelectItem value="Vertical">Vertical</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex justify-between items-center'>
                  <div className='text-left w-full text-sm'>Tabs position</div>

                  <Select
                    value={(
                      templateData.data.styles.tabButton.justifyContent === 'center' ? "Center" : (
                        templateData.data.styles.tabButton.justifyContent === 'flex-start' ? "Left" : (
                          templateData.data.styles.tabButton.justifyContent === 'flex-end' ? "Right" : undefined
                        )
                      )
                    )}
                    onValueChange={(value: string) => {
                      const newValue = value === 'Center' ? 'center' : value === 'Left' ? 'flex-start' : 'flex-end';
                      handleUpdateTemplateData('styles.tabButton.justifyContent', newValue);
                    }}
                  >
                    <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                      <SelectValue placeholder="Position" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Left">Left</SelectItem>
                      <SelectItem value="Right">Right</SelectItem>
                      <SelectItem value="Center">Center</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex justify-between items-center'>
                  <div className='text-left w-full text-sm'>Tabs width</div>
                  <div className='flex items-center space-x-2'>
                    <Input
                      type="number"
                      value={parseInt(templateData?.data?.styles?.tabButton?.width)}
                      onChange={(e) => {
                        handleUpdateTemplateData('styles.tabButton.width', `${e.target.value}${templateData?.data?.styles?.tabButton?.width?.includes('%') ? '%' : 'px'}`);
                      }}
                      className='w-[5rem] px-2 py-1 rounded-md'
                    />
                    <select
                      value={templateData?.data?.styles?.tabButton?.width?.includes('%') ? '%' : 'px'}
                      onChange={(e) => {
                        const newValue = `${parseInt(templateData?.data?.styles?.tabButton?.width)}${e.target.value}`;
                        handleUpdateTemplateData('styles.tabButton.width', newValue);
                      }}
                      className='w-[4rem] px-2 py-1 rounded-md text-xs'
                    >
                      <option value="px">px</option>
                      <option value="%">%</option>
                    </select>
                  </div>
                </div>

                <div className='flex justify-between items-center'>
                  <div className='text-left w-full text-sm'>Tabs height</div>
                  <div className='flex items-center space-x-2'>
                    <Input
                      type="number"
                      value={parseInt(templateData?.data?.styles?.tabButton?.height)}
                      onChange={(e) => {
                        handleUpdateTemplateData('styles.tabButton.height', `${e.target.value}${templateData?.data?.styles?.tabButton?.height?.includes('%') ? '%' : 'px'}`);
                      }}
                      className='w-[5rem] px-2 py-1 rounded-md'
                    />
                    <select
                      value={templateData?.data?.styles?.tabButton?.height?.includes('%') ? '%' : 'px'}
                      onChange={(e) => {
                        const newValue = `${parseInt(templateData?.data?.styles?.tabButton?.height)}${e.target.value}`;
                        handleUpdateTemplateData('styles.tabButton.height', newValue);
                      }}
                      className='w-[4rem] px-2 py-1 rounded-md text-xs'
                    >
                      <option value="px">px</option>
                      <option value="%">%</option>
                    </select>
                  </div>
                </div>

              </TabsContent>
            </>
          )
        }

      </Tabs>
    </div>
  )
}

export default Settings;

import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useSelector } from 'react-redux';
import Auth from "src/components/Auth";
// import Layout from "src/components/Layout";
import Table from "src/utils/Table";
import Canvas from "./canvas";
import TemplateRibbon from './TemplateRibbon';




type Props = {}

function Course({ }: Props) {
  const [topic, setTopic] = useState<any>(null);
  const [isTopicSelected, setIsTopicSelected] = useState(false);

  const user = useSelector((state: any) => state.user.authUser);
  
  useEffect(() => {
    console.log('topic:', topic, 'isTopicSelected:', isTopicSelected);
  }, [topic, isTopicSelected])


  return (
    <Auth>
      <Layout topic={topic} handleTopic={(topic) => { setTopic(topic); setIsTopicSelected(true) }} >
        <div className='h-full w-full max-w-[70rem] flex flex-col items-center justify-center space-y-1'>
          {topic ? (
            <>
            <TemplateRibbon />
              <Canvas topic={topic} handleTopicChange={(topic) => setTopic(topic)}  />
            </>)
            : (<h2 className='text-lg font-medium'>
              Select a topic to get started
            </h2>)
          }
        </div>
      </Layout>
    </Auth>
  )
}

  export default Course;
import React, { Component } from "react";
import styled, { css } from "styled-components";
//@ts-ignore
import Bgimage from "src/assets/Course/bg_pattern.jpg";
 
function TextImageCentered() {
      return (
        <Rect>
          <Rect2>
            <LoremIpsum>
              What Is a Learning Goal?{"\n"}
              {"\n"}Let’s first start with the “whats” of learning goals. A learning
              goal is defined as an overarching statement of what you intend to
              teach. Note the slight distinction between what you intend to teach
              versus have the trainee learn.{"\n"}
              {"\n"}Remember, as an ID or L&amp;D professional, you’re not on the
              learner-side of training; you’re acting as the instructor. Therefore,
              your course goals should be focused on what you aim to accomplish with
              your program.{"\n"}
              {"\n"}Put otherwise, learning goals give you an aerial perspective of
              your entire course roadmap. Though you’re far from the development
              stage, they provide you with the direction and focus needed to design
              a cohesive training program. Only then will you be able to research
              and generate relevant content that prioritizes your audience’s needs.
            </LoremIpsum>
          </Rect2>
        </Rect>
      );
    }
     
    const Rect = styled.div`
      display: flex;
      background-color: rgba(245,160,160,1);
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding-bottom: 6rem;
      padding-top: 6rem;
    `;
     
    const Rect2 = styled.div`
      background-color: #E6E6E6;
      border-width: 2px;
      border-color: #000000;
      border-radius: 10px;
      flex-direction: column;
      display: flex;
      flex: 1 1 0%;
      border-style: solid;
      width: 75%;
      justify-content: center;
      align-items: center;
    `;
     
    const LoremIpsum = styled.span`
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      color: #121212;
      font-size: 18px;
      flex: 1 1 0%;
      justify-content: center;
      align-items: center;
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
    `;
 
export default TextImageCentered;
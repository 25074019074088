import React from "react"
import { Mcr, Scr, TrueFalse } from "../index"
import TextWImage from "../TextWImage"
import Tabs from "../Tabs"

export const GetPreviewTemplate = new Map([
    ['mcr', (props: any) => <Mcr {...props} />],
    ['scr', (props: any) => <Scr {...props} />],
    ['true/false', (props: any) => <TrueFalse {...props} />],
    ['textwithimage', (props: any) => <TextWImage {...props} />],
    ['tabs', (props: any) => <Tabs {...props}/>]
])
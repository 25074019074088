import React, { ReactElement, useEffect } from "react";
import { Close } from "@carbon/icons-react";

type Props = {
  onOpened: boolean;
  onClose: () => void;
  children: ReactElement;
  className?: string;
  handleSubmit: () => void;
};

export default function AuthoringModal({
  onOpened,
  onClose,
  children,
  className,
  handleSubmit,
}: Props) {
  //   const keydownHandler = ({ key }: { key: string }) => {
  //     switch (key) {
  //       case "Escape":
  //         onClose();
  //         break;
  //       default:
  //     }
  //   };

  //   useEffect(() => {
  //     document.addEventListener("keydown", keydownHandler);
  //     return () => document.removeEventListener("keydown", keydownHandler);
  //   });

  return !onOpened ? null : (
    <div className="absolute top-0 left-0 right-0 z-[99999] h-full w-full overflow-hidden bg-black/20">
      <div className={`relative h-full w-full bg-white rounded-lg`}>
        <button
          className="absolute top-4 right-10 text-black bg-white rounded-full text-lg z-10"
          onClick={onClose}
        >
          <Close size={32} />
        </button>
        <div className="h-[calc(100vh-10rem)] flex flex-col">
          <div className="flex-grow overflow-y-scroll">{children}</div>
          <div className="py-10 ml-14">
            <button className={`bg-blue hover:bg-blue/50 text-white py-2 px-4 rounded`} onClick={() => handleSubmit()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}

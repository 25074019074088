import { CircleSlash, List, ListTodo } from 'lucide-react'
import React from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedTemplate } from 'src/features/courseTemplate/courseTemplateSlice';
import { DefaultTemplateData } from './Templates/Authoring/utils/DefaultTemplateData';

type Props = {}

const TemplatesArray = [
    {
        id: 1,
        title: "MCR",
        type: "mcr",
        description: "Multiple Choice Response",
        icon: <ListTodo size={24} />
    },
    {
        id: 2,
        title: "SCR",
        type: "scr",
        description: "Single Choice Response",
        icon: <List size={24} />
    },
    {
        id: 3,
        title: "True/False",
        type: "true/false",
        description: "True or False",
        icon: <CircleSlash size={24} />
    }
]

export type Template = {
    id: number;
    title: string;
    type: string;
    description: string;
    data?: any;
}

function TemplateRibbon({ }: Props) {

    const dispatch = useDispatch();

    function handleTemplateSelection(template: Template) {
        dispatch(setSelectedTemplate({
            id: new Date().getTime(),
            title: template.title,
            type: template.type,
            description: template.description,
            data: template.data || DefaultTemplateData.get(template.type)
        }))
    }

    return (
        <div className="flex justify-around items-center p-1 bg-gray-100 border border-gray-300 space-x-4 shadow-lg rounded-lg">
            {TemplatesArray.map((template, index) => (
                <div className="relative group" key={template.id}>
                    <button className="group-hover:bg-gray-200 flex flex-col items-center p-2 space-y-1" onClick={() => handleTemplateSelection(template)}>
                        <span>
                            {template.icon}
                        </span>
                        <span className="text-xs lowercase">
                            {template.title}
                        </span>
                    </button>
                    <span className="hidden absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300">
                        {template.description}
                    </span>
                </div>
            ))}
        </div>
    )
}

export default TemplateRibbon
import React from 'react'
import Question from './common/Question'
import { Question as QuestionType } from './utils/DefaultTemplateData'

type Props = {
  handleDataChange: (data: any) => void;
  data: QuestionType
}

function Scr({ data, handleDataChange }: Props) {
  return (
    <div>
      <Question type='scr' data={data} handleDataChange={(data) => {
        handleDataChange(data);
      }} />
    </div>
  )
}

export default Scr
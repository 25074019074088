import React, { useEffect, useState } from "react";
import UploadLogo from "../assets/Upload/Upload.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import Modal from "./Modal";
import Text from "./Text/Txt";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import axios from "axios";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";

type Props = {
  pageState?: any;
  type?: string | null;
  name?: string;
  handleClick?: (name: string) => void;
  handleWebLink?: (url: string) => void;
  handleUploadFromDevice?: (file: any) => void;
};

function UploadButton({ pageState, type = null, name, handleClick, handleWebLink, handleUploadFromDevice }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userId, businessUnitId, sepmId, templateId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // console.log(pageState, type)

  const [showButtons, setShowButtons] = React.useState(false);
  const [openWebLinkModal, setOpenWebLinkModal] = React.useState(false);
  const [weblinkUrl, setWeblinkUrl] = React.useState("");

  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);
  const { authUser } = useSelector((state: any) => state.user);

  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const [uploadedLogoFile, setUploadedLogoFile] = useState<any>("");

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { fileFolder } = useFolder({
    folderId: folderId === "null" ? null : folderId,
    folder,
  });
  const currentFolder = fileFolder?.folder;

 useEffect(() => {
    if (uploadedLogoFile && handleUploadFromDevice) {
      handleUploadFromDevice(uploadedLogoFile);
    }
  }, [uploadedLogoFile, handleUploadFromDevice]);

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setShowButtons(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = (e.target as HTMLInputElement).files![0];

    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles: any) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/${
            file.name
          }`
        : `${
            currentFolder.path.map((item: any) => item.name).join("/") &&
            `/${currentFolder.path.map((item: any) => item.name).join("/")}`
          }/${currentFolder.name}/${file.name}`;

    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}${filePath}`,
      file.type
    );
    console.log("upload url: ", UploadUrl, authUser.oid, filePath, file);
    axios
      .put(`${UploadUrl}`, file, {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => {
        console.log(response.data);
        //   // Set logoFile state with uploaded file data
        // setLogoFile({
        //   name: file.name,
        //   type: file.type,
        //   size: file.size,
        //   url: UploadUrl.split("?")[0], // or SignedUrl.split("?")[0] depending on your requirement
        // });
      })
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(
      `uploads/users/${authUser.oid}${filePath}`
    );

    console.log("this is the signed url: ", SignedUrl);

    setUrl(SignedUrl.split("?")[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: file.name,
        type: file.type,
        size: file.size,
        folderId,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    console.log("Added files", response.data.data);
    dispatch(setAddToChildFiles(response.data.data));
    setUploadedLogoFile(response.data.data);
  }

  return (
    <div>
      <button
        onClick={() => {

          setShowButtons((prevState) => !prevState);
        }}
        className="relative flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
      >
        <span className="text-[1rem] font-[600]  text-[#FFFFFF] ">Upload</span>
        <span>
          <img src={UploadLogo} alt="upload" />
        </span>
      </button>
      {showButtons && (
        <div ref={menuRef} className="absolute border rounded-sm bg-white flex flex-col items-start  w-[12.875rem] z-50">
          <button onClick={() => {
            if (handleClick && name) handleClick(name);
            navigate(
              `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
                "screen"
              )}&folderId=null&type=${type}`
            );
            if (pageState) dispatch(setPageState(pageState));
          }} className=" text-tertiary font-semibold px-4 py-2 rounded mr-2">Media Library</button>
          {type === "video" && <button onClick={() => setOpenWebLinkModal(true)} className=" text-tertiary font-semibold px-4 py-2 rounded">Add Web Link</button>}
          <div className=" px-4 py-2">
          <input
            className="hidden"
            onChange={(e) =>  handleUpload(e)}
            type="file"
            name=""
            id="upload-logo"
          />
            <label
              htmlFor="upload-logo"
              className="text-tertiary font-semibold rounded"
            >
              Upload from Device
            </label>
          </div>
        </div>
      )}
      <Modal
        onOpened={openWebLinkModal}
        onClose={() => setOpenWebLinkModal(false)}
        title="Add Web Link"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow">
            <Text
              value={weblinkUrl}
              onChange={(e) => setWeblinkUrl(e.target.value)}
              labelTitle="Add Link"
              autoFocus={true}
            />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={() => setOpenWebLinkModal(false)}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M25.5 7.5L7.5 25.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.5 25.5L7.5 7.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                if (handleWebLink) handleWebLink(weblinkUrl);
                setOpenWebLinkModal(false);
              }}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
  
  // (
  //   <div>
  //     <button
  //       onClick={() => {
  //         if (handleClick && name) handleClick(name);
  //         navigate(
  //           `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
  //             "screen"
  //           )}&folderId=null&type=${type}`
  //         );
  //         if (pageState) dispatch(setPageState(pageState));
  //       }}
  //       className="relative flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
  //     >
  //       <span className="text-[1rem] font-[600]  text-[#FFFFFF] ">Upload</span>
  //       <span>
  //         <img src={UploadLogo} alt="upload" />
  //       </span>
  //     </button>
  //   </div>
  // );
}

export default UploadButton;

import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import PageNotFound from "./utils/PageNotFound";
import Resourses from "./components/Resources";
import Library from "./components/Library";
import Organization from "./components/Organization";
import Learners from "./components/Learners";
import Login from "./components/Auth/Login";
import LoginSuccess from "./components/Auth/LoginSuccess";
import Course from './components/Course';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import BusinessUnit from './components/BusinessUnit';
import Program from './components/Program';
import Favourite from './components/Fovourite';
import Shared from './components/Shared';
import Templates from './components/Templates';
import Layout from './components/MediaLibrary/Layout';
import { RefType, ToastPortal } from './components/ToastComponent/ToastPortal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLog } from './features/errorLog/errorLogSlice';
import { Test } from './components/Test';
import Preview from './components/Preview';
import { Tester } from './utils/Tester';
import Publish from './components/Publish';
import Share from './components/Share';
import Nav from './components/Navbar';
import Profile from './components/Profile';
import LoginDashboardRedirect from './components/Auth/LoginDashboardRedirect';
import TokenExpire from './components/Auth/TokenExpire';
import { setAccessToken } from "src/features/token/tokenSlice";
import { setTokenExpired } from "src/features/token/tokenSliceExpire";
import Invite from './components/Invite';
import UpgradePlan from './components/UpgradePlan';
import Page from './components/Page';
import PreviewPage from './components/Page/PreviewPage';
import DragDropWidget from './components/DragDropWidget';
import CoursePreview from './components/Course/CoursePreview';
import Recent from './components/Recent';
import CoursePreviewPlayer from './components/Course/CoursePreviewPlayer';

function App({ msalInstance }: { msalInstance: PublicClientApplication }) {
  const toastRef = useRef<RefType>(null);
  const { response } = useSelector((state: any) => state.errorLog)
  const tokenExpired = useSelector((state: any) => state.tokenExpired.authTokenExpired);
  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>("");

  useEffect(() => {
    return () => {
      if (toastRef.current && response) toastRef.current.addMessage(response)
      dispatch(setLog(null));
    }
  }, [response])


  function logout() {
    dispatch(setAccessToken(null))
    dispatch(setTokenExpired(true))
    alert("Session Expired")
    // msalInstance.logoutRedirect({
    //account: msalInstance.getAccountByUsername(username),
    //});
  }

  const handleTokenExpired = () => {
    logout()
  };

  return (


    <MsalProvider instance={msalInstance}>
      <>
        {/* {(!tokenExpired) && <TokenExpire onTokenExpired={handleTokenExpired} />} */}
        <Router>
          <Routes>
            <Route path="/" element={<LoginDashboardRedirect />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user/:userId/recent" element={<Recent />} />
            <Route path="/user/:userId/favorite" element={<Favourite />} />
            <Route path="/user/:userId/shared" element={<Shared />} />
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/login-success" element={<LoginSuccess />} />
            <Route path="/user/:userId/profile" element={<Profile />} />
            <Route path="/user/:userId/subscription" element={<UpgradePlan />} />
            {/* <Route path="/course" element={<Course />} /> */}
            <Route path="/user/:userId/course/:courseId" element={<Course />} />
            <Route path="/user/:userId/course/:courseId/share" element={<Share />} />
            <Route path="/user/:userId/course/:courseId/preview" element={<CoursePreview />} />
            <Route path="/user/:userId/course/:courseId/settings" element={<CoursePreviewPlayer />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/course/:courseId" element={<Course />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/course/:courseId/share" element={<Share />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/course/:courseId/preview" element={<CoursePreview />} />
            <Route path="/dragdropwidget" element={<DragDropWidget />} />
            <Route path="/user/:userId/businessunit/:businessUnitId" element={<BusinessUnit />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId" element={<Program />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/page/:pageId" element={<Page />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/page/:pageId/media" element={<Layout />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId/template/:templateId" element={<Templates />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId/template/:templateId/media" element={<Layout />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId/share" element={<Share />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId/publish" element={<Publish />} />
            <Route path="/user/:userId/businessunit/:businessUnitId/sepm/:sepmId/preview" element={
              <main className="w-full h-screen max-w-[90rem] mx-auto max-h-[64rem] overflow-hidden font-poppins">
                <Preview />
              </main>
            } />
            <Route path="/user/:userId/businessunit/:businessUnitId/page/:pageId/preview/" element={<PreviewPage />} />
            <Route path="/test" element={<Tester />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <ToastPortal ref={toastRef} autoClose={true} autoCloseTime={5000} />
      </>
    </MsalProvider>
  );
}

export default App;

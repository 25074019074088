import React, { ElementRef, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import FolderLogo from "../../assets/Course/folder.png";
import TemplateFileLogo from "../../assets/Course/layout.png";
import { cn } from "../lib/utils";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import RenameLogo from "../../assets/TableMenu/PencilLine.svg";
import DeleteLogo from "../../assets/TableMenu/TrashSimple.svg";
import ListLogo from "../../assets/list_2.png";
import CopyLogo from "../../assets/TableMenu/Copy.svg";
import MoveUp from "../../assets/move_up.png";
import MoveDown from "../../assets/move_down.png";
import RemoveLogo from "../../assets/remove1.png";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import { setLog } from "src/features/errorLog/errorLogSlice";
import Tools from "./Tools";
import { MenuIcon } from "lucide-react";
import { Template } from "./TemplateRibbon";

export type FileSystemNode = {
  id: string;
  name: string;
  type: "file" | "folder";
  tools?: any[];
  template?: Template | null;
  depth: number; // Track the depth of the folder
  children?: FileSystemNode[];
};

type Props = {
  handleTopic: (topic: any) => void;
  topic: any;
};

function Navigation({ handleTopic, topic }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [data, setData] = useState<FileSystemNode[]>([]);
  const [inputState, setInputState] = useState<{
    type: "folder" | "file";
    parentId: string | null;
  } | null>(null);
  const [newNodeName, setNewNodeName] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  // To rename
  const [editNodeId, setEditNodeId] = useState<string | null>(null);
  const [renameNodeName, setRenameNodeName] = useState<string>("");
  const renameInputRef = useRef<HTMLInputElement>(null);
  const creationInputRef = useRef<HTMLInputElement>(null);

  const [activeNodeId, setActiveNodeId] = useState<string | null>(null);

  // const [topic, setTopic] = useState<any>();
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isResizingRef = useRef(false);
  const sidebarRef = useRef<ElementRef<"div">>(null);
  const selectedRef = useRef<ElementRef<"div">>(null);

  const [isResetting, setIsResetting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const { pageId, userId, businessUnitId, courseId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const dispatch = useDispatch();

  // Fetch existing data from API
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          setData(res.data.data.course?.data || []); // Ensure data is set as an array
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [courseId, accessToken]);

  // Save data when the structure changes (debounced)
  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`,
          { data },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    }, 500); // 500ms debounce time

    return () => clearTimeout(debounceTimeout);
  }, [data, courseId, accessToken]);

  useEffect(() => {
    const updateContent = (items: any[]) => {
      const newArr = items.map((item: any) => {
        if (item.id === topic?.id) {
          item = topic;
        } else if (item.type === "folder") {
          item.children = updateContent(item.children);
        }
        return item;
      });
      return newArr;
    };

    const newData = updateContent(data);
    setData(newData);
  }, [topic]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    isResizingRef.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizingRef.current) return;
    let newWidth = e.clientX;

    if (newWidth < 240) newWidth = 240;
    if (newWidth > 480) newWidth = 480;

    if (sidebarRef.current) {
      sidebarRef.current.style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const resetWidth = () => {
    if (sidebarRef.current) {
      setIsCollapsed(false);
      setIsResetting(true);
      sidebarRef.current.style.width = isMobile ? "100%" : "20rem";
      // navbarRef.current.style.setProperty("left", isMobile ? "100%" : "30rem");
      // navbarRef.current.style.setProperty(
      //   "width",
      //   isMobile ? "0" : "calc(100% - 30rem)"
      // );
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  const collapse = () => {
    if (sidebarRef.current) {
      setIsCollapsed(true);
      setIsResetting(true);
      sidebarRef.current.style.width = "0";
      // navbarRef.current.style.setProperty("left", "0");
      // navbarRef.current.style.setProperty("width", "100%");
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  const addNodeToParent = (
    node: FileSystemNode,
    parent: FileSystemNode | null
  ) => {
    if (!parent) {
      setData((prevData) => [...prevData, node]);
    } else {
      const addToParent = (nodes: FileSystemNode[]): FileSystemNode[] =>
        nodes.map((n) =>
          n === parent
            ? { ...n, children: [...(n.children || []), node] }
            : {
                ...n,
                children: n.children ? addToParent(n.children) : undefined,
              }
        );
      setData(addToParent(data));
    }
  };

  const handleCreateNode = () => {
    if (!inputState || newNodeName.trim() === "") return;

    const parentNode = findNodeById(data, selectedNodeId);
    const newNode: FileSystemNode = {
      id: (Math.round(Math.random() * (10000 - 1)) + 1).toString(),
      name: newNodeName,
      type: inputState.type,
      tools: inputState.type === "file" ? [] : undefined,
      template: inputState.type === "file" ? null : undefined,
      depth: parentNode ? parentNode.depth + 1 : 0, // If parentNode is undefined, default depth is 0
      children: inputState.type === "folder" ? [] : undefined,
    };

    addNodeToParent(newNode, parentNode);
    setNewNodeName("");
    setInputState(null);
    setSelectedNodeId(null);
  };

  const findNodeById = (
    nodes: FileSystemNode[],
    id: string | null
  ): FileSystemNode | null => {
    if (id === null) return null;
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDelete = (id: string) => {
    const deleteNode = (
      nodes: FileSystemNode[],
      nodeId: string
    ): FileSystemNode[] => {
      return nodes.reduce((acc: FileSystemNode[], node) => {
        // Skip the node to delete
        if (node.id === nodeId) return acc;

        // Recursively delete from children
        const updatedChildren = node.children
          ? deleteNode(node.children, nodeId)
          : [];

        // Include node only if it has children or is not a folder being deleted
        if (updatedChildren.length > 0 || node.id !== nodeId) {
          acc.push({ ...node, children: updatedChildren });
        }

        return acc;
      }, []);
    };

    setData(deleteNode(data, id));
  };

  const handleRenameNode = (nodeId: string, currentName: string) => {
    setEditNodeId(nodeId);
    setRenameNodeName(currentName);
  };

  const handleSaveRename = () => {
    if (!editNodeId || renameNodeName.trim() === "") return;

    const updateNodeName = (nodes: FileSystemNode[]): FileSystemNode[] =>
      nodes.map((node) =>
        node.id === editNodeId
          ? { ...node, name: renameNodeName }
          : {
              ...node,
              children: node.children
                ? updateNodeName(node.children)
                : undefined,
            }
      );

    setData(updateNodeName(data));
    setEditNodeId(null);
    setRenameNodeName("");
  };

  useEffect(() => {
    if (creationInputRef.current && inputState) {
      creationInputRef.current.focus();
    }
    if (renameInputRef.current && editNodeId) {
      renameInputRef.current.focus();
    }
  }, [editNodeId, inputState]);

  // Function to find and swap the nodes
  const swapNodes = (
    nodes: FileSystemNode[],
    nodeId: string,
    direction: "up" | "down"
  ): FileSystemNode[] => {
    const index = nodes.findIndex((node) => node.id === nodeId);
    if (index === -1) return nodes; // Node not found, return unchanged nodes

    if (direction === "up" && index > 0) {
      // Swap with the previous node
      [nodes[index - 1], nodes[index]] = [nodes[index], nodes[index - 1]];
    }

    if (direction === "down" && index < nodes.length - 1) {
      // Swap with the next node
      [nodes[index + 1], nodes[index]] = [nodes[index], nodes[index + 1]];
    }

    return [...nodes]; // Return a new array with swapped nodes
  };

  // Recursive function to move nodes up/down in the entire structure
  const moveNode = (
    nodes: FileSystemNode[],
    nodeId: string,
    direction: "up" | "down"
  ): FileSystemNode[] => {
    // Check if the node is at the root level (depth 0)
    const nodeAtRoot = nodes.find((node) => node.id === nodeId);
    if (nodeAtRoot) {
      // If the node is at the root level, swap it directly in the root array
      return swapNodes(nodes, nodeId, direction);
    }

    // If not at the root level, traverse the children to find and move the node
    return nodes.map((node) => {
      if (node.children && node.children.length > 0) {
        return {
          ...node,
          children: moveNode(
            swapNodes(node.children, nodeId, direction),
            nodeId,
            direction
          ),
        };
      }
      return node;
    });
  };

  const handleMoveNode = (nodeId: string, direction: "up" | "down") => {
    console.log(nodeId, direction);
    setData((prevData) => moveNode(prevData, nodeId, direction));
  };

  const handleNodeSelect = (nodeId: string) => {
    setSelectedNodeId(nodeId);
  };

  const renderStructure = (nodes: FileSystemNode[], depth: number = 0) => (
    <ul className="w-full ">
      {nodes.map((node) => (
        <div key={node.id} className=" w-full ">
          <div
            // ref={selectedRef}
            onClick={() => {
              handleNodeSelect(node.id);
              if (node.type === "file") {
                handleTopic(node);
              }
            }}
            className={`relative w-full ${
              node.depth === 0
                ? "pl-[16px]"
                : node.depth === 1
                ? "pl-[32px]"
                : node.depth === 2
                ? "pl-[48px]"
                : "pl-[64px]"
            } pr-4 py-1.5 hover:bg-[#dedede] flex items-center space-x-2 group ${
              selectedNodeId === node.id
                ? "bg-[#dedede] border border-tertiary"
                : ""
            }`}
          >
            {editNodeId === node.id ? (
              <input
                ref={renameInputRef}
                className="outline-tertiary px-[1.25rem] py-[0.75rem] text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                value={renameNodeName}
                onChange={(e) => setRenameNodeName(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSaveRename()}
                onBlur={() => handleSaveRename()}
              />
            ) : (
              <>
                {node.type === "folder" ? (
                  <img src={FolderLogo} alt="folder" className="w-5 h-5" />
                ) : (
                  <img src={TemplateFileLogo} alt="file" className="w-5 h-5" />
                )}
                <span className="text-xl">{node.name}</span>
                <div className="absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity z-[9999]">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <OverflowMenuHorizontal size={24} />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="absolute -left-10 z-[9999] bg-white">
                      <DropdownMenuGroup>
                        <DropdownMenuItem
                          onClick={() => handleRenameNode(node.id, node.name)}
                        >
                          <button className="flex items-center py-1 px-2 hover:bg-[#dedede] w-full rounded-md mx-1">
                            Rename
                          </button>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleDelete(node.id)}>
                          <button className="flex items-center py-1 px-2 hover:bg-[#dedede] w-full rounded-md mx-1">
                            Remove
                          </button>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleMoveNode(node.id, "up")}
                        >
                          <button className="flex items-center py-1 px-2 hover:bg-[#dedede] w-full rounded-md mx-1">
                            Move Up
                          </button>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleMoveNode(node.id, "down")}
                        >
                          <button className="flex items-center py-1 px-2 hover:bg-[#dedede] w-full rounded-md mx-1">
                            Move Down
                          </button>
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </>
            )}

            {node.type === "folder" &&
              selectedNodeId === node.id &&
              inputState && (
                <>
                  {/* Render input field below the selected folder */}
                  <div
                    className={`absolute top-6 left-[20%] mt-2 w-full transition-all duration-300 z-10 ${
                      selectedNodeId ? "block" : "hidden"
                    }`}
                    ref={inputRef}
                  >
                    <input
                      ref={creationInputRef}
                      className="outline-tertiary px-[1.25rem] py-[0.75rem] text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                      value={newNodeName}
                      onChange={(e) => setNewNodeName(e.target.value)}
                      onKeyDown={(e) => e.key === "Enter" && handleCreateNode()}
                      placeholder={`${
                        inputState?.type === "folder" ? "Folder" : "File"
                      } name`}
                    />
                  </div>
                </>
              )}
          </div>
          {node.type === "folder" &&
            node.children &&
            node.children.length > 0 &&
            renderStructure(node.children, depth + 1)}
        </div>
      ))}
    </ul>
  );

  // Handle clicks outside the input fields to close them
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedRef.current &&
        !selectedRef.current.contains(event.target as Node)
      ) {
        // Reset selectedNodeId if clicking outside the sidebar
        setSelectedNodeId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // if (
      //   sidebarRef.current &&
      //   !sidebarRef.current.contains(event.target as Node)
      // ) {
      //   // Reset selectedNodeId if clicking outside the sidebar
      //   setSelectedNodeId(null);
      // }

      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        // Close the input field if clicking outside
        setInputState(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const selectedNode = selectedNodeId
    ? findNodeById(data, selectedNodeId)
    : null;
  const canCreateFolder =
    !selectedNode || (selectedNode.type === "folder" && selectedNode.depth < 2);
  const canCreateFile = !selectedNode || selectedNode.type === "folder";

  useEffect(() => {
    console.log(selectedNode?.type);
  }, [selectedNode]);

  return (
    <>
      <aside className="flex">
        {/* <div
          ref={navbarRef}
          className={cn(
            "absolute top-0 z-[99999] left-60 w-[calc(100%-20rem)]",
            isResetting && "transition-all ease-in-out duration-300",
            isMobile && "left-0 w-full"
          )}
        > */}
        <nav className="bg-transparent px-3 py-2 border border-r-2 flex flex-col items-center ">
          {/* {isCollapsed && ( */}
          <>
            <div 
            // ref={selectedRef} 
            className="flex items-center justify-center p-2 cursor-pointer hover:bg-gray-200">
              <MenuIcon
                onClick={() => (isCollapsed ? resetWidth() : collapse())}
                role="button"
                size={24}
              />
            </div>
            <Tools />
          </>
          {/* )} */}
        </nav>
        {/* </div> */}

        <div
          ref={sidebarRef}
          className={cn(
            "group/sidebar h-full overflow-hidden bg-[#EFEFEF] relative flex w-[20rem] flex-col",
            isMobile && "w-0"
          )}
        >
          <div
            ref={selectedRef}
            className="w-full h-12 flex items-center justify-end space-x-3 bg-gray-200 border-b-2 border-gray-300 px-[1.28rem] py-[0.5rem]"
          >
            <div className="relative group">
              <button
                className=""
                onClick={() =>
                  canCreateFolder &&
                  setInputState({ type: "folder", parentId: selectedNodeId })
                }
                disabled={!canCreateFolder}
              >
                {selectedNode?.type === "file" || !canCreateFolder ? (
                  <></>
                ) : (
                  <img src={FolderLogo} alt="folder" className="w-7 h-7" />
                )}
              </button>
              <span className="hidden absolute bottom-[-35px] left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 z-[99999]">
                Create Folder
              </span>
            </div>
            <div className="relative group">
              <button
                className=""
                onClick={() =>
                  canCreateFile &&
                  setInputState({ type: "file", parentId: selectedNodeId })
                }
                disabled={!canCreateFile}
              >
                {selectedNode?.type === "file" ? (
                  <></>
                ) : (
                  <img src={TemplateFileLogo} alt="file" className="w-7 h-7" />
                )}
              </button>
              <span className="hidden absolute bottom-[-35px] left-1/4 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 z-[99999]">
                Create File
              </span>
            </div>
          </div>

          {/* Input fields for folder/file creation */}
          {inputState && selectedNodeId === null && (
            <div className="absolute top-10 right-0 z-10" ref={inputRef}>
              <input
                ref={creationInputRef}
                className="outline-tertiary px-[1.25rem] py-[0.75rem] text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                value={newNodeName}
                onChange={(e) => setNewNodeName(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleCreateNode()}
                placeholder={`${
                  inputState.type === "folder" ? "Folder" : "File"
                } name`}
              />
            </div>
          )}
          <div className="">{renderStructure(data)}</div>
        </div>
      </aside>
    </>
  );
}

export default Navigation;

import React from 'react'
import Layout from './common/Layout';
import { useDispatch } from 'react-redux';
import { setCanvasActiveComponent } from 'src/features/canvasActiveComponent/canvasActiveComponent';
import { useSelector } from 'react-redux';
import TextImageCentered from './layouts/TextImageCentered';

type Props = {
    type?: string;
    handleDataChange: (data: any) => void;
    data: any;
    openTemplate: any;
    handleOpenAuthoringModal?: (state: boolean) => void;
    handleCancelTemplateForm?: () => void;
    nestedTemplate?: boolean;
}

function TextWImage({ type, handleDataChange, data, openTemplate, handleOpenAuthoringModal, handleCancelTemplateForm, nestedTemplate }: Props) {

    const templateType = 'textwithimage';

    const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);

    const dispatch = useDispatch();

    return (
        <>
            {!type ? (
                <>
                    {
                        !nestedTemplate ? (
                            <Layout
                                type={templateType}
                                data={data}
                                handleDataChange={(data) => handleDataChange(data)}
                                openTemplate={openTemplate}
                                handleOpenAuthoringModal={(state) => { handleOpenAuthoringModal && handleOpenAuthoringModal(state) }}
                                handleCancelTemplateForm={() => { handleCancelTemplateForm && handleCancelTemplateForm() }}
                            >
                                <>
                                    {
                                        (data.layoutOption === 'text-left-image-right' || data.layoutOption === 'text-right-image-left') && (
                                            <div className={`flex ${data.layoutOption === 'text-left-image-right' ? "" : "flex-row-reverse"} h-full w-full`}>
                                                <div
                                                    style={data.styles.textContainer}
                                                    onClick={() => {
                                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                                            dispatch(setCanvasActiveComponent(null));
                                                        } else {
                                                            dispatch(setCanvasActiveComponent({
                                                                templateType,
                                                                componentType: 'text'
                                                            }));
                                                        }
                                                    }}
                                                    className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                                >
                                                    {data.logoImageFile &&
                                                        <div
                                                            style={data.styles.logoImageContainer}
                                                            className='flex w-full'
                                                        >
                                                            <img style={data.styles.logoImage} src={data.logoImageFile?.url} alt="logo image" className="w-[10rem] aspect-[16/9] object-contain" />
                                                        </div>
                                                    }
                                                    <div
                                                        style={data.styles.text}
                                                        className="flex flex-wrap grow">
                                                        <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                                    </div>
                                                </div>
                                                <div
                                                    style={data.styles.imageContainer}
                                                    className={`flex w-full h-full ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image' ? 'border-2 border-dashed border-blue' : ''}`}
                                                    onClick={() => {
                                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image') {
                                                            dispatch(setCanvasActiveComponent(null));
                                                        } else {
                                                            dispatch(setCanvasActiveComponent({
                                                                templateType,
                                                                componentType: 'image'
                                                            }));
                                                        }
                                                    }}
                                                >
                                                    {
                                                        data.imageFile?.url && <img
                                                            style={data.styles.image}
                                                            src={data.imageFile?.url}
                                                            alt="Image"
                                                            className='w-full h-full object-contain' />
                                                    }
                                                </div>

                                            </div>
                                        )}
                                    {
                                        data.layoutOption === 'only-text' && (
                                            <div className='flex h-full w-full'>
                                                <div
                                                    style={data.styles.textContainer}
                                                    onClick={() => {
                                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                                            dispatch(setCanvasActiveComponent(null));
                                                        } else {
                                                            dispatch(setCanvasActiveComponent({
                                                                templateType,
                                                                componentType: 'text'
                                                            }));
                                                        }
                                                    }}
                                                    className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                                >
                                                    <div
                                                        style={data.styles.text}
                                                        className="flex flex-wrap grow">
                                                        <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        data.layoutOption === 'text-image-centered' && (
                                            <TextImageCentered />
                                        )
                                    }
                                </>

                            </Layout>
                        ) : (
                            <>
                                {
                                    (data.layoutOption === 'text-left-image-right' || data.layoutOption === 'text-right-image-left') && (
                                        <div className={`flex ${data.layoutOption === 'text-left-image-right' ? "" : "flex-row-reverse"} h-full w-full`}>
                                            <div
                                                style={data.styles.textContainer}
                                                onClick={() => {
                                                    if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                                        dispatch(setCanvasActiveComponent(null));
                                                    } else {
                                                        dispatch(setCanvasActiveComponent({
                                                            templateType,
                                                            componentType: 'text'
                                                        }));
                                                    }
                                                }}
                                                className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                            >
                                                {data.logoImageFile &&
                                                    <div
                                                        style={data.styles.logoImageContainer}
                                                        className='flex w-full'
                                                    >
                                                        <img style={data.styles.logoImage} src={data.logoImageFile?.url} alt="logo image" className="w-[10rem] aspect-[16/9] object-contain" />
                                                    </div>
                                                }
                                                <div
                                                    style={data.styles.text}
                                                    className="flex flex-wrap grow">
                                                    <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                                </div>
                                            </div>
                                            <div
                                                style={data.styles.imageContainer}
                                                className={`flex w-full h-full ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image' ? 'border-2 border-dashed border-blue' : ''}`}
                                                onClick={() => {
                                                    if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image') {
                                                        dispatch(setCanvasActiveComponent(null));
                                                    } else {
                                                        dispatch(setCanvasActiveComponent({
                                                            templateType,
                                                            componentType: 'image'
                                                        }));
                                                    }
                                                }}
                                            >
                                                {
                                                    data.imageFile?.url && <img
                                                        style={data.styles.image}
                                                        src={data.imageFile?.url}
                                                        alt="Image"
                                                        className='w-full h-full object-contain' />
                                                }
                                            </div>

                                        </div>
                                    )}
                                {
                                    data.layoutOption === 'only-text' && (
                                        <div className='flex h-full w-full'>
                                            <div
                                                style={data.styles.textContainer}
                                                onClick={() => {
                                                    if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                                        dispatch(setCanvasActiveComponent(null));
                                                    } else {
                                                        dispatch(setCanvasActiveComponent({
                                                            templateType,
                                                            componentType: 'text'
                                                        }));
                                                    }
                                                }}
                                                className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                            >
                                                <div
                                                    style={data.styles.text}
                                                    className="flex flex-wrap grow">
                                                    <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    data.layoutOption === 'text-image-centered' && (
                                        <TextImageCentered />
                                    )
                                }
                            </>
                        )
                    }
                </>
            ) : (
                <>
                    {
                        (data.layoutOption === 'text-left-image-right' || data.layoutOption === 'text-right-image-left') && (
                            <div className={`flex ${data.layoutOption === 'text-left-image-right' ? "" : "flex-row-reverse"} h-full w-full`}>
                                <div
                                    style={data.styles.textContainer}
                                    onClick={() => {
                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                            dispatch(setCanvasActiveComponent(null));
                                        } else {
                                            dispatch(setCanvasActiveComponent({
                                                templateType,
                                                componentType: 'text'
                                            }));
                                        }
                                    }}
                                    className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                >
                                    {data.logoImageFile &&
                                        <div
                                            style={data.styles.logoImageContainer}
                                            className='flex w-full'
                                        >
                                            <img style={data.styles.logoImage} src={data.logoImageFile?.url} alt="logo image" className="w-[10rem] aspect-[16/9] object-contain" />
                                        </div>
                                    }
                                    <div
                                        style={data.styles.text}
                                        className="flex flex-wrap grow">
                                        <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                    </div>
                                </div>
                                <div
                                    style={data.styles.imageContainer}
                                    className={`flex w-full h-full ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image' ? 'border-2 border-dashed border-blue' : ''}`}
                                    onClick={() => {
                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'image') {
                                            dispatch(setCanvasActiveComponent(null));
                                        } else {
                                            dispatch(setCanvasActiveComponent({
                                                templateType,
                                                componentType: 'image'
                                            }));
                                        }
                                    }}
                                >
                                    {
                                        data.imageFile?.url && <img
                                            style={data.styles.image}
                                            src={data.imageFile?.url}
                                            alt="Image"
                                            className='w-full h-full object-contain' />
                                    }
                                </div>

                            </div>
                        )}
                    {
                        data.layoutOption === 'only-text' && (
                            <div className='flex h-full w-full'>
                                <div
                                    style={data.styles.textContainer}
                                    onClick={() => {
                                        if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text') {
                                            dispatch(setCanvasActiveComponent(null));
                                        } else {
                                            dispatch(setCanvasActiveComponent({
                                                templateType,
                                                componentType: 'text'
                                            }));
                                        }
                                    }}
                                    className={`flex flex-col w-full h-full overflow-hidden ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
                                >
                                    <div
                                        style={data.styles.text}
                                        className="flex flex-wrap grow">
                                        <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        data.layoutOption === 'text-image-centered' && (
                            <TextImageCentered />
                        )
                    }
                </>
            )
            }
        </>
    )
}

export default TextWImage
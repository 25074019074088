import React, { memo, useEffect, useState } from "react";
// import NavProfile from "../NavProfile";
import Logo from "../../assets/logo.png";
import NotificationIcon from "../../assets/notification.svg";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ChevronLeft } from "@carbon/icons-react";
//@ts-ignore
import MicrosoftLogo from "../../src/assets/microsoft.svg";
//@ts-ignore
import CheckLogo from "../../src/assets/check.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "src/utils/Modal";
// import Layout from "../MediaLibrary/Layout";
// import Toolbar from "../MediaLibrary/Toolbar";
// import Sidebar from "../MediaLibrary/Sidebar";
// import Main from "../MediaLibrary/Main";
import PreviewModal from "src/utils/PreviewModal";
import { cva } from "class-variance-authority";
import { Device } from "src/components/Preview/Layout";
// import Navbar from "../Preview/Navbar";
// import { cn } from "../lib/utils";
// import Preview from "../Preview";

type ButtonType = {
  name: string;
  buttonIcon: React.ReactNode;
  navigate: boolean;
  navigateUrl?: string;
};

type NavProps = {
  type: string;
  buttonsArray?: ButtonType[];
  handlePreviewModal?: (open: boolean) => void;
  handleSettingsModal?: (open: boolean) => void;
  handleProgram?: (program: any) => void;
  handleCourse?: (course: any) => void;
  handlePage?: (page: any) => void;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
});

const ProgramNav = memo(
  ({
    type,
    buttonsArray,
    handlePreviewModal,
    handleSettingsModal,
    handleProgram,
    handleCourse,
    handlePage
  }: NavProps) => {
    const { instance } = useMsal();
    const [activeList, setActiveList] = useState("");
    const [program, setProgram] = useState<any>();
    const [course, setCourse] = useState<any>();
    const [page, setPage] = useState<any>();
    const [businessUnit, setBusinessUnit] = useState<any>();
    const [openMediaLibrary, setOpenMediaLibrary] = useState(false);

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [deviceVariantState, setDeviceVariantState] =
      useState<Device>("default");

    const [items, setItems] = useState<any>([]);

    const { businessUnitId, sepmId, pageId, courseId, templateId, authorId, userId } =
      useParams();
    const { accessToken } = useSelector((state: any) => state.accessToken);

    const { saveChanges } = useSelector((state: any) => state.saveChanges);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
      if (type === "Sepm") {
        getProgram();
      } else if (type === "Course") {
        getCourse();
      } else getPage();
      console.log(buttonsArray);
    }, []);

    async function getProgram() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/detail/${sepmId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setProgram(response.data.data.program);
          setBusinessUnit(response.data.data.businessUnit);
          if (handleProgram) handleProgram(response.data.data.program);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getCourse() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setCourse(response.data.data.course);
          setBusinessUnit(response.data.data.businessUnit);
          if (handleCourse) handleCourse(response.data.data.course);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getPage() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/get/${pageId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setPage(response.data.data.page);
          setBusinessUnit(response.data.data.businessUnit);
          if (handlePage) handlePage(response.data.data.page);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const handleSignIn = () => {
      instance.loginRedirect({
        scopes: ["User.Read"],
      });
    };

    return (
      <div className="flex justify-between px-[1.44rem]  items-center border-b bg-[#E1E1E1] h-[4.375rem]">
        <div className="flex space-x-4">
          <div className="flex items-center justify-between space-x-2">
            <a
              href={`/user/${userId}/businessunit/${businessUnitId}`}
              className="flex items-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20 26L10 16L20 6"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            {type === "Sepm" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title}/{program?.title}
              </span>
            )}
            {type === "Page" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title}/{page?.title}
              </span>
            )}
            {type === "Course" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title}/{course?.title}
              </span>
            )}
          </div>
        </div>
        {type === "Sepm" && (
          <div className="text-xs flex items-end border-l-4 border-green-500 p-3 bg-[#ededed] rounded-md">
            {saveChanges ? (
              <span>Saving...</span>
            ) : (
              <span className="flex space-x-3">
                <span className="w-4 h-4">
                  <img src={CheckLogo} alt="logo" />
                </span>
                <span>Changes saved!</span>
              </span>
            )}
          </div>
        )}
        <AuthenticatedTemplate>
          <div className="flex items-center space-x-[2rem]">
            {buttonsArray?.map((button, idx: number) => (
              <button
                key={idx}
                onClick={() => {
                  if (button.navigate && button.navigateUrl) {
                    navigate(button.navigateUrl)
                  }
                  else if (button.name === "Preview") {
                    if (handlePreviewModal) handlePreviewModal(true);
                  }
                  else if (button.name === "Settings") {
                    if (handleSettingsModal) handleSettingsModal(true);
                  }
                }
                }
                className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
              >
                {button.buttonIcon}
                <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                  {button.name}
                </span>
              </button>
            ))}
            {/* <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
                    "screen"
                  )}&folderId=null`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={MediaLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Media Library
              </span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/share`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={ShareLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Share
              </span>
            </button>
            <button
              onClick={() => {
                if (handlePreviewModal) handlePreviewModal(true);
              }}
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center "
            >
              <img src={PreviewLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Preview
              </span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/publish`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={ExportLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Publish
              </span>
            </button>
            <button
              onClick={() => {
                if (handleSettingsModal) handleSettingsModal(true);
              }}
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center "
            >
              <img src={SettingLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Settings
              </span>
            </button> */}
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <button
            className="flex items-center px-2 py-1 space-x-2 bg-white text-black font-semibold rounded-sm"
            onClick={() => handleSignIn()}
          >
            <span className="h-8 w-8">
              <img src={MicrosoftLogo} alt="logo" />
            </span>
            <span>Sign in</span>
          </button>
        </UnauthenticatedTemplate>
      </div>
    );
  }
);

export default ProgramNav;

import React, { useEffect, useState } from "react";
import { Option } from "../../Authoring/utils/DefaultTemplateData";
import { stat } from "fs";
import { Check, X } from "lucide-react";

type Props = {
  type: string;
  data: any;
  coursePreview?: boolean;
  handleResponses?: (responses: any) => void;
  submitted?: boolean;
  status?: any;
  handleCheckResponse?: () => void;
  openFeedback?: any;
  handleOpenFeedback?: (openFeedBack: any) => void;
  canvasDimensions: {
    width: number;
    height: number;
  };
};

function Question({
  type,
  data,
  coursePreview,
  handleResponses,
  submitted = false,
  handleCheckResponse,
  openFeedback,
  handleOpenFeedback,
  status = "",
  canvasDimensions,
}: Props) {
  const [options, setOptions] = useState<Array<Option>>([]);
  const [occupiedCells, setOccupiedCells] = useState<Array<any>>([]); // Track occupied cells
  const [openFeedBack, setOpenFeedBack] = useState("");

  useEffect(() => {
    setOptions(data.options);
  }, [data]);

  useEffect(() => {
    handleResponses && handleResponses(options);
  }, [options]);

  // Fisher-Yates shuffle algorithm
  // const shuffleArray = (array: Option[]) => {
  //   const shuffled = [...array];
  //   for (let i = shuffled.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  //   }
  //   return shuffled;
  // };

  // useEffect(() => {
  //   // Shuffle options when component mounts
  //   setShuffledOptions(shuffleArray(options));
  // }, [options]);

  useEffect(() => {
    setOpenFeedBack(openFeedback);
  }, [openFeedback]);

  useEffect(() => {
    console.log(submitted);
    console.log(coursePreview);
    console.log(status);
  }, [submitted, status]);

  // Function to calculate the coordinates
  const getZoneCoordinates = (zone: number[][]) => {
    if (!zone || zone.length === 0) return null;

    let rowStart: number | null = null;
    let rowEnd: number | null = null;
    let colStart: number | null = null;
    let colEnd: number | null = null;

    zone.forEach((row: number[], rowIndex: number) => {
      row.forEach((col: number, colIndex: number) => {
        if (col) {
          if (rowStart === null) rowStart = rowIndex;
          rowEnd = rowIndex;

          if (colStart === null) colStart = colIndex;
          colEnd = colIndex;
        }
      });
    });

    if (
      rowStart === null ||
      rowEnd === null ||
      colStart === null ||
      colEnd === null
    ) {
      return null;
    }

    return {
      rowStart,
      rowEnd,
      colStart,
      colEnd,
    };
  };

  const isOverlapping = (zoneA: any, zoneB: any) => {
    // Check if rows and columns overlap (same rowStart, rowEnd, colStart, colEnd)
    return (
      zoneA.rowStart === zoneB.rowStart && zoneA.colStart === zoneB.colStart
    );
  };

  // Handle occupied cells calculation on initial render or when data changes
  useEffect(() => {
    const calculateOccupiedCells = () => {
      const cells = data.componentProperties
        ?.map((component: any) => getZoneCoordinates(component.zone))
        .filter((zone: any) => zone !== null);

      setOccupiedCells(cells || []);
    };

    calculateOccupiedCells();
  }, [data]);


  // Function to format time in MM:SS format
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Adjust spacing if zones overlap
  const handleOverlap = (currentZone: any) => {
    if (occupiedCells.some((zone: any) => isOverlapping(zone, currentZone))) {
      return { marginTop: "20px" }; // Add spacing if overlap is detected
    }
    return {};
  };

  const renderComponent = (
    componentType: string,
    componentData: any,
    offset: number
  ) => {
    const zone = getZoneCoordinates(
      data.componentProperties?.find((c: any) => c.type === componentType)?.zone
    );
    if (!zone) return null;

    const style: React.CSSProperties = {
      gridRowStart: zone.rowStart + 1,
      gridRowEnd: zone.rowEnd + 2,
      gridColumnStart: zone.colStart + 1,
      gridColumnEnd: zone.colEnd + 2,
      marginTop: offset * 10, // Keeping it as a number
      position: "relative", // Ensuring 'position' uses the correct type
      // display: "flex",
      // justifyContent: "left",
      // alignItems: 'center',
      // alignItems: componentType === 'Question' ? 'start' : 'center',
      paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
      paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
    };

    return (
      <>
        {componentType === "Question" &&
          <div key={componentType} style={{ ...style, ...data.styles.questionContainer }} className="">
            <div style={{
              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
              ...data.styles.question
            }} className={`text-[16px]`}>{data.question}</div>
          </div>
        }

        {componentType === "Image" &&
          <div key={componentType} style={{ ...style, ...data.styles.imageContainer }} className="">
            <img style={data.styles.image} className="rounded-md h-full max-w-max object-contain" src={data?.imageFile?.url} alt="" />
          </div>
        }

        {componentType === "Prompt" &&
          <div key={componentType} style={{ ...style, ...data.styles.promptContainer }} className="">
            <div style={{
              fontSize: `calc(${14 * (canvasDimensions.width / 960)}px)`,
              ...data.styles.prompt
            }} className={`text-[14px] font-light`}>{data.prompt}</div>
          </div>
        }

        {componentType === "Choices Feedback" && (
          <div key={componentType} style={{ ...style, ...data.styles.optionsContainer, alignItems: "flex-start" }} className="">
            <div
              style={{
                fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                gap: data.haveOptionImage
                  ? `${24 * (canvasDimensions.width / 960)}px`
                  : `${24 * (canvasDimensions.width / 960)}px`,
              }}
              className="flex flex-col">
              {/* {shuffledOptions?.map((option: Option, index: number) => ( */}
              {options?.map(
                (option: Option, index: number) =>
                  option.feedback &&
                  submitted && (
                    <div style={{ ...data.styles.feedbackOption, width: data.styles.option.height }} className="bg-black/50 rounded-md p-1">
                      <span key={index} className="text-white">
                        {option.feedback}
                      </span>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
        {componentType === "Choices" && (
          <div key={componentType} style={{ ...style, ...data.styles.optionsContainer }} className="">
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: `${10 * (canvasDimensions.width / 960)}px`,
              ...data.styles.option
            }} >
              <OptionChoices
                type={type}
                submitted={submitted ?? false}
                // options={shuffledOptions}
                options={options}
                coursePreview={coursePreview}
                setOptions={setOptions}
                status={status}
                isSubmitType={data.isSubmitType}
                isNextType={data.isNextType}
                handleCheckResponse={handleCheckResponse}
                componentProperties={data.componentProperties}
                canvasDimensions={canvasDimensions}
              />
            </div>
          </div>
        )}
        {componentType === "Global Feedback" && (
          <div key={componentType} style={{ ...style, ...data.styles.globalFeedbackContainer }} className="">
            <div
              style={{
                fontSize: '20px',
                lineHeight: '28px',
                ...data.styles.globalFeedback
              }}
              className="text-xl text-tertiary">
              {data.isGlobalPopup === false &&
                coursePreview &&
                openFeedBack &&
                data.globalFeedback}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>

      <div
        className={`p-2 grid gap-0`}
        style={{
          display: "grid",
          gridAutoRows: `minmax(auto, 0fr)`,
          gridTemplateColumns: `repeat(2, 1fr)`,
          padding: `${8 * (canvasDimensions.width / 960)}px`,
          height: `${(canvasDimensions.width * 9 / 16) - (32 * (canvasDimensions.width / 960))}px`,
        }}
      >
        {renderComponent("Question", data, 0)}
        {renderComponent("Image", data, 0)}
        {renderComponent("Prompt", data, 0)}
        {renderComponent("Choices Feedback", data, 0)}
        {renderComponent("Choices", data, 0)}
        {renderComponent("Global Feedback", data, 0)}
      </div>
      {openFeedBack && data.globalFeedback && (
        <>
          {data.isGlobalPopup && (
            <div className="absolute top-[30%] left-0 right-0 bottom-0 h-[30%] w-[100%] bg-gray-100 flex flex-col z-[99] border border-black/25 shadow-lg shadow-black/25 rounded-md">
              <div className="bg-tertiary text-white w-full h-[30%] text-[2rem] flex items-center justify-center rounded-t-md">
                Global Feedback
              </div>
              <div className="text-xl text-tertiary w-full h-[70%] flex items-center justify-center">
                {data.globalFeedback}
              </div>
              <X
                className="absolute top-5 right-5 text-white"
                role="button"
                size={28}
                onClick={() => {
                  setOpenFeedBack("");
                  if (handleOpenFeedback) handleOpenFeedback(openFeedBack);
                  // setOpenFeedback('');
                  // setResponses([]); //Reset Responses
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Question;

const OptionChoices = ({
  type,
  options,
  setOptions,
  coursePreview,
  submitted,
  status,
  isSubmitType,
  isNextType,
  handleCheckResponse,
  componentProperties,
  isTimeOver,
  canvasDimensions,
}: {
  type: string;
  options: Option[];
  setOptions: (options: Option[]) => void;
  coursePreview?: boolean;
  submitted: boolean;
  status: any;
  isSubmitType?: boolean;
  isNextType?: boolean;
  handleCheckResponse?: () => void;
  componentProperties?: any;
  isTimeOver?: boolean;
  canvasDimensions: {
    width: number;
    height: number;
  };
}) => {
  console.log("options:", options);

  if (type === "true/false") {
    return (
      <>
        {!coursePreview ? (
          options?.map((option, index) => {
            return (
              <div className="flex space-x-2 items-center mb-1" key={index}>
                <div
                  style={{
                    height: `${40 * (canvasDimensions.width / 960)}px`,
                    width: `${40 * (canvasDimensions.width / 960)}px`,
                    borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                  }}
                  className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md border-gray-300 flex items-center justify-cente flex-shrink-0 ${option.correct ? "bg-green-500 " : "bg-red-500 "
                    }`}
                >
                  {/* {option.correct ? (
                                <Check size={24} className="text-green-500" />
                              ) : (
                                <X size={24} className="text-red-500" />
                              )} */}
                </div>
                {option?.optionImage && (
                  <div
                    style={{
                      width: `${96 * (canvasDimensions.width / 960)}px`,
                      height: `${64 * (canvasDimensions.width / 960)}px`,
                      borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                      borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                    }}
                    className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0">
                    <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                      <img
                        className="max-w-max h-full object-contain rounded-md"
                        src={option?.optionImage?.url}
                        alt=""
                      />
                    </span>
                  </div>
                )}

                {option?.value && (
                  <div
                    style={{
                      borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                      borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                      minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                    }}
                    className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                    <div
                      style={{
                        borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                        paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                        paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                        borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                      }}
                      className="flex w-full items-center space-x-4 border-2 border-white px-2 py-1 rounded-[1rem] cursor-not-allowed flex-shrink">
                      <span style={{
                        fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                        lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                      }} className={`text-[16px] whitespace-nowrap`}>{option.value}</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>
            {options?.map((option, index) => {
              const bgColor = submitted
                ? option.response
                  ? !option.correct // red for true (in correct)
                    ? "bg-red-400 text-white"
                    : "bg-green-400 text-white" // green for false (correct)
                  : ""
                : option.response
                  ? "bg-gray-300"
                  : "";

              const borderColor = submitted
                ? !option.response
                  ? option.correct
                    ? "border-green-500"
                    : "border-gray-300"
                  : option.correct
                    ? "border-green-500"
                    : "border-red-500"
                : "border-gray-300";

              return (
                <div
                  key={index}
                  className={``}
                  // onClick={() => handleSelectOption(option._id)}
                  onClick={() =>
                    !submitted &&
                    setOptions(
                      options.map((op) => {
                        if (op._id === option._id) {
                          return { ...op, response: true };
                        }
                        return { ...op, response: false };
                      })
                    )
                  }
                >
                  <div className={`flex space-x-2 items-center mb-1`}>
                    <div
                      style={{
                        height: `${40 * (canvasDimensions.width / 960)}px`,
                        width: `${40 * (canvasDimensions.width / 960)}px`,
                        borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                      }}
                      className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md  border-gray-300 flex items-center justify-center flex-shrink-0 ${bgColor} `}
                    >
                      {/* {
                            (submitted) ? option.correct ?
                              <Check size={24} className="text-green-500" /> : <X size={24} className="text-red-500" /> : <></>
                          } */}
                    </div>
                    {option?.optionImage && (
                      <div
                        style={{
                          width: `${96 * (canvasDimensions.width / 960)}px`,
                          height: `${64 * (canvasDimensions.width / 960)}px`,
                          borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                          borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                        }}
                        className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0">
                        <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                          <img
                            className="max-w-max h-full object-contain rounded-md"
                            src={option?.optionImage?.url}
                            alt=""
                          />
                        </span>
                      </div>
                    )}

                    {option?.value && (
                      <div
                        style={{
                          borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                          borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                          minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                        }}
                        className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                        {isSubmitType === true || isNextType === true ? (
                          <div
                            style={{
                              borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                              borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                            }}
                            className={`  w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                          >
                            <span style={{
                              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                              lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                            }} className={`text-[16px] whitespace-nowrap`}>{option.value}</span>
                          </div>
                        ) : (
                          <div
                            onClick={handleCheckResponse}
                            style={{
                              borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                              borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                            }}
                            className={` w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                          >
                            <span style={{
                              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                              lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                            }} className={`text-[16px] whitespace-nowrap`}>{option.value}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  }

  if (type === "mcr") {
    return (
      <>
        {!coursePreview
          ? options.map((option, index) => {
            return (
              <div className="flex space-x-2 items-center mb-1" key={index}>
                <div
                  style={{
                    height: `${40 * (canvasDimensions.width / 960)}px`,
                    width: `${40 * (canvasDimensions.width / 960)}px`,
                    borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                  }}
                  className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md border-gray-300 flex items-center justify-cente flex-shrink-0 ${option.correct ? "bg-green-500 " : "bg-red-500 "
                    }`}
                >
                  {/* {option.correct ? (
                                <Check size={24} className="text-green-500" />
                              ) : (
                                <X size={24} className="text-red-500" />
                              )} */}
                </div>
                {option?.optionImage && (
                  <div
                    style={{
                      width: `${96 * (canvasDimensions.width / 960)}px`,
                      height: `${64 * (canvasDimensions.width / 960)}px`,
                      borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                      borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                    }}
                    className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0">
                    <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                      <img
                        className="max-w-max h-full object-contain rounded-md"
                        src={option?.optionImage?.url}
                        alt=""
                      />
                    </span>
                  </div>
                )}

                {option?.value && (
                  <div
                    style={{
                      borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                      borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                      minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                    }}
                    className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                    <div
                      style={{
                        borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                        paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                        paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                        borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                      }}
                      className={` flex w-full items-center space-x-4 border-2 border-white px-2 py-1 rounded-[1rem]  cursor-not-allowed flex-shrink`}
                    >
                      <span style={{
                        fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                        lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                      }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })
          : options.map((option, index) => {
            const bgColor = submitted
              ? option.response
                ? status === "correct" && option.correct
                  ? "bg-green-500 text-white"
                  : status === "incorrect" && option.correct
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white"
                : option.correct
                  ? "bg-green-500 text-white"
                  : ""
              : option.response
                ? "bg-gray-300"
                : "";
            const borderColor = submitted
              ? !option.response
                ? option.correct
                  ? "border-green-500"
                  : "border-gray-300"
                : option.correct
                  ? "border-green-500"
                  : "border-red-500"
              : "border-gray-300";

            return (
              <div
                className={``}
                key={index}
                onClick={() =>
                  !submitted &&
                  setOptions(
                    options.map((op) => {
                      if (op._id === option._id) {
                        return { ...op, response: !op.response };
                      }
                      return op;
                    })
                  )
                }
              >
                <div className={`flex space-x-2 items-center mb-1`}>
                  <div
                    style={{
                      height: `${40 * (canvasDimensions.width / 960)}px`,
                      width: `${40 * (canvasDimensions.width / 960)}px`,
                      borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                    }}
                    className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md  border-gray-300 flex items-center justify-center flex-shrink-0 ${bgColor} `}
                  >
                    {/* {
                            (submitted) ? option.correct ?
                              <Check size={24} className="text-green-500" /> : <X size={24} className="text-red-500" /> : <></>
                          } */}
                  </div>
                  {option?.optionImage && (
                    <div
                      style={{
                        width: `${96 * (canvasDimensions.width / 960)}px`,
                        height: `${64 * (canvasDimensions.width / 960)}px`,
                        borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                        borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                      }}
                      className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0">
                      <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                        <img
                          className="max-w-max h-full object-contain rounded-md"
                          src={option?.optionImage?.url}
                          alt=""
                        />
                      </span>
                    </div>
                  )}

                  {option?.value && (
                    <div
                      style={{
                        borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                        borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                        minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                      }}
                      className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                      {isSubmitType === true || isNextType === true ? (
                        <div
                          style={{
                            borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                            paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                            paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                            paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                            paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                            borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                          }}
                          className={`w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                        >
                          <span style={{
                            fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                            lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                          }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                        </div>
                      ) : (
                        <div
                          onClick={handleCheckResponse}
                          style={{
                            borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                            paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                            paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                            paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                            paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                            borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                          }}
                          className={` w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                        >
                          <span style={{
                            fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                            lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                          }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </>
    );
  }

  if (type === "scr") {
    return (
      <>
        {!coursePreview ? (
          options?.map((option, index) => {
            return (
              <div className=" flex items-center space-x-2 mb-1" key={index}>
                <div
                  style={{
                    height: `${40 * (canvasDimensions.width / 960)}px`,
                    width: `${40 * (canvasDimensions.width / 960)}px`,
                    borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                  }}
                  className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md border-gray-300 flex items-center justify-center flex-shrink-0 ${option.correct ? "bg-green-500 " : "bg-red-500 "
                    }`}
                >
                  {/* {option.correct ? (
                                <Check size={24} className="text-green-500" />
                              ) : (
                                <X size={24} className="text-red-500" />
                              )} */}
                </div>
                {option?.optionImage && (
                  <div style={{
                    width: `${96 * (canvasDimensions.width / 960)}px`,
                    height: `${64 * (canvasDimensions.width / 960)}px`,
                    borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                    borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                  }} className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0" >
                    <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                      <img
                        className="max-w-max h-full object-contain rounded-md"
                        src={option?.optionImage?.url}
                        alt=""
                      />
                    </span>
                  </div>
                )}

                {option.value && (
                  <div style={{
                    borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                    borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                    minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                  }} className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                    <div
                      style={{
                        borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                        paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                        paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                        paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                        borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                      }}
                      className={` flex w-full items-center space-x-4 border-2 border-white px-2 py-1 rounded-[1rem] cursor-not-allowed flex-shrink`}
                    >
                      <span style={{
                        fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                        lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                      }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>
            {options?.map((option, index) => {
              const bgColor = submitted
                ? option.response
                  ? status === "correct" && option.correct
                    ? "bg-green-500 text-white"
                    : status === "incorrect" && option.correct
                      ? "bg-green-500 text-white"
                      : "bg-red-500 text-white"
                  : option.correct
                    ? "bg-green-500 text-white"
                    : ""
                : option.response
                  ? "bg-gray-300"
                  : "";

              const borderColor = submitted
                ? !option.response
                  ? option.correct
                    ? "border-green-500"
                    : "border-gray-300"
                  : option.correct
                    ? "border-green-500"
                    : "border-red-500"
                : option.response
                  ? "bg-gray-400"
                  : "";

              return (
                <div
                  className={``}
                  key={index}
                  onClick={() =>
                    !submitted &&
                    setOptions(
                      options.map((op) => {
                        if (op._id === option._id) {
                          return { ...op, response: true };
                        }
                        return { ...op, response: false };
                      })
                    )
                  }
                >
                  <div className={`flex space-x-2 items-center mb-1`}>
                    <div
                      style={{
                        height: `${40 * (canvasDimensions.width / 960)}px`,
                        width: `${40 * (canvasDimensions.width / 960)}px`,
                        borderWidth: `${3 * (canvasDimensions.width / 960)}px`,
                      }}
                      className={`h-[2.5rem] w-[2.5rem] border-[3px] rounded-md  border-gray-300 flex items-center justify-center flex-shrink-0 ${bgColor} `}
                    >
                      {/* {
                            (submitted) ? option.correct ?
                              <Check size={24} className="text-green-500" /> : <X size={24} className="text-red-500" /> : <></>
                          } */}
                    </div>
                    {option?.optionImage && (
                      <div style={{
                        width: `${96 * (canvasDimensions.width / 960)}px`,
                        height: `${64 * (canvasDimensions.width / 960)}px`,
                        borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                        borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                      }} className="w-[6rem] h-[4rem] border-2 border-gray-300 rounded-md flex-shrink-0" >
                        <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
                          <img
                            className="max-w-max h-full object-contain rounded-md"
                            src={option?.optionImage?.url}
                            alt=""
                          />
                        </span>
                      </div>
                    )}

                    {option?.value && (
                      <div
                        style={{
                          borderWidth: `calc(${3 * (canvasDimensions.width / 960)}px)`,
                          borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                          minWidth: `calc(${208 * (canvasDimensions.width / 960)}px)`,
                        }}
                        className="flex min-w-[13rem] grow border-[3px] border-gray-300 rounded-[1rem] bg-white">
                        {isSubmitType === true || isNextType === true ? (
                          <div
                            style={{
                              borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                              borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                            }}
                            className={`  w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                          >
                            <span style={{
                              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                              lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                            }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                          </div>
                        ) : (
                          <div
                            onClick={handleCheckResponse}
                            style={{
                              borderWidth: `${2 * (canvasDimensions.width / 960)}px`,
                              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingRight: `${8 * (canvasDimensions.width / 960)}px`,
                              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                              paddingLeft: `${8 * (canvasDimensions.width / 960)}px`,
                              borderRadius: `${16 * (canvasDimensions.width / 960)}px`,
                            }}
                            className={` w-full flex items-center border-2 border-white px-2 py-1 rounded-[1rem] cursor-pointer flex-shrink`}
                          >
                            <span style={{
                              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                              lineHeight: `calc(${24 * (canvasDimensions.width / 960)}px)`,
                            }} className={`text-[18px] whitespace-nowrap`}>{option.value}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  }
};

import React, { useEffect, useState } from 'react'
import { Option } from '../../Authoring/utils/DefaultTemplateData'

type Props = {
    type: string,
    data: any,
    coursePreview?: boolean;
    handleResponses?: (responses: any) => void;
}

function Question({ type, data, coursePreview, handleResponses }: Props) {
    const [options, setOptions] = useState<Array<Option>>([])

    useEffect(() => {
        setOptions(data.options)
    }, [data])

    useEffect(() => {
        handleResponses && handleResponses(options);
    }, [options])

    return (
        <div className={"p-4 space-y-4"}>
            <div className={`flex flex-col ${coursePreview ? "space-y-2" : ""}`}>
                <span className={`font-semibold ${coursePreview ? "text-3xl font-bold" : "text-lg"}`}>Question</span>
                <span className={`${coursePreview ? "text-lg font-semibold" : ""}`}>{data.question}</span>
            </div>
            <div>
                <span className={`${coursePreview ? "text-sm font-normal" : "text-xs font-light"} `}>{data.prompt}</span>
            </div>
            <div className='flex flex-col'>
                {OptionChoices({ type, options: options, coursePreview, setOptions: (options: Option[]) => { setOptions(options); } })}
            </div>
        </div>
    )
}

export default Question

const OptionChoices = ({ type, options, setOptions, coursePreview }: { type: string, options: Option[], setOptions: (options: Option[]) => void, coursePreview?: boolean }) => {
    console.log('options:', options)
    if (type === 'true/false') {
        return (
            <div className='flex items-center justify-evenly mt-6'>
                {
                    !coursePreview ? options.map((option, index) => {
                        return (
                            <div className={`${option.correct && "bg-tertiary text-white"} px-3 py-1`} key={index}>
                                <input type="radio" className='hidden' checked={option.correct} />
                                <span className='text-lg'>{option.value}</span>
                            </div>
                        )
                    })
                        :
                        options.map((option, index) => {
                            return (
                                <div key={index}>
                                    <label htmlFor={`truefalse-option-${index}`} className={`${option.response && "bg-tertiary text-white"} text-lg border border-tertiary cursor-pointer px-3 py-1`}>{option.value}
                                        <input id={`truefalse-option-${index}`} checked={option.response} type="radio" className='hidden'
                                            onChange={(e) => setOptions(options.map((op) => {
                                                if (op._id === option._id) {
                                                    return { ...op, response: e.target.checked }
                                                } else
                                                    return { ...op, response: false };
                                            }))} />

                                    </label>
                                </div>
                            )
                        })
                }
            </div>
        )
    }

    if (type === 'mcr') {
        return (
            <div>
                {
                    !coursePreview ? options.map((option, index) => {
                        return (
                            <div className="flex items-center space-x-4" key={index}>
                                <input className='accent-tertiary' type="checkbox" checked={option.correct} />
                                <span className=''>{option.value}</span>
                            </div>
                        )
                    })
                        :
                        options.map((option, index) => {
                            return (
                                <div className="flex items-center space-x-4" key={index}>
                                    <input className='accent-tertiary' type="checkbox"
                                        onChange={(e) => setOptions(options.map((op) => {
                                            if (op._id === option._id) {
                                                return { ...op, response: e.target.checked }
                                            }
                                            return op
                                        }))} />
                                    <span className=''>{option.value}</span>
                                </div>
                            )
                        })
                }
            </div>
        )
    }

    if (type === 'scr') {
        return (
            <div>
                {
                    !coursePreview ? options.map((option, index) => {
                        return (
                            <div className="flex items-center space-x-4" key={index}>
                                <input className='accent-tertiary' type="radio" checked={option.correct} />
                                <span>{option.value}</span>
                            </div>
                        )
                    })
                        :
                        options.map((option, index) => {
                            return (
                                <div className="flex items-center space-x-4" key={index}>
                                    <input className='accent-tertiary' type="radio"
                                        onChange={(e) => setOptions(options.map((op) => {
                                            if (op._id === option._id) {
                                                return { ...op, response: e.target.checked }
                                            }
                                            return op
                                        }))} />
                                    <span>{option.value}</span>
                                </div>
                            )
                        })
                }
            </div>
        )
    }
}
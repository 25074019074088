import React, { useEffect, useRef, useState } from 'react';
import Tool from './Tool';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeSelectedTemplate, removeSelectedTool } from 'src/features/courseTemplate/courseTemplateSlice';
import { GetTemplate } from './Templates/Authoring/utils/GetTemplate';
import { Mcr } from './Templates/Preview';
import { GetPreviewTemplate } from './Templates/Preview/utils/GetPreviewTempalte';

export type Tool = {
    id: number;
    type: string;
    x: number;
    y: number;
    width: string | number;
    height: string | number;
    content: string;
};

type Props = {
    topic: any;
    handleTopicChange: (topic: any) => void;
    openTemplate: any;
}


const Canvas = ({ topic, handleTopicChange, openTemplate }: Props) => {
    const [tools, setTools] = useState<Array<Tool>>([]);
    const [template, setTemplate] = useState<any>(null);

    const [openAuthoringModal, setOpenAuthoringModal] = useState(false);
    const [selectedTemplateState, setSelectedTemplateState] = useState<any>(null);

    const canvasRef = useRef<HTMLDivElement>(null);
    const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
            const canvas = canvasRef.current;
            if (canvas) {
                const canvasWidth = canvas.offsetWidth;
                const canvasHeight = canvas.offsetHeight;
                setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
            }
        };

        // Initial resize
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setTools(topic.tools);
        setTemplate(topic.template);
    }, [topic]);

    const { selectedTemplate, selectedTool } = useSelector((state: any) => state.courseTemplate);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedTemplate) {
            console.log(selectedTemplate);
            setTemplate(selectedTemplate);
            setSelectedTemplateState(selectedTemplate);
            dispatch(removeSelectedTemplate());
        }

    }, [selectedTemplate]);

    useEffect(() => {
        if (selectedTool) {
            setTools([...tools, selectedTool]);
            dispatch(removeSelectedTool());
        }

    }, [selectedTool]);

    useEffect(() => {
        console.log(template);
    }, [tools, template]);

    function handleDataChange(data: any) {
        console.log(data);
        setTemplate({ ...template, data });
        handleTopicChange({ ...topic, template: { ...template, data } });
    }


    const addTool = (type: string) => {
        const newTool = {
            id: Date.now(),
            type,
            x: 50,
            y: 50,
            width: 100,
            height: 50,
            content: type === 'text' ? 'Text' : 'Image'
        };
        setTools([...tools, newTool]);
    };

    const handleDragStop = (id: number, x: number, y: number) => {
        const updatedTools = tools.map(tool => tool.id === id ? { ...tool, x, y } : tool);
        setTools(updatedTools);
        handleTopicChange({ ...topic, tools: updatedTools })
    };

    const handleResizeStop = (id: number, width: string, height: string) => {
        const updatedTools = tools.map(tool => tool.id === id ? { ...tool, width, height } : tool);
        setTools(updatedTools);
        handleTopicChange({ ...topic, tools: updatedTools })
    };

    useEffect(() => {
        console.log(template?.type);
    }, []);

    useEffect(() => {
        console.log(openAuthoringModal);
    }, [openAuthoringModal]);

    return (
        <div className="canvas-container">
            {/* <div className="toolbar">
                <button onClick={() => addTool('text')}>Add Text</button>
                <button onClick={() => addTool('image')}>Add Image</button>
            </div> */}
            <div ref={canvasRef} style={{ height: `${(canvasDimensions.width) * 9 / 16}px` }} className={`canvas ${openAuthoringModal ? "no-relative" : ""}`} id="canvas">
                {!template ? (
                    tools.length > 0 ? (
                        tools.map((tool) => (
                            <Tool
                                key={tool.id}
                                tool={tool}
                                onDragStop={handleDragStop}
                                onResizeStop={handleResizeStop}
                            />
                        ))
                    ) : (<div className='text-lg font-medium flex w-full h-full justify-center items-center'>
                        <span>Insert a template or create a blank presentation template</span>
                    </div>)
                ) :
                    (
                        (() => {
                            const TemplateComponent = GetPreviewTemplate.get(template.type);
                            return TemplateComponent ?
                                <TemplateComponent
                                    data={template.data}
                                    handleDataChange={handleDataChange}
                                    handleOpenAuthoringModal={(state: boolean) => setOpenAuthoringModal(state)}
                                    handleCancelTemplateForm={() => {
                                        if (selectedTemplateState) {
                                            setTemplate(null);
                                            setSelectedTemplateState(null);
                                        }
                                    }}
                                    openTemplate={openTemplate}
                                    canvasDimensions={canvasDimensions}
                                /> : null;
                        })()
                    )
                }
            </div>
        </div>
    );
};

export default Canvas;

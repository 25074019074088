import React, { memo, useEffect, useState } from "react";
import Layout from "../Layout";
import Auth from "../Auth";
import axios from "axios";
import { useIsAuthenticated } from "@azure/msal-react";
import Table from "src/utils/Table";
import { useDispatch } from "react-redux";
import { setActiveSidebar } from "src/features/activeSidebar/activeSidebarSlice";
import { useSelector } from "react-redux";

type course = {
  _id: string;
  authorId: string;
  title: string;
  imageUrl: string;
  createdOn: string;
  modifiedOn: string;
};

const Dashboard = memo((props) => {
  const [programs, setPrograms] = useState<Array<course>>([]);
  const isAuthenticated = useIsAuthenticated()

  const dispatch = useDispatch()

  const { accessToken } = useSelector((state: any) => state.accessToken);

  useEffect(() => {
    dispatch(setActiveSidebar("recent"))
  }, [])

  useEffect(() => {
    (async () => await getRecentPrograms())()
  },[])

  useEffect(() => 
  {
    console.log(programs)
  },
  [programs])

  async function getRecentPrograms() {
    console.log("accessToken:", accessToken)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/recent/get`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("programs:", response.data.data);
        setPrograms(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Auth>
      <Layout type="dashboard">
        {
          (callback) => 
          <div className="col-span-12 overflow-auto w-full max-w-[68.75rem] max-h-[calc(56.25rem)] rounded-[1.25rem] mt-[1.25rem] mb-[0.875rem] mr-[0.625rem] px-[0.63rem] py-[1.25rem] shadow-lg text-secondary bg-[#FFF]">
          <section className="flex flex-col space-y-[0.81rem] overflow-auto w-full h-full no-scrollbar">
            <div className="text-[1.5rem] font-[600] px-[1.37rem] leading-normal text-[#000]">Recent</div>
            <Table type='recent' data={programs} />
            {
              programs.length <= 0 &&
              <div className="flex flex-col pt-[19.8rem] px-[19.5rem] whitespace-nowrap items-center justify-center text-center">
                <div className="text-[#1rem] font-[600] leading-normal text-[#242424]">You haven't worked on any materials yet.</div>
                <div className="text-[#0.875rem] font-[600] leading-normal text-[#929292]">All materials you worked on recently will appear here.</div>
              </div>
            }
          </section>
        </div>
        }
      </Layout>
    </Auth>
  );
});

export default Dashboard;

import React, { useEffect, useState } from 'react'
import Question from '../Preview/common/Question'
import Layout from './common/Layout'
import { Option } from '../Authoring/utils/DefaultTemplateData';
import { kMaxLength } from 'buffer';

type Props = {
    type?: string;
    handleDataChange: (data: any) => void;
    data: any;
    handleSubmit?: () => void;
    disableNext?: boolean;
}

function Mcr({ type, data, handleDataChange, handleSubmit, disableNext }: Props) {
    const [responses, setResponses] = useState<Array<Option>>([])
    const [openFeedback, setOpenFeedback] = useState("")
    const [status, setStatus] = useState('')

    const templateType = 'mcr'

    function checkResponse() {
        let correct = true
        let feedback = data.globalfeedback
        responses.forEach((item, index) => {
            if (item.response !== data.options[index].correct) {
                correct = false
                feedback = data.options[index].feedback
            }
        })
        feedback = feedback || responses[0].feedback
        setStatus(correct ? 'correct' : 'incorrect')
        setOpenFeedback(feedback)

    }

    return (
        <>
            {
                !type ?
                    <Layout type={templateType} data={data} handleDataChange={(data) => { console.log("mcr data changes: ", data); handleDataChange(data) }} >
                        <Question type={templateType} data={data} />
                    </Layout>
                    :
                    <>
                        <div className='space-y-4 w-[80%]'>
                            <Question coursePreview={true} handleResponses={(responses) => { setResponses(responses); console.log(responses) }} type='mcr' data={data} />
                            {status && <div className='flex flex-col justify-start'>
                                {status === 'correct' && <div className='text-xl font-semibold text-green-500'>Correct!</div>}
                                {status === 'incorrect' && <div className='text-xl font-semibold text-red-500'>Incorrect!</div>}
                                {openFeedback && <div className='text-lg'>{openFeedback}</div>}
                            </div>}
                            <button className='bg-tertiary text-white ml-4 px-4 py-1' onClick={() => checkResponse()}>Submit</button>
                            {status && <button className={`bg-tertiary text-white ml-4 px-4 py-1 ${!disableNext && "animate-pulse"} disabled:bg-gray-200`} onClick={() => {
                                console.log('submitting mcr');
                                handleSubmit && handleSubmit();
                                setStatus('');
                                setOpenFeedback('');
                            }} disabled={disableNext} >Next</button>}
                        </div>
                        {/* {
                            status &&
                            <div className="absolute h-full w-full top-0 left-0 bg-white z-[10] flex flex-col items-center justify-center space-y-6">
                                <div className='flex flex-col justify-start'>
                                    {status === 'correct' && <div className='text-xl font-semibold text-green-500'>Correct!</div>}
                                    {status === 'incorrect' && <div className='text-xl font-semibold text-red-500'>Incorrect!</div>}
                                    {openFeedback && <div className='text-lg'>{openFeedback}</div>}
                                </div>
                                <button className='bg-tertiary text-white px-4 py-1' onClick={() => {
                                    console.log('submitting mcr');
                                    handleSubmit && handleSubmit();
                                    setStatus('');
                                    setOpenFeedback('');
                                }}>Submit</button>
                            </div>
                        } */}
                    </>
            }
        </>
    )
}

export default Mcr
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Auth from 'src/components/Auth';
import Layout from './Layout';
import { GetPreviewTemplate } from '../Templates/Preview/utils/GetPreviewTempalte';
import Tool from '../Tool';
import { get } from 'lodash';

type Props = {}

function CoursePreview({ }: Props) {
    const [data, setData] = useState<any>([]);
    const [topic, setTopic] = useState<any>(null);
    const [topicCount, setTopicCount] = useState(0);
    const [disableNext, setDisableNext] = useState(false);

    const { courseId } = useParams();

    const { accessToken } = useSelector((state: any) => state.accessToken);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );
                if (res && res.data) {
                    setData(res.data.data.course?.data || []); // Ensure data is set as an array
                    const topics = getTopics(res.data.data.course?.data || []);
                    setTopic(topics.length > 0 ? topics[0] : null);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [courseId, accessToken]);

    useEffect(() => {
        console.log('topic:', topic, disableNext);
    }, [topic, disableNext]);

    function getTopics(data: any) {
        let topics: any[] = [];
        data.forEach((item: any) => {
            if (item.type === 'file') {
                topics.push(item);
            } else if (item.children) {
                topics = [...topics, ...getTopics(item.children)];
            }
        });
        return topics
    }

    function handleNextTopic() {
        console.log((topicCount + 1), getTopics(data).length);
        if (topicCount + 1 < getTopics(data).length) {
            setTopicCount(topicCount + 1);
            setTopic(getTopics(data)[topicCount + 1]);
        }
        if (topicCount + 2 === getTopics(data).length) {
            setDisableNext(true);
        }
    }

    function handleTopicChange(topic: any) {
        const topics = getTopics(data);
        const index = topics.findIndex(t => t === topic);
        if (index !== -1) {
            setTopicCount(index);
        }
        setTopic(topic);
    }

    return (
        <Auth>
            <Layout data={data} handleTopicChange={(topic) => handleTopicChange(topic)} handleNextTopic={handleNextTopic}>
                <>
                    {!topic?.template ?

                        (<div className='flex flex-col border-4'>

                            {topic?.tools.map((tool: any) => (
                                <Tool
                                    key={tool.id}
                                    tool={tool}
                                />
                            ))}
                            <button className={`bg-tertiary text-white ml-4 px-4 py-1 ${!disableNext && "animate-pulse"} disabled:bg-gray-200`} onClick={() => {
                                handleNextTopic();
                            }} disabled={disableNext} >Next</button>
                        </div>
                        ) : (
                            (() => {
                                const TemplateComponent = GetPreviewTemplate.get(topic.template.type);
                                return TemplateComponent ? <TemplateComponent data={topic.template.data} type={"coursepreview"} handleSubmit={() => { console.log("submitting course preview"); handleNextTopic() }} disableNext={disableNext} /> : null;
                            })()
                        )
                    }
                </>
            </Layout>
        </Auth >
    )
}

export default CoursePreview
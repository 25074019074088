import React from 'react'
import { Question as QuestionType } from './utils/DefaultTemplateData'
import Question from './common/Question'

type Props = {
    handleDataChange: (data: any) => void;
    data: QuestionType
}

function TrueFalse({ data, handleDataChange }: Props) {
    return (
        <div>
            <Question type='true/false' data={data} handleDataChange={handleDataChange} />
        </div>
    )
}

export default TrueFalse
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'src/components/ui/button';

type Props = {
  children: React.ReactNode;
  handleBack: () => void;
  handleNext: () => void; 
  isSubmitType?: any;
  isNextType?: any;
  haveResponse?: any;
  haveSubmitted?: any;
  data: any;
  submitted?: boolean;
  coursePreview?: boolean;
};


const CoursePreviewPlayer = ({ children, handleBack, handleNext, isSubmitType, isNextType, haveResponse, haveSubmitted, data, submitted, coursePreview }: Props) => {

  const [timer, setTimer] = useState<number>(data?.timer);
  const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userId, courseId } = useParams();

  const playerRef = useRef<HTMLDivElement>(null);
  const [playerDimensions, setplayerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerContainerDimensions, setplayerContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      const player = playerRef.current;
      const playerContainer = playerContainerRef.current;
      if (playerContainer) {
        const playerContainerWidth = playerContainer.offsetWidth;
        const playerContainerHeight = (playerContainerWidth * 9) / 16 + 100;
        setplayerContainerDimensions({
          width: playerContainerWidth,
          height: playerContainerHeight,
        });
      }
      if (player) {
        const playerWidth = playerContainerDimensions.width - 20;
        const playerHeight = (playerWidth * 9) / 16;
        setplayerDimensions({ width: playerWidth, height: playerHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(()=> {
    console.log("coursePreview",coursePreview);
  },[coursePreview]);

  useEffect(() => {
    // Update the timer based on data.timer whenever data changes
    setTimer(data?.timer);
  }, [data?.timer]); // Listen for changes to data.timer

  useEffect(() => {
    if (coursePreview && timer > 0 && !submitted) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer, coursePreview, submitted]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimeOver(true); // Set time over state when the timer reaches zero
    }
  }, [timer]);

  // Function to format time in MM:SS format
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    console.log(haveResponse, isSubmitType, isNextType, haveSubmitted);
  },[haveResponse, isSubmitType, isNextType, haveSubmitted]);

  const submitCase = !haveResponse || (haveResponse && isSubmitType && !haveSubmitted) ;
  const nextCase = !haveResponse || (haveResponse && !isNextType && !haveSubmitted) ;
  const isNextDisabled = submitCase || nextCase;
  
  return (
    // <div className="small-container ">
    //   {coursePreview && (
    //     <>
    //     {data?.isTimer && (
    //       <div
    //         className={` absolute right-[4rem] top-0 border-2 w-52 rounded-md p-1 mt-1 ${
    //           submitted
    //             ? "border-black"
    //             : !isTimeOver
    //             ? "border-green-500"
    //             : "border-red-500"
    //         }`}
    //       >
    //         <div
    //           className={`flex justify-between font-semibold ${
    //             submitted
    //               ? "text-black"
    //               : !isTimeOver
    //               ? "text-green-500"
    //               : "text-red-500"
    //           } `}
    //         >
    //           <span>
    //             {!isTimeOver ? "Time remaining: " : "Timer has ended: "}
    //           </span>
    //           <span>{formatTime(timer)}</span>
    //         </div>
    //       </div>
    //     )}
          


    //       {/* try to comment this button before you create the bundle for scorm */}
    //       <button
    //         onClick={() => navigate(`/user/${userId}/course/${courseId}`)}
    //         className="absolute right-2 top-2 mt-1 z-10 px-3 py-1 rounded-md bg-tertiary hover:bg-tertiary/60 text-white"
    //       >
    //         Exit
    //       </button>
    //     </>
    //   )}
      
    //   <div className="w-full border-b border-gray-300 ">
    //     {children}
    //   </div>
    //   <div className="flex justify-between m-2 ">
    //     <button
    //       className="px-4 ml-6 py-2 bg-[#AB0273] text-white rounded hover:bg-[#8e006e] focus:outline-none focus:ring-[#AB0273]"
    //       onClick={handleBack}>← Previous
    //     </button>
    //     <button
    //       className={`px-4 py-2 bg-[#AB0273] text-white rounded hover:bg-[#8e006e] focus:outline-none focus:ring-[#AB0273] disabled:bg-tertiary/40 ${!isNextDisabled ? 'animate-pulse' : ''}`}
    //       onClick={handleNext}
    //       disabled={isNextDisabled}>Next →
    //     </button>
    //   </div>
    // </div>
    <main className=' w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins'>
      <div className='flex w-full h-[3rem] px-[1.25rem] py-[0.5625] items-center'>preview</div>

      <section className='relative w-full h-[calc(100%-3rem)] bg-[#d1d1d1] flex items-center justify-center'>

      {coursePreview && (
        <>
        {data?.isTimer && (
          <div
            className={` absolute right-[4rem] top-0 border-2 w-52 rounded-md p-1 mt-1 ${
              submitted
                ? "border-black"
                : !isTimeOver
                ? "border-green-500"
                : "border-red-500"
            }`}
          >
            <div
              className={`flex justify-between font-semibold ${
                submitted
                  ? "text-black"
                  : !isTimeOver
                  ? "text-green-500"
                  : "text-red-500"
              } `}
            >
              <span>
                {!isTimeOver ? "Time remaining: " : "Timer has ended: "}
              </span>
              <span>{formatTime(timer)}</span>
            </div>
          </div>
        )}
          
        {/* try to comment this button before you create the bundle for scorm */}
          <button
            onClick={() => navigate(`/user/${userId}/course/${courseId}`)}
            className="absolute right-2 top-2 mt-1 z-10 px-3 py-1 rounded-md bg-tertiary hover:bg-tertiary/60 text-white"
          >
            Exit
          </button>
        </>
      )}
        
        <div className=' containerplayer '>
          <div
            ref={playerContainerRef}
            // style={{
            //   height: `${playerContainerDimensions.height}px`,
            // }}
            className="flex flex-col items-center canvas"
          >

            <div className="w-full aspect-[16/9]">
              {children}
            </div>

            {/* Player Controls */}
            <div className="flex justify-between items-center w-full p-3 border-t-2 border-[#ccc]">
              <Button
                className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
            {/* <Player/> */}
        </div>
      </section>
    </main>
  );
};

export default CoursePreviewPlayer;

import React, { useEffect, useRef, useState } from "react";
import Layout from "./common/Layout";
import { Tab } from "../Authoring/utils/DefaultTemplateData";
import { GetPreviewTemplate } from "./utils/GetPreviewTempalte";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

type Props = {
  type?: string;
  handleDataChange: (data: any) => void;
  data: any;
  openTemplate: any;
  handleOpenAuthoringModal?: (state: boolean) => void;
  handleCancelTemplateForm?: () => void;
};

function Tabs({
  type,
  handleDataChange,
  data,
  openTemplate,
  handleOpenAuthoringModal,
  handleCancelTemplateForm,
}: Props) {

  const templateType = "tabs";

  const [selectedTab, setSelectedTab] = useState<Tab>(data?.tabs[0]);

  const contentRef = useRef<HTMLDivElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });

  const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {

  }, [selectedTab])

  useEffect(() => {
    const handleResize = () => {
      const canvas = contentRef.current;
      if (canvas) {
        const canvasWidth = canvas.offsetWidth;
        const canvasHeight = canvas.offsetHeight;
        setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!type ? (
        <Layout
          type={templateType}
          data={data}
          handleDataChange={(data) => handleDataChange(data)}
          openTemplate={openTemplate}
          handleOpenAuthoringModal={(state) => {
            console.log(state);
            handleOpenAuthoringModal && handleOpenAuthoringModal(state)
          }}
          handleCancelTemplateForm={() => {
            handleCancelTemplateForm && handleCancelTemplateForm();
          }}
        >
          <div
            style={data?.styles?.container}
            onClick={() => {
              if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'tab') {
                dispatch(setCanvasActiveComponent(null));
              } else {
                dispatch(setCanvasActiveComponent({
                  templateType,
                  componentType: 'tab'
                }));
              }
            }}
            className={`h-full w-full ${data.layoutOption === "horizontal-tab-top" ? "flex flex-col" : "flex"}  ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'tab' ? 'border-2 border-dashed border-blue' : ''}`}>
            {selectedTab !== null && (
              <>
                <div
                  style={{
                    ...data?.styles?.tabContainer,
                    width: data.layoutOption === "vertical-tab-left" ? "max-content" : "100%",
                  }}
                  className={`${data.layoutOption === "horizontal-tab-top" ? "flex flex-wrap" : "flex flex-col"}`}>
                  {data?.tabs?.map((tab: Tab, idx: number) => (
                    <button
                      key={tab._id}
                      onClick={() => setSelectedTab(tab)}
                      style={{
                        ...data?.styles?.tabButton,
                        backgroundColor: selectedTab._id !== tab._id ? data?.styles?.tabButton.backgroundColor : data?.styles?.tabButton.selected.backgroundColor,
                        color: selectedTab._id !== tab._id ? data?.styles?.tabButton.color : data?.styles?.tabButton.selected.color,
                      }}
                      className={`flex`}
                      // className={`block line-clamp-1 rounded-md text-left mr-2 mb-2 bg-gray-200 hover:bg-[#c4c4c4]`}
                      title={tab?.title}
                    >
                      <span className="whitespace-nowrap">{tab?.title}</span>
                    </button>
                  ))}
                </div>
                <div className="h-full w-full">
                  <div
                    style={{
                      ...data?.styles?.content,
                      width: data.layoutOption === "vertical-tab-left" ? "80%" : "100%",
                      marginLeft: data.layoutOption === "vertical-tab-left" ? "5px" : "0",
                    }}
                    ref={contentRef}
                    className={`${data.layoutOption === "horizontal-tab-top" ? "w-full" : "flex"}`}>
                    {(() => {
                    console.log(selectedTab);
                    const TemplateComponent = GetPreviewTemplate.get(
                      selectedTab.content.templateType
                    );
                    return TemplateComponent ? (
                      <TemplateComponent
                        data={selectedTab.content.templateData.data}
                        handleDataChange={handleDataChange}
                        // handleOpenAuthoringModal={(state: boolean) => setOpenAuthoringModal(state)}
                        // handleCancelTemplateForm={() => {
                        //     if (selectedTemplateState) {
                        //         setTemplate(null);
                        //         setSelectedTemplateState(null);
                        //     }
                        // }}
                        // openTemplate={openTemplate}
                        canvasDimensions={canvasDimensions}
                        istabtemplate={true}
                        nestedTemplate={true}
                      />
                    ) : null;
                  })()}
                  </div>
                </div>
              </>
            )}
          </div>
        </Layout>
      ) : (
        <div
          style={data?.styles?.container}
          onClick={() => {
            if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'tab') {
              dispatch(setCanvasActiveComponent(null));
            } else {
              dispatch(setCanvasActiveComponent({
                templateType,
                componentType: 'tab'
              }));
            }
          }}
          className={`h-full w-full ${data.layoutOption === "horizontal-tab-top" ? "flex flex-col" : "flex"} ${canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'tab' ? 'border-2 border-dashed border-blue' : ''}`}>
          {selectedTab !== null && (
            <>
              <div
                style={{
                  ...data?.styles?.tabContainer,
                  width: data.layoutOption === "vertical-tab-left" ? "20%" : "100%",
                }}
                className={`${data.layoutOption === "horizontal-tab-top" ? "flex flex-wrap" : "flex flex-col"}`}>
                {data?.tabs?.map((tab: Tab, idx: number) => (
                  <button
                    key={tab._id}
                    onClick={() => setSelectedTab(tab)}
                    style={{
                      ...data?.styles?.tabButton,
                      backgroundColor: selectedTab._id !== tab._id ? data?.styles?.tabButton.backgroundColor : data?.styles?.tabButton.selected.backgroundColor,
                      color: selectedTab._id !== tab._id ? data?.styles?.tabButton.color : data?.styles?.tabButton.selected.color,
                    }}
                    className={`flex`}
                    // className={`block line-clamp-1 rounded-md text-left mr-2 mb-2 bg-gray-200 hover:bg-[#c4c4c4]`}
                    title={tab?.title}
                  >
                    <span className="whitespace-nowrap">{tab?.title}</span>
                  </button>
                ))}
              </div>
              <div className="w-full h-full">
                <div
                  style={{
                    ...data?.styles?.content,
                    width: data.layoutOption === "vertical-tab-left" ? "80%" : "100%",
                    marginLeft: data.layoutOption === "vertical-tab-left" ? "5px" : "0",
                  }}
                  ref={contentRef}
                  className={`aspect-[16/9] ${data.layoutOption === "horizontal-tab-top" ? "w-full" : "flex"}`}>
                  {(() => {
                    console.log(selectedTab);
                    const TemplateComponent = GetPreviewTemplate.get(
                      selectedTab.content.templateType
                    );
                    return TemplateComponent ? (
                      <TemplateComponent
                        data={selectedTab.content.templateData.data}
                        handleDataChange={handleDataChange}
                        // handleOpenAuthoringModal={(state: boolean) => setOpenAuthoringModal(state)}
                        // handleCancelTemplateForm={() => {
                        //     if (selectedTemplateState) {
                        //         setTemplate(null);
                        //         setSelectedTemplateState(null);
                        //     }
                        // }}
                        // openTemplate={openTemplate}
                        canvasDimensions={canvasDimensions}
                        type={"coursepreview"}
                      />
                    ) : null;
                  })()}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Tabs;

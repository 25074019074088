import React, { useEffect, useState } from "react";
import RichTextEditor from "src/utils/RichTE";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";

type Tab = {
  id: number;
  content: string;
  name: string;
};

type Props = {
  tabs: Tab[];
  selectedTab: number | null;
  setSelectedTab: (tabId: number | null) => void;
  onChange: (tabs: Tab[]) => void;
};

function HorizontalTabs({ tabs = [], selectedTab, setSelectedTab, onChange }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<number | null>(null);
  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [renamedTabId, setRenamedTabId] = useState<number | null>(null);
  const [renamedTabName, setRenamedTabName] = useState("");

  useEffect(() => {
    console.log(tabs);
  }, [tabs]);

  const addTab = () => {
    const newTab: Tab = {
      id: Date.now(),
      content: "",
      name: `Tab ${tabs.length + 1}`,
    };
    onChange([...tabs, newTab]);
    setSelectedTab(newTab.id);
  };

  const selectTab = (tabId: number) => {
    setSelectedTab(tabId);
  };

  const handleEditorChange = (tabId: number, content: string) => {
    const updatedTabs = tabs.map((tab) =>
      tab.id === tabId ? { ...tab, content } : tab
    );
    onChange(updatedTabs);
  };

  const handleRemoveTab = (tabId: number) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== tabId);
    onChange(updatedTabs);
    if (selectedTab === tabId) {
      setSelectedTab(updatedTabs.length > 0 ? updatedTabs[0].id : null);
    }
  };

  const toggleMenu = (tabId: number) => {
    setIsMenuOpen(isMenuOpen === tabId ? null : tabId);
  };

  const handleRenameTab = (tabId: number, newName: string) => {
    const updatedTabs = tabs.map((tab) =>
      tab.id === tabId ? { ...tab, name: newName } : tab
    );
    onChange(updatedTabs);
  };

  const openRenameModal = (tab: Tab) => {
    setRenamedTabId(tab.id);
    setRenamedTabName(tab.name);
    setOpenRenamePopup(true);
    setIsMenuOpen(null);
  };

  const handleRename = () => {
    if (renamedTabId !== null) {
      handleRenameTab(renamedTabId, renamedTabName);
      setOpenRenamePopup(false);
    }
  };

  return (
    <div className="flex flex-col mt-5">
        <div className="flex flex-wrap">
          {tabs.map((tab, idx: number) => (
            <div key={tab.id} className="group relative flex ">
              <button
                key={tab.id}
                onClick={() => selectTab(tab.id)}
                className={`block line-clamp-1 w-32 px-3 py-2 text-left mr-2 mb-2 hover:bg-[#c4c4c4] ${
                  tab.id === selectedTab ? "bg-[#c4c4c4]" : "bg-gray-200"
                }`}
                title={tab.name}
              >
                <span className="line-clamp-1">{tab.name}</span>
              </button>
              <button
                onClick={() => toggleMenu(tab.id)}
                className={`absolute group-hover:visible right-3 top-2 ${
                  isMenuOpen !== tab.id ? "invisible" : "visible"
                }`}
              >
                <OverflowMenuHorizontal size="24" />
              </button>
              {isMenuOpen === tab.id && (
                <div className="absolute top-10 left-7 w-36 bg-white border rounded shadow-lg z-10">
                  <button
                    onClick={() => openRenameModal(tab)}
                    className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                  >
                    Rename
                  </button>
                  <button
                    onClick={() => {
                      handleRemoveTab(tab.id);
                      setIsMenuOpen(null);
                    }}
                    className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          ))}
          <button
            onClick={() => addTab()}
            className="block w-32 px-4 py-2 mb-2 text-[1rem] bg-[#AB0273] text-[#ffffff] "
          >
            Add Tab +
          </button>
      </div>
      <div className="w-full mt-2">
        {selectedTab !== null && (
          <RichTextEditor
            key={selectedTab}
            value={tabs.find((tab) => tab.id === selectedTab)?.content || ""}
            onChange={(content) => handleEditorChange(selectedTab, content)}
          />
        )}
      </div>
      <Modal
        onOpened={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        title="Rename"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow">
            <Text
              value={renamedTabName}
              onChange={(e) => setRenamedTabName(e.target.value)}
              labelTitle="Rename Title"
              autoFocus={true}
            />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={() => setOpenRenamePopup(false)}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M25.5 7.5L7.5 25.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.5 25.5L7.5 7.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={handleRename}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HorizontalTabs;

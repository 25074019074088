import React, { useState } from 'react'
import Question from '../Preview/common/Question'
import Layout from './common/Layout'
import { Option } from '../Authoring/utils/DefaultTemplateData';

type Props = {
    type?: string;
    handleDataChange: (data: any) => void;
    data: any
    handleSubmit?: () => void;
    disableNext?: boolean;
}

function Scr({ type, data, handleDataChange, handleSubmit, disableNext }: Props) {
    const [responses, setResponses] = useState<Array<Option>>([])
    const [openFeedback, setOpenFeedback] = useState("")
    const [status, setStatus] = useState('')

    const templateType = 'scr'

    function checkResponse() {
        let correct = true
        let feedback = data.globalfeedback
        responses.forEach((item, index) => {
            if (item.response !== data.options[index].correct) {
                correct = false
                feedback = data.options[index].feedback
            }
        })
        feedback = feedback || responses[0].feedback
        console.log('feedback: ', feedback, responses)
        setStatus(correct ? 'correct' : 'incorrect')
        setOpenFeedback(feedback)

    }

    return (
        <>
            {
                !type ?
                    <Layout type={templateType} data={data} handleDataChange={(data) => handleDataChange(data)} >
                        <Question type={templateType} data={data} />
                    </Layout>
                    :
                    <>
                        <div className='space-y-4 w-[80%]'>
                            <Question type='scr' data={data} coursePreview={true} handleResponses={(responses) => setResponses(responses)} />
                            {status && <div className='flex flex-col justify-start'>
                                {status === 'correct' && <div className='text-xl font-semibold text-green-500'>Correct!</div>}
                                {status === 'incorrect' && <div className='text-xl font-semibold text-red-500'>Incorrect!</div>}
                                {openFeedback && <div className='text-lg'>{openFeedback}</div>}
                            </div>}
                            <button className='bg-tertiary text-white ml-4 px-4 py-1' onClick={() => checkResponse()}>Submit</button>
                            {status && <button className={`bg-tertiary text-white ml-4 px-4 py-1 ${!disableNext && "animate-pulse"} disabled:bg-gray-200`} onClick={() => {
                                console.log('submitting mcr');
                                handleSubmit && handleSubmit();
                                setStatus('');
                                setOpenFeedback('');
                            }} disabled={disableNext} >Next</button>}
                        </div>
                    </>
            }
        </>
    )
}

export default Scr
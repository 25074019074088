import React, { memo, useState } from "react";
import NavProfile from "../NavProfile";
import Logo from "../../assets/logowhite.png";
import NotificationIcon from "../../assets/Bell.svg";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import MicrosoftLogo from "../../assets/microsoft.svg";
import ChatComponent  from '../ChatGPT/ChatComponent';
type NavProps = {
  type: string;
};

const Nav = memo((props: NavProps) => {
  const { instance } = useMsal();
  const [activeList, setActiveList] = useState("");

  const handleSignIn = () => {
    instance.loginRedirect({
      scopes: ["User.Read"],
    });
  };

  return (
    <div className="relative flex w-full max-w-[90rem] h-[5.625rem] justify-between px-[1.25rem] py-[0.5625rem] items-center bg-[#242424] text-[#D9D9D9] ">
      <AuthenticatedTemplate>
        <a
          href="/"
          className="flex items-center justify-center space-x-[1.0625rem]"
        >
          <span>
            <img src={Logo} alt="logo" />
          </span>
          <span className="w-[0.125rem] h-[1.875rem] bg-[#FFFFFF]"></span>
          <div className="font-normal flex items-center space-x-1">
            <span className="flex items-start text-[#AB0273]">
              <span className="text-[3rem] font-[500]  leading-normal">ALTO</span>
              <span className="text-[2rem] font-[400]  leading-normal">©</span>
            </span>
            <span className="text-[#D9D9D9] text-[1.5rem] font-[400]  leading-normal">Suite</span>
          </div>
        </a>
        {props.type !== "homepage" ? (
          <ul className="flex mx-auto space-x-16">
            {/* <form className="group relative">
              <svg
                width="20"
                height="20"
                fill="currentColor"
                className="absolute left-3 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                />
              </svg>
              <input
                className="focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 pl-10 ring-1 ring-slate-200 shadow-sm"
                type="text"
                aria-label="Search"
                placeholder="Search..."
              />
            </form> */}
            {/* <a href="/courses">
              <li
                onClick={() => setActiveList("Courses")}
                className={`cursor-pointer py-4 ${
                  activeList === "Courses" &&
                  "border-b border-b-tertiary text-tertiary"
                }`}
              >
                Courses
              </li>
            </a>
            <a href="/resources">
              <li
                onClick={() => setActiveList("Resources")}
                className={`cursor-pointer py-4 ${
                  activeList === "Resources" &&
                  "border-b border-b-tertiary text-tertiary"
                }`}
              >
                3D Modal
              </li>
            </a>
            <a href="/library">
              <li
                onClick={() => setActiveList("My library")}
                className={`cursor-pointer py-4 ${
                  activeList === "My library" &&
                  "border-b border-b-tertiary text-tertiary"
                }`}
              >
                Assets library
              </li>
            </a>
            <a href="/organization">
              <li
                onClick={() => setActiveList("My organization")}
                className={`cursor-pointer py-4 ${
                  activeList === "My organization" &&
                  "border-b border-b-tertiary text-tertiary"
                }`}
              >
                Administration
              </li>
            </a>
            <a href="/learners">
              <li
                onClick={() => setActiveList("Learners")}
                className={`cursor-pointer py-4 ${
                  activeList === "Learners" &&
                  "border-b border-b-tertiary text-tertiary"
                }`}
              >
                Tech Community
              </li>
            </a> */}
          </ul>
        ) : (
          <ul className="grow pl-44">
            <a href="/dashboard">
              <li
                onClick={() => setActiveList("Dashboard")}
                className={`cursor-pointer py-4 ${activeList === "Dashboard" &&
                  "border-b border-b-tertiary text-tertiary"
                  }`}
              >
                Dashboard
              </li>
            </a>
          </ul>
        )}
        
        <div className="flex items-center space-x-[1.75rem]">
          <img src={NotificationIcon} alt="notification-icon" />
          <ChatComponent />
          <NavProfile />
        </div>
      </AuthenticatedTemplate>
      {/* <UnauthenticatedTemplate>
        <button
          className="flex items-center px-2 py-1 space-x-2 bg-white text-black font-semibold rounded-sm"
          onClick={() => handleSignIn()}
        >
          <span className="h-8 w-8">
            <img src={MicrosoftLogo} alt="logo" />
          </span>
          <span>Sign in</span>
        </button>
      </UnauthenticatedTemplate> */}
      <UnauthenticatedTemplate>
        <div className="w-full flex justify-center items-center">
          <a
            href="/"
            className="flex items-center justify-center space-x-[1.0625rem]"
          >
            <span>
              <img src={Logo} alt="logo" />
            </span>
            <span className="w-[0.125rem] h-[1.875rem] bg-[#FFFFFF]"></span>
            <div className="font-normal flex items-center space-x-1">
              <span className="flex items-start text-[#AB0273]">
                <span className="text-[3rem] font-[500]  leading-normal">ALTO</span>
                <span className="text-[2rem] font-[400]  leading-normal">©</span>
              </span>
              <span className="text-[#D9D9D9] text-[1.5rem] font-[400]  leading-normal">Suite</span>
            </div>
          </a>
        </div>
      </UnauthenticatedTemplate>
      
    </div>
  );
});

export default Nav;

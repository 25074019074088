import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import defaultPlayerImage from "src/assets/Course/defaultPlayerImage.png";

type Props = {};

function Player({}: Props) {
  const playerRef = useRef<HTMLDivElement>(null);
  const [playerDimensions, setplayerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerContainerDimensions, setplayerContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleNext = () => {
    // Handle Next button logic
    console.log("Next button clicked");
  };

  const handlePrevious = () => {
    // Handle Previous button logic
    console.log("Previous button clicked");
  };

  useEffect(() => {
    const handleResize = () => {
      const player = playerRef.current;
      const playerContainer = playerContainerRef.current;
      if (playerContainer) {
        const playerContainerWidth = playerContainer.offsetWidth;
        const playerContainerHeight = (playerContainerWidth * 9) / 16 + 100;
        setplayerContainerDimensions({
          width: playerContainerWidth,
          height: playerContainerHeight,
        });
      }
      if (player) {
        const playerWidth = playerContainerDimensions.width - 20;
        const playerHeight = (playerWidth * 9) / 16;
        setplayerDimensions({ width: playerWidth, height: playerHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={playerContainerRef}
      style={{
        width: `${playerContainerDimensions.width}px`,
        height: `${playerContainerDimensions.height}px`,
      }}
      className="flex flex-col items-center canvas !w-full"
    >
      {/* Outer Resizable Player Container */}
      <div
        className="canvas-container"
      >
        {/* Inner Resizable Player */}
        <div
          ref={playerRef}
          style={{
            height: `${playerDimensions.height}px`,
            width: `${playerDimensions.width}px`,
          }}
          className="player"
        >
          <div className="!w-full !h-full">
            <img className="w-full h-full" src={defaultPlayerImage} alt="" />
          </div>
        </div>
      </div>

      {/* Player Controls */}
      <div className="flex justify-between items-center w-full p-3 border-t-2 border-[#ccc]">
        <button
          className="bg-blue text-white px-4 py-2 rounded"
          onClick={handlePrevious}
        >
          Previous
        </button>
        <button
          className="bg-blue text-white px-4 py-2 rounded"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Player;

import React, { useState, useEffect } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  input: string;
  openCreateModal?: any;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleAdd: () => void;
};

function AddSection({
  state,
  input,
  openCreateModal,
  onChangeInput,
  onHandleAdd,
}: Props) {
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    console.log("this is state: ", state);

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [input, state]);

  return (
    <div className="flex flex-col h-full  px-[0.45rem]">
      <div className="grow space-y-[1.5rem] py-[1.25rem] overflow-y-scroll no-scrollbar ">
        <div>
          <InputText
            value={input}
            onChange={(e) => onChangeInput(e)}
            placeholder="Enter Name"
            labelTitle="Name"
          />
        </div>

        <div className="flex flex-col space-y-3 px-[0.49rem]">
          <div className="text-[16px] font-[600]">Section Image</div>
          <div className="flex space-x-1 items-center">
            <UploadButton
              type="image"
              pageState={{
                ...state,
                input: input,
                openCreateModal: openCreateModal,
              }}
              handleUploadFromDevice={(file) => {
                setFile(file);
                console.log(file);
              }}
            />
            <UploadDropdowns
              type="image"
              pageState={{
                ...state,
                input: input,
                openCreateModal: openCreateModal,
              }}
              handleCropFile={(cropFile) => setFile(cropFile)}
              openCreateModal={openCreateModal}
              File={file}
            />
          </div>
        </div>
      </div>
      <div className=" border-t px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => onHandleAdd()}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddSection;

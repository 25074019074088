import React, { useEffect, useState } from "react";
import Question from "../Preview/common/Question";
import Layout from "./common/Layout";
import { Option } from "../Authoring/utils/DefaultTemplateData";
import { Button } from "src/components/ui/button1";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";

type Props = {
  type?: string;
  handleDataChange: (data: any) => void;
  data: any;
  handleSubmit?: () => void;
  disableNext?: boolean;
  handleOpenAuthoringModal?: (state: boolean) => void;
  handleCancelTemplateForm?: () => void;
  openTemplate?: any;
  handleNextDisable?: (res: any, submitted: any) => void;
  canvasDimensions: {
    width: number;
    height: number;
  };
  nestedTemplate?: boolean;
};

function Mcr({
  type,
  data,
  handleDataChange,
  handleSubmit,
  disableNext,
  handleOpenAuthoringModal,
  handleCancelTemplateForm,
  openTemplate,
  handleNextDisable,
  canvasDimensions,
  nestedTemplate,
}: Props) {
  const [responses, setResponses] = useState<Array<Option>>([]);
  const [openFeedback, setOpenFeedback] = useState("");
  const [status, setStatus] = useState("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true);

  const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);

  const dispatch = useDispatch();

  const templateType = "mcr";

  useEffect(() => {
    // Add your effect logic here if necessary
  }, [data, openFeedback]);

  useEffect(() => {
    if (responses && handleNextDisable) {
      const res = responses.find(
        (detail: any) => detail.response === true
      )?.response;
      handleNextDisable(res, submitted);
    }
  }, [responses, submitted]);

  function checkResponse() {
    let correct = true;
    let feedback = data.globalFeedback;

    responses.forEach((item, index) => {
      if (item.response !== data.options[index].correct) {
        correct = false;
        // feedback = data.options[index].feedback; // Uncomment if needed
      }
    });

    setStatus(correct ? "correct" : "incorrect");
    setSubmitted(true);

    setTimeout(() => {
      setOpenFeedback(feedback);
    }, 2000);
  }

  return (
    <>
      {!type ? (
        <>
          {
            !nestedTemplate ? (
              <Layout
                type={templateType}
                data={data}
                handleDataChange={(data) => handleDataChange(data)}
                handleOpenAuthoringModal={(state) => {
                  handleOpenAuthoringModal && handleOpenAuthoringModal(state);
                }}
                handleCancelTemplateForm={() => {
                  handleCancelTemplateForm && handleCancelTemplateForm();
                }}
                openTemplate={openTemplate}
              >
                <div
                  style={data?.styles?.container}
                  onClick={() => {
                    if (canvasActiveComponent?.templateType === templateType && canvasActiveComponent?.componentType === 'mcr') {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(setCanvasActiveComponent({
                        templateType,
                        componentType: 'mcr'
                      }));
                    }
                  }}
                  className="flex flex-col w-full">
                  <Question
                    type={templateType}
                    data={data}
                    canvasDimensions={canvasDimensions}
                  />
                  <button
                    disabled
                    style={{
                      fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                      paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                      paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
                      paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                      paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
                      borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                      marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
                      bottom: `${20 * (canvasDimensions.width / 960)}px`,
                    }}
                    className={`absolute bottom-5 rounded-md disabled:bg-blue/40 text-white ml-4 px-4 py-1 max-w-max cursor-not-allowed`}
                  >
                    Submit
                  </button>
                </div>
              </Layout>
            ) : (
              <>
                <Question
                  type={templateType}
                  data={data}
                  canvasDimensions={canvasDimensions}
                />
                <Button
                  disabled
                  style={{
                    fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                    paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                    paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
                    paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                    paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
                    borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                    marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
                    bottom: `${20 * (canvasDimensions.width / 960)}px`,
                  }}
                  className={`absolute bottom-5 rounded-md disabled:bg-blue/40 text-white ml-4 px-4 py-1 max-w-max cursor-not-allowed`}
                >
                  Submit
                </Button>
              </>
            )
          }
        </>
      ) : (
        <>
          <Question
            coursePreview={true}
            handleResponses={(responses) => setResponses(responses)}
            type="mcr"
            data={data}
            submitted={submitted}
            status={status}
            openFeedback={openFeedback}
            handleOpenFeedback={(openFeedBack: any) =>
              setOpenFeedback(openFeedBack)
            }
            canvasDimensions={canvasDimensions}
          />
          <button
            style={{
              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
              paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
              paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
              borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
              marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
              bottom: `${6 * (canvasDimensions.width / 960)}px`,
            }}
            className={`absolute bottom-1.5 left-0 rounded-md bg-blue text-white ml-4 px-4 py-1 max-w-max ${submitted && "bg-blue/40 cursor-not-allowed"
              }`}
            onClick={() => checkResponse()}
          >
            Submit
          </button>
        </>
      )}
    </>
  );
}

export default Mcr;

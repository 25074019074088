import React from 'react'
import Question from './common/Question'
import { Question as QuestionType } from './utils/DefaultTemplateData'

type Props = {
    handleDataChange: (data: any) => void;
    data: QuestionType
}

function Mcr({ data, handleDataChange }: Props) {
    return (
        <div>
            <Question type='mcr' data={data} handleDataChange={(data) => {
                handleDataChange(data);
                console.log(data);
            }} />
        </div>
    )
}

export default Mcr
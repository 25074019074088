import React from "react";
import {v4 as uuid} from "uuid";

export type Option = {
    _id: string;
    value: string;
    correct: boolean;
    response: boolean;
    feedback: string | null;
};

export type Question = {
    question: string;
    prompt: string;
    options: Option[];
    globalFeedback: string | null;
};

const defaultMcrData: Question = {
    question: "Choose the airbuses that are available in CATHAY PACIFIC",
    prompt: "Select multiple options",
    options: [
        { _id: uuid(), value: "A320", correct: true, response: false, feedback: "All airbuses are available in CATHAY PACIFIC." },
        { _id: uuid(), value: "A330", correct: true, response: false, feedback: "All airbuses are available in CATHAY PACIFIC." },
        { _id: uuid(), value: "A340", correct: true, response: false, feedback: "All airbuses are available in CATHAY PACIFIC." },
        { _id: uuid(), value: "A350", correct: true, response: false, feedback: "All airbuses are available in CATHAY PACIFIC." },
    ],
    globalFeedback: null,
};

const defaultScrData: Question = {
    question: "Find the odd one out",
    prompt: "Select an option",
    options: [
        { _id: uuid(), value: "First Aid", correct: false, response: false, feedback: "All options are related to Aircraft Emergency Equipments, but Business Class option is the passenger's ticket type." },
        { _id: uuid(), value: "Business Class", correct: true, response: false, feedback: "All options are related to Aircraft Emergency Equipments, but Business Class option is the passenger's ticket type." },
        { _id: uuid(), value: "Fire Extinguiser", correct: false, response: false, feedback: "All options are related to Aircraft Emergency Equipments, but Business Class option is the passenger's ticket type." },
        { _id: uuid(), value: "Oxygen Cylinder", correct: false, response: false, feedback: "All options are related to Aircraft Emergency Equipments, but Business Class option is the passenger's ticket type." },
    ],
    globalFeedback: null,
};

const defaultTrueFalseData: Question = {
    question: "Passengers are allowed to carry more than one piece of hand luggage onboard if the total weight is under 7 kg.",
    prompt: "Select True or False",
    options: [
        { _id: uuid(), value: "True", correct: false, response: false, feedback: "Passengers are typically allowed only one piece of hand luggage onboard, and specific airline policies often set limits on both the size and weight of carry-on items." },
        { _id: uuid(), value: "False", correct: true, response: false, feedback: "Passengers are typically allowed only one piece of hand luggage onboard, and specific airline policies often set limits on both the size and weight of carry-on items." },
    ],
    globalFeedback: null,
};

export const DefaultTemplateData = new Map([
    ["mcr", defaultMcrData],
    ["scr", defaultScrData],
    ["true/false", defaultTrueFalseData],
])
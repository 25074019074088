import React, { memo, useEffect, useState, useRef } from "react";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import Templates from "../Templates";
import SidebarWidgets from "./SidebarWigets";
import {
  ChevronLeft,
  ChevronRight,
  Menu,
  UserFollow,
  ChevronDown,
} from "@carbon/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { setLeftPanelCollapsed } from "../../features/Sidebar/sidebarSlice";
import Modal from "src/utils/Modal";
import axios from "axios";
import { useUser } from "src/hooks/useUser";
import DashboardIcon from "../../assets/dashboard.svg";
import CourseIcon from "../../assets/course.svg";
import RecentIcon from "../../assets/SignOut.svg";
import FavoriteIcon from "../../assets/SideBar/Star.svg";
import SharedwithmeIcon from "../../assets/SideBar/Users.svg";
import AddClientIcon from "../../assets/addclient.svg";
import EditLogo from "../../assets/TableMenu/NotePencil.svg";
import CollaborateLogo from "../../assets/TableMenu/UserPlus.svg";
import RenameLogo from "../../assets/TableMenu/PencilLine.svg";
import DeleteLogo from "../../assets/TableMenu/TrashSimple.svg";
import DetailsLogo from "../../assets/TableMenu/Note.svg";
import CatalogueIcon from "../../assets/SideBar/Book.svg";
import Text from "src/utils/Text/Txt";
import { useAccount, useMsal } from "@azure/msal-react";
import { setAccessToken } from "src/features/token/tokenSlice";
import {
  setAddBusinessUnit,
  setBusinessUnits,
  setDelBusinessUnits,
  setEditBusinessUnit,
} from "src/features/businessUnits/businessUnitSlice";
import { setActiveSidebar } from "src/features/activeSidebar/activeSidebarSlice";
import { setAuthUser, setIsAuthenticated } from "src/features/user/userSlice";
import Avatar from "react-avatar";
import { useNavigate, useParams } from "react-router-dom";
import Drawer from "src/utils/Drawer";
import TextArea from "src/utils/TextInput/TextArea";
import InputText from "src/utils/TextInput/InputText";
import { setLog } from "src/features/errorLog/errorLogSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

type sidebarProps = {
  type: String;
};

type BusinessUnitType = {
  _id: string;
  authorId: string;
  title: string;
  description: string;
  version: string;
  modifiedOn: number;
  createdOn: number;
};

const Sidebar = memo((props: sidebarProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalUnit, setOpenModalUnit] = useState(false);
  const [openDetailModalUnit, setOpenDetailModalUnit] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );
  const [unit, setUnit] = useState<any>();
  const [image, setImage] = useState<File>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [version, setVersion] = useState("");
  const [units, setUnits] = useState<Array<BusinessUnitType> | null>(null);
  const [openOptions, setOpenOptions] = useState("");
  const [tableMenuActive, setTableMenuActive] = useState<string>("");
  const [projectName, setProjectName] = useState("");
  const [descriptionName, setDescriptionName] = useState("");
  const [versionName, setVersionName] = useState("");
  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [renamedTitle, setRenamedTitle] = useState("");
  const [renamedId, setRenamedId] = useState("");
  const isLeftPanelCollapsed = useSelector(
    (state: any) => state.sidebar.leftPanelCollapsed
  );

  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { businessUnits }: { businessUnits: BusinessUnitType[] } = useSelector(
    (state: any) => state.businessUnits
  );

  const { businessUnitId } = useParams();

  const { activeSidebar } = useSelector((state: any) => state.activeSidebar);

  const user: any = useSelector((state: any) => state.user.authUser);

  const handleNewCourse = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    Init();
  }, []);

  useEffect(() => {
    getBusinessUnits();
  }, []);

  async function getBusinessUnits() {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {
      const res = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });

      accessToken = res.accessToken;
      if (accessToken) dispatch(setAccessToken(accessToken));

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/getbusinessunit`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("User:", response.data.data);
        // const myUnits = response.data.data as BusinessUnitType[]
        setUnits(response.data.data as BusinessUnitType[]);
        dispatch(setBusinessUnits(response.data.data as BusinessUnitType[]));
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("An error occurred during token acquisition", error);
      }
    }
  }

  async function Init() {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {
      const res = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });

      accessToken = res.accessToken;
      if (accessToken) dispatch(setAccessToken(accessToken));

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/user/me`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("User:", response.data.data);
        dispatch(setIsAuthenticated(true));
        dispatch(setAuthUser(response.data.data));
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("An error occurred during token acquisition", error);
      }
    }
  }

  function handleAddClient() {
    setOpenModal(true);
  }

  function handleEditUnit(unit: any) {
    setOpenModalUnit(true);
    setUnit(unit);
    setProjectName(unit?.title);
    setDescriptionName(unit?.description);
    setVersionName(unit?.version);
  }

  function handleDetailsOfUnit(unit: any) {
    setOpenDetailModalUnit(true);
    setUnit(unit);
    // setProjectName(unit?.title);
    // setDescriptionName(unit?.description);
    // setVersionName(unit?.version);
  }

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
  }

  // delete business unit

  async function handleDelete(id: any) {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {
      const res = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });

      accessToken = res.accessToken;
      if (accessToken) dispatch(setAccessToken(accessToken));

      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("Catalogue deleted successfully");
        dispatch(setLog({ mode: "success", message: response.data.message }));
        dispatch(setDelBusinessUnits(id));
        setOpenDeletePopup(false);
        setOpenModalUnit(false);
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("Error deleting business unit", error);
        dispatch(setLog({ mode: "failure", message: error }));
      }
    }
  }

  async function AddUnit() {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    try {

      const response = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });
      accessToken = response.accessToken;
      const newTitle = businessUnits.filter((item: any) => {
        const value = item.title.toLowerCase();
        return value === title.toLowerCase();
      });
      console.log(newTitle, parseInt(version));
      if (newTitle.length > 0) {
        dispatch(
          setLog({
            mode: "warn",
            message: "Catalogue of same name already exist!",
          })
        );
        return;
      }
      if (!parseInt(version)) {
        dispatch(
          setLog({ mode: "warn", message: "Version should be in numbers!" })
        );
        return;
      }
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/createbusinessunit`,
          {
            title: title + `${newTitle.length > 0 ? `(${newTitle.length})` : ""}`,
            description,
            version,
            modifiedOn: new Date().getTime(),
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => res.data)
        .then((response) => {
          console.log(response.data, response);
          dispatch(setAddBusinessUnit(response.data as BusinessUnitType));
          navigate(`/user/${response.data.authorId}/businessunit/${response.data._id}`, { relative: "path" });
          dispatch(setLog({ mode: "success", message: response.message }));
          setTitle("");
          setDescription("");
          setVersion("");
          setOpenModal(false);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setLog({ mode: "failure", message: err.response.data.message })
          );
        });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("An error occurred during token acquisition", error);
      }
    }
  }

  async function EditUnit(id: string) {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }
    try {
      const response = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });
      accessToken = response.accessToken;
      const newTitle = businessUnits.filter((item: any) => {
        if (item._id !== id) {
          const value = item.title.toLowerCase();
          return value === projectName.toLowerCase();
        }
        return false;
      });
      console.log(newTitle);
      if (newTitle.length > 0) {
        dispatch(
          setLog({
            mode: "warn",
            message: "Catalogue of same name already exist!",
          })
        );
        return;
      }
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/editbusinessunit/${id}`,
          {
            title:
              projectName +
              `${newTitle.length > 0 ? `(${newTitle.length})` : ""}`,
            description: descriptionName,
            version: versionName,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => res.data)
        .then((response) => {
          console.log("edited catalogue: ", response.data);
          dispatch(setEditBusinessUnit(response.data));
          dispatch(setLog({ mode: "success", message: response.message }));
          setOpenModalUnit(false);
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setLog({ mode: "failure", message: err.response.data.message })
          );
        });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("An error occurred during token acquisition", error);
      }
    }
  }

  function handleRenamePopup(unit: any) {
    setOpenRenamePopup(true);
    setRenamedTitle(unit.title);
    setRenamedId(unit._id);
    setVersionName(unit.version);
    setDescriptionName(unit.description);
  }

  async function handleRename(unitId: any, title: any) {
    let accessToken = null;
    // const account = instance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }
    try {
      const response = await instance.acquireTokenSilent({
        scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
        account: account,
      });
      accessToken = response.accessToken;
      const newTitle = businessUnits.filter((item: any) => {
        const value = item.title.toLowerCase();
        return value === title.toLowerCase();
      });
      console.log(newTitle);
      if (newTitle.length > 0) {
        dispatch(
          setLog({
            mode: "warn",
            message: "Catalogue of same name already exist!",
          })
        );
        return;
      }
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/editbusinessunit/${unitId}`,
          {
            title: title + `${newTitle.length > 0 ? `(${newTitle.length})` : ""}`,
            description: descriptionName,
            version: versionName,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => res.data)
        .then((response) => {
          console.log("renamed catalogue: ", response.data);
          dispatch(setEditBusinessUnit(response.data));
          dispatch(setLog({ mode: "success", message: response.message }));
          window.location.reload();
          setOpenRenamePopup(false);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setLog({ mode: "failure", message: err.response.data.message })
          );
        });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle the interaction required error
        console.log("Interaction required, prompting for sign-in");
        instance.acquireTokenRedirect({
          scopes: ["f4789d8e-6631-4410-84e7-94a0870d0be3/.default"],
          account: account,
        })
      } else {
        console.error("An error occurred during token acquisition", error);
      }
    }
  }

  // Outside click

  const tableRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!tableRef?.current?.contains(event.target as Node)) {
        setOpenOptions("");
        setTableMenuActive("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tableRef]);

  return (
    <>
      <div className={`relative p-[1.25rem] min-w-[20.625rem] max-h-[calc(56.25rem)] `}>
        <div className="flex flex-col text-[#242424]  space-y-4">
          <div className="text-[1.5rem] font-[600] leading-normal">
            PROGRAM DASHBOARD
          </div>
          <div className="flex flex-col space-y-[0.87rem]">
            <button
              onClick={() => dispatch(setActiveSidebar("recent"))}
              className={` ${activeSidebar === "recent" && "bg-tertiary text-[#FFFFFF]"
                } px-[0.94rem] py-[0.44rem] text-[1.25rem] font-[500] leading-normal rounded-[1.25rem] hover:bg-tertiary hover:text-[#FFFFFF]`}
            >
              <a
                className="flex items-center space-x-[0.625rem]"
                href={`/dashboard`}
              >
                <span>
                  <img src={RecentIcon} alt="recent-icon" />
                </span>
                <span>Recent</span>
              </a>
            </button>
            <button
              onClick={() => dispatch(setActiveSidebar("favorite"))}
              className={`${activeSidebar === "favorite" && "bg-tertiary text-[#FFFFFF]"
                } px-[0.94rem] py-[0.44rem] text-[1.25rem] font-[500] leading-normal rounded-[1.25rem] hover:bg-tertiary hover:text-[#FFFFFF]`}
            >
              <a
                className="flex items-center space-x-[0.625rem]"
                href={`/user/${user?.oid}/favorite`}
              >
                <span>
                  <img src={FavoriteIcon} alt="starred-icon" />
                </span>
                <span>Favorite</span>
              </a>
            </button>
            <button
              onClick={() => dispatch(setActiveSidebar("shared"))}
              className={`${activeSidebar === "shared" && "bg-tertiary text-[#FFFFFF]"
                } px-[0.94rem] py-[0.44rem] text-[1.25rem] font-[500] leading-normal rounded-[1.25rem] hover:bg-tertiary hover:text-[#FFFFFF]`}
            >
              <a
                className="flex items-center space-x-[0.625rem]"
                href={`/user/${user?.oid}/shared`}
              >
                <span>
                  <img src={SharedwithmeIcon} alt="shared-icon" />
                </span>
                <span>Shared with me</span>
              </a>
            </button>
          </div>
          <div className="w-full h-[0.0625rem] bg-[#667085]"></div>
          <div className="flex flex-col space-y-[0.87rem]">
            <div className="flex items-center justify-between pl-[1.25rem] py-[0.5rem]">
              <span className="text-[1.5rem] font-[600] leading-normal capitalize">
                Catalogues
              </span>
              <button onClick={() => handleAddClient()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                    stroke="#242424"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 16H21"
                    stroke="#242424"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 11V21"
                    stroke="#242424"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            {businessUnits ? (
              <>
                {businessUnits.map((unit) => (
                  <>
                    <button
                      onClick={() => dispatch(setActiveSidebar(unit._id))}
                      className={`group flex items-center justify-between ${activeSidebar === unit._id &&
                        "bg-tertiary text-[#FFFFFF]"
                        } px-[0.7rem] py-[0.44rem] text-[1.25rem] font-[500] leading-normal rounded-[1.25rem] hover:bg-tertiary hover:text-[rgb(255,255,255)] relative`}
                    >
                      <a className="w-full" href={`/user/${unit.authorId}/businessunit/${unit._id}`}>
                        <div className="flex w-full items-center space-x-[0.75rem]">
                          <img src={CatalogueIcon} alt="logo" />
                          <div className="text-left line-clamp-1">
                            {unit.title}
                          </div>
                        </div>
                      </a>
                      {/* <button
                        // onClick={(e) => {handleEditUnit(unit); }} // NESTED ONCLICK ALSO TRIGGERS PARENT
                        onClick={() => {
                          if (!openOptions) {
                            setOpenOptions(unit._id);
                            setTableMenuActive(unit._id);
                          } else setOpenOptions("");
                        }}
                        className="invisible group-hover:visible group-hover:z-10"
                      >
                        <OverflowMenuHorizontal size="24" />
                      </button> */}
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            size={"sm"}
                            onClick={() => {
                              if (!openOptions) {
                                setOpenOptions(unit._id);
                                setTableMenuActive(unit._id);
                              } else setOpenOptions("");
                            }}
                            className={`${tableMenuActive !== unit._id
                              ? "invisible"
                              : "visible"
                              } group-hover:visible`}
                            variant="destructive"
                          >
                            <OverflowMenuHorizontal size="24" />
                          </Button>
                        </DropdownMenuTrigger>
                        {openOptions === unit._id && (
                          <DropdownMenuContent
                            ref={tableRef}
                            className="relative right-[5.2rem] flex flex-col w-[13.4375rem] h-[16rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] px-[0.7rem] pt-[0.65rem] space-y-[1.94rem] "
                          >
                            <DropdownMenuGroup>
                              <DropdownMenuItem className="flex items-center">
                                <button
                                  className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]"
                                  onClick={(e) => {
                                    handleEditUnit(unit);
                                  }}
                                >
                                  <span>
                                    <img src={EditLogo} alt="logo" />
                                  </span>
                                  <span>Edit</span>
                                </button>
                                {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
                              </DropdownMenuItem>
                              <DropdownMenuItem className="flex items-center">
                                <button className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">
                                  <span>
                                    <img src={CollaborateLogo} alt="logo" />
                                  </span>
                                  <span>Collaborate</span>
                                </button>
                              </DropdownMenuItem>
                              <DropdownMenuItem className="flex items-center">
                                <button
                                  className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]"
                                  onClick={() => handleRenamePopup(unit)}
                                >
                                  <span>
                                    <img src={RenameLogo} alt="logo" />
                                  </span>
                                  <span>Rename</span>
                                </button>
                              </DropdownMenuItem>
                              <DropdownMenuItem className="flex items-center">
                                <button
                                  className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]"
                                  onClick={(e) => {
                                    handleDetailsOfUnit(unit);
                                  }}
                                >
                                  <span>
                                    <img src={DetailsLogo} alt="logo" />
                                  </span>
                                  <span>Details</span>
                                </button>
                              </DropdownMenuItem>
                              <DropdownMenuSeparator className="bg-[#2C2C2C]" />
                              <DropdownMenuItem className="flex items-center">
                                <button
                                  className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#C2262E] leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]"
                                  onClick={() => handleDeletePopup(unit._id)}
                                >
                                  <span>
                                    <img src={DeleteLogo} alt="logo" />
                                  </span>
                                  <span>Delete</span>
                                </button>
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        )}
                      </DropdownMenu>
                      {/* {openOptions === unit._id && (
                        <div
                          ref={tableRef}
                          className="overflow-hidden z-10 absolute top-[3rem] right-0 bg-[#F5F5F5] border-2 border-black/30 p-4 rounded-[1.25rem] min-w-[13.4375rem] flex-shrink-0"
                        >
                          <ul className="w-full flex flex-col items-start justify-center space-y-[0.88rem]">
                            <li className=" text-[1rem] font-[500] text-[#242424] hover:text-black w-full p-1 hover:bg-[#E0CBD9] hover:rounded-[1.25rem]">
                              <button
                                className="flex items-center space-x-[1.94rem]  w-full 
 leading-normal"
                                onClick={(e) => {
                                  handleEditUnit(unit);
                                }}
                              >
                                <span>
                                  <img src={EditLogo} alt="logo" />
                                </span>
                                <span>Edit</span>
                              </button>
                            </li>
                            <li className="flex items-center space-x-[1.94rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded-[1.25rem]">
                              <button
                                className="flex items-center space-x-[1.94rem]  w-full 
 leading-normal"
                              >
                                <span>
                                  <img src={CollaborateLogo} alt="logo" />
                                </span>
                                <span>Collaborate</span>
                              </button>
                            </li>
                            <li className="flex items-center space-x-[1.94rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded-[1.25rem]">
                              <button
                                className="flex items-center space-x-[1.94rem]  w-full 
 leading-normal"
                                onClick={() => handleRenamePopup(unit)}
                              >
                                <span>
                                  <img src={RenameLogo} alt="logo" />
                                </span>
                                <span>Rename</span>
                              </button>
                            </li>
                            <li className="flex items-center space-x-[1.94rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded-[1.25rem]">
                              <button
                                className="flex items-center space-x-[1.94rem]  w-full 
 leading-normal"
                              >
                                <span>
                                  <img src={DetailsLogo} alt="logo" />
                                </span>
                                <span>Details</span>
                              </button>
                            </li>
                            <li className="w-full">
                              <div className="border-b border-[#2C2C2C] pt-[0.32rem]"></div>
                            </li>
                            <li className="flex items-center space-x-[1.94rem] text-[1rem] font-[500] text-[#C2262E] hover:text-red-700 leading-normal w-full rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded-[1.25rem]">
                              <button
                                className="flex items-center space-x-[1.94rem]  w-full
 leading-normal"
                                onClick={() => handleDeletePopup(unit._id)}
                              >
                                <span>
                                  <img src={DeleteLogo} alt="logo" />
                                </span>
                                <span>Delete</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      )} */}
                    </button>
                  </>
                ))}
              </>
            ) : (
              <div className="text-sm">Click "+" to create a new unit</div>
            )}
          </div>
        </div>
      </div>

      <Drawer
        onOpened={openModal}
        onClose={() => setOpenModal(false)}
        title="Manage Catalogue"
        height="calc(100%)"
      // height="calc(58.375rem-2.125rem)"
      >
        <div className="flex flex-col h-full">
          <div className="space-y-[1.25rem] py-[1.06rem] overflow-y-scroll">
            <div>
              <InputText
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                labelTitle=""
                placeholder="Catalogue Name"
              />
            </div>
            <div>
              <TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                labelTitle=""
                placeholder="Catalogue Description"
              />
            </div>
            <div>
              <InputText
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                labelTitle=""
                placeholder="Catalogue Version"
              />
            </div>
            <div className="flex items-center text-[1rem] font-[600] px-[0.49rem]">
              <div className="grow text-[#242424] leading-normal">
                Catalogue Collaborators
              </div>
              <button className="flex items-center justify-center space-x-1 px-[0.75rem] py-[0.63rem]  text-[#FFFFFF] rounded-[1.875rem] bg-[#AB0273]">
                <span>
                  {/* <UserFollow size={24} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M19.25 6.125H24.5"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.875 3.5V8.75"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.38916 23.624C4.46493 21.7619 6.01177 20.2158 7.87428 19.1408C9.7368 18.0659 11.8494 17.5 13.9999 17.5C16.1503 17.5 18.2629 18.066 20.1254 19.1409C21.9879 20.2159 23.5347 21.7621 24.6104 23.6242"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.5684 12.9262C20.1005 14.1896 19.2777 15.291 18.1991 16.0981C17.1204 16.9053 15.8316 17.3838 14.4875 17.4762C13.1435 17.5686 11.8013 17.271 10.6223 16.6191C9.44326 15.9672 8.47753 14.9888 7.8411 13.8014C7.20467 12.614 6.92462 11.268 7.03458 9.9253C7.14454 8.58256 7.63984 7.30013 8.46097 6.23207C9.28211 5.164 10.3942 4.35573 11.6635 3.90436C12.9329 3.45298 14.3056 3.3777 15.6167 3.68756"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Add Collaborators</span>
              </button>
            </div>

            <div className="flex items-center px-[0.49rem] py-[1.35rem] border-y-[0.0625rem] border-[#2C2C2C] justify-between">
              <div className="grow flex space-x-4 items-center">
                <Avatar name={user?.name} size={"3.125rem"} round={true} />
                <span className="flex flex-col space-y-[0.1rem] text-[14px] text-[#242424]">
                  <div className="flex items-center space-x-[0.88rem] leading-normal">
                    <span className="font-[400]">{user?.name}</span>
                    <span className="p-[0.3125rem] bg-[#929292] font-[600] rounded-[1.875rem] text-[#FFFFFF]">
                      Account Owner
                    </span>
                  </div>
                  <span className="font-[400] leading-normal">{user?.upn}</span>
                </span>
              </div>
              <span className="text-[0.8125rem] font-[600] leading-normal text-[#586A84]">
                Catalogue Owner
              </span>
            </div>
            <div className="flex items-center px-[0.49rem] ">
              <div className="grow">
                <span className="flex flex-col font-[600] text-[#242424]">
                  <span className="text-[1rem] leading-normal">
                    Team access
                  </span>
                  <span className="text-[0.875rem] leading-normal">
                    The entire team can edit catalogue materials
                  </span>
                </span>
              </div>
              <div className="text-[16px] font-[600] leading-normal flex items-center justify-center px-[0.75rem] py-[0.5rem] space-x-1 text-tertiary border-2 border-tertiary rounded-[1.875rem] flex-shrink-0">
                <span>Can edit</span>
                <span>
                  {/* <ChevronDown size={24} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M26 12L16 22L6 12"
                      stroke="#AB0273"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="border-t-[0.0625rem] border-[#2C2C2C] py-5 px-[0.49rem] flex justify-between items-center">
            <button className="flex items-center justify-center space-x-1 text-[#FFFFFF] px-3 py-3 text-[16px] rounded-[1.875rem] bg-[#929292]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M23.6245 6.5625L4.37451 6.56251"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.625 2.1875H18.375"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.875 6.5625V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.5625"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Delete Catalogue</span>
            </button>
            <button
              onClick={() => {
                AddUnit();
              }}
              className="text-[#FFFFFF] px-7 py-3 text-[16px] rounded-[1.875rem] bg-tertiary"
            >
              Done
            </button>
          </div>
        </div>
      </Drawer>

      <Drawer
        onOpened={openModalUnit}
        onClose={() => setOpenModalUnit(false)}
        title="Edit Catalogue"
        height="calc(100%)"
      >
        <div className="flex flex-col h-full">
          <div className="space-y-[1.25rem] py-[1.06rem] overflow-y-scroll">
            <div>
              <InputText
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                labelTitle=""
              />
            </div>
            <div>
              <TextArea
                value={descriptionName}
                onChange={(e) => setDescriptionName(e.target.value)}
                labelTitle=""
              />
            </div>
            <div>
              <InputText
                value={versionName}
                onChange={(e) => setVersionName(e.target.value)}
                labelTitle=""
              />
            </div>
            <div className="flex items-center text-[1rem] font-[600] px-[0.49rem]">
              <div className="grow text-[#242424] leading-normal">
                Catalogue Collaborators
              </div>
              <button className="flex items-center justify-center space-x-1 px-[0.75rem] py-[0.63rem]  text-[#FFFFFF] rounded-[1.875rem] bg-[#AB0273]">
                <span>
                  {/* <UserFollow size={24} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M19.25 6.125H24.5"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.875 3.5V8.75"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.38916 23.624C4.46493 21.7619 6.01177 20.2158 7.87428 19.1408C9.7368 18.0659 11.8494 17.5 13.9999 17.5C16.1503 17.5 18.2629 18.066 20.1254 19.1409C21.9879 20.2159 23.5347 21.7621 24.6104 23.6242"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.5684 12.9262C20.1005 14.1896 19.2777 15.291 18.1991 16.0981C17.1204 16.9053 15.8316 17.3838 14.4875 17.4762C13.1435 17.5686 11.8013 17.271 10.6223 16.6191C9.44326 15.9672 8.47753 14.9888 7.8411 13.8014C7.20467 12.614 6.92462 11.268 7.03458 9.9253C7.14454 8.58256 7.63984 7.30013 8.46097 6.23207C9.28211 5.164 10.3942 4.35573 11.6635 3.90436C12.9329 3.45298 14.3056 3.3777 15.6167 3.68756"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Add Collaborators</span>
              </button>
            </div>

            <div className="flex items-center px-[0.49rem] py-[1.35rem] border-y-[0.0625rem] border-[#2C2C2C] justify-between">
              <div className="grow flex space-x-4 items-center">
                <Avatar name={user?.name} size={"3.125rem"} round={true} />
                <span className="flex flex-col space-y-[0.1rem] text-[14px] text-[#242424]">
                  <div className="flex items-center space-x-[0.88rem] leading-normal">
                    <span className="font-[400]">{user?.name}</span>
                    <span className="p-[0.3125rem] bg-[#929292] font-[600] rounded-[1.875rem] text-[#FFFFFF]">
                      Account Owner
                    </span>
                  </div>
                  <span className="font-[400] leading-normal">{user?.upn}</span>
                </span>
              </div>
              <span className="text-[0.8125rem] font-[600] leading-normal text-[#586A84]">
                Catalogue Owner
              </span>
            </div>
            <div className="flex items-center px-[0.49rem] ">
              <div className="grow">
                <span className="flex flex-col font-[600] text-[#242424]">
                  <span className="text-[1rem] leading-normal">
                    Team access
                  </span>
                  <span className="text-[0.875rem] leading-normal">
                    The entire team can edit catalogue materials
                  </span>
                </span>
              </div>
              <div className="text-[16px] font-[600] leading-normal flex items-center justify-center px-[0.75rem] py-[0.5rem] space-x-1 text-tertiary border-2 border-tertiary rounded-[1.875rem] flex-shrink-0">
                <span>Can edit</span>
                <span>
                  {/* <ChevronDown size={24} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M26 12L16 22L6 12"
                      stroke="#AB0273"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 px-[0.49rem] flex justify-between items-center">
            <button
              onClick={() => handleDeletePopup(unit._id)}
              className="flex items-center justify-center space-x-1 text-[#FFFFFF] px-3 py-3 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M23.6245 6.5625L4.37451 6.56251"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.625 2.1875H18.375"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.875 6.5625V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.5625"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Delete Catalogue</span>
            </button>
            <button
              onClick={() => {
                EditUnit(unit._id);
              }}
              className="text-[#FFFFFF] px-7 py-3 text-[16px] rounded-[1.875rem] bg-tertiary"
            >
              Done
            </button>
          </div>
        </div>
      </Drawer>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>Are you sure you want to delete this Catalogue?</span>
          </div>

          <div className="border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
            <button
              onClick={() => setOpenDeletePopup(false)}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleDelete(passUnitIdToDelete);
              }}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        onOpened={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        title="Rename"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow">
            <Text
              value={renamedTitle}
              onChange={(e) => setRenamedTitle(e.target.value)}
              labelTitle="Catalogues Name"
              autoFocus={true}
            />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={() => setOpenRenamePopup(false)}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M25.5 7.5L7.5 25.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.5 25.5L7.5 7.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                handleRename(renamedId, renamedTitle);
              }}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        onOpened={openDetailModalUnit}
        onClose={() => setOpenDetailModalUnit(false)}
        title="Catalogue Details"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow flex flex-col px-[1.63rem] pt-[1.25rem] space-y-[1.25rem]">
            <div className="grid grid-cols-8 items-center space-x-3 ">
              <span
                className="col-span-3 font-[500] text-[1.125rem] leading-normal text-[#242424]"
              >
                Catalogue Name:
              </span>
              <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                {unit?.title}
              </span>
            </div>
            <div className="grid grid-cols-8 items-center space-x-3 ">
              <span
                className="col-span-3  font-[500] text-[1.125rem] leading-normal text-[#242424]"
              >
                Catalogue Description:
              </span>
              <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                {unit?.description}
              </span>
            </div>
            <div className="grid grid-cols-8 items-center space-x-3 ">
              <span
                className="col-span-3 font-[500] text-[1.125rem] leading-normal text-[#242424]"
              >
                Catalogue Version:
              </span>
              <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                {unit?.version}
              </span>
            </div>
          </div>
          <div className="pt-[2.31rem] pb-[0.85rem] px-[1rem] space-x-[3.94rem] flex justify-end items-center">
            <button
              onClick={() => setOpenDetailModalUnit(false)}
              className="text-[#FFFFFF] px-4 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default Sidebar;

import { Edit } from 'lucide-react';
import React, { useState } from 'react'
import AuthoringModal from '../../Authoring/utils/AuthoringModal';
import { GetTemplate } from '../../Authoring/utils/GetTemplate';

type Props = {
    data: any;
    handleDataChange: (data: any) => void;
    children: React.ReactNode;
    type: string;
}

function Layout({ children, data, handleDataChange, type }: Props) {
    const [openAuthoringModal, setOpenAuthoringModal] = useState(true);
    const [templateData, setTemplateData] = useState(data);

    const handleTemplateDataChange = (data: any) => {
        setTemplateData(data);
    }

    function handleSubmit() {
        handleDataChange(templateData);
        setOpenAuthoringModal(false);
    }

    return (
        <>
            <div className='relative'>
                <Edit className='absolute top-5 right-5' size={24} onClick={() => setOpenAuthoringModal(true)}
                    role="button" />
                {children}
            </div>
            <AuthoringModal onOpened={openAuthoringModal} onClose={() => setOpenAuthoringModal(false)} handleSubmit={() => handleSubmit()} >
                <>
                    {(
                        (() => {
                            const TemplateComponent = GetTemplate.get(type);
                            return TemplateComponent ? <TemplateComponent data={templateData} handleDataChange={(data: any) => {
                                handleTemplateDataChange(data);
                                console.log("handledatachange", data);
                            }} /> : null;
                        })()
                    )
                    }
                </>
            </AuthoringModal>
        </>
    )
}

export default Layout